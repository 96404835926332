<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Tax Invoices</div>
    <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <button type="button" @click="AccountStatementFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
        title="Filter" :disabled="dissableFilterButton == true">
        <span class="custom-filter-apply-alert" v-if="filterflag"></span>
        <i class="pi pi-filter custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-12 tax-invoice-page-scrollbar" style="height: calc(100vh - 141px)">
      <div class="
          content-header-section
        ">
        <div>
          <span class="plan-header-label d-flex justify-content-start align-items-center">
            <div class="me-2">Billing Company : </div>
            <div class="custom-form-group mb-0 w-50">
              <Multiselect v-model="companyname" :options="companynamelist" :searchable="false" label="label"
                placeholder="Select Company Name" class="multiselect-custom text-capitalize" :canClear="false"
                :closeOnSelect="true" :object="true" disabled />
            </div>
          </span>
        </div>
      </div>
      <div class="accordion" id="accordionCompanyBilling">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingCompany">
            <button class="accordion-button custom-accordion-focus-out-btn" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseCompanyBilling" aria-expanded="true" aria-controls="collapseCompanyBilling">
              Company Details
            </button>
          </h2>
          <div id="collapseCompanyBilling" class="accordion-collapse collapse show" aria-labelledby="headingCompany"
            data-bs-parent="#accordionCompanyBilling">
            <div class="accordion-body">
              <div class="row g-2">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group">
                    <label class="box-subheader-label">Legal Name</label>
                    <div class="plan-header-label text-capitalize">
                      {{ selectedCompanyDetails.al3 ? selectedCompanyDetails.al3 : "N/A" }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group">
                    <label class="box-subheader-label">GST No</label>
                    <div class="plan-header-label text-uppercase">{{ selectedCompanyDetails.al6 ?
                selectedCompanyDetails.al6 : "N/A" }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group">
                    <label class="box-subheader-label">Pan Card</label>
                    <div class="plan-header-label text-uppercase">{{ selectedCompanyDetails.al5 ?
                selectedCompanyDetails.al5 : "N/A" }}</div>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <div class="customer-label-group mb-0">
                    <label class="box-subheader-label">Address</label>
                    <div class="plan-header-label text-capitalize">{{ selectedCompanyDetails.al7 ?
                selectedCompanyDetails.al7 : "N/A" }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="customer-label-group mb-0">
                    <label class="box-subheader-label">Pincode</label>
                    <div class="plan-header-label text-capitalize">{{ selectedCompanyDetails.al13 ?
                selectedCompanyDetails.al13 : "N/A" }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-ultima-datatable custom-tax-invoice-scroll-datatable mt-3">
        <DataTable :value="taxInvoiceList" :scrollable="true" scrollHeight="flex" :paginator="true"
      :rows="30" :totalRecords="totalRecords" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :lazy="true"
          :rowHover="true" :loading="loading" dataKey="bl1">
          <template v-if="!loading" #empty>No records found.</template>
          <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
          <Column field="date" header="Date" headerStyle="width: 15%" bodyStyle="width: 15%">
            <template #body="{ data }">
              <div class="label-subheading">{{ format_date(data.bl6) }}</div>
            </template>
          </Column>
          <Column field="invoicenumber" header="Invoice Number" headerStyle="width: 20%" bodyStyle="width: 20%">
            <template #body="{ data }">
              <div class="label-subheading">{{ data.bl5 }}</div>
            </template>
          </Column>
          <Column field="inoviceamount" header="Invoice Amount" headerStyle="width: 15%" bodyStyle="width: 15%"
            class="justify-content-end">
            <template #body="{ data }">
              <div class="label-subheading">
                {{ vueNumberFormat(data.bl7, {
                prefix: '₹ ', decimal: '.', thousand: ',', precision: 2, acceptNegative:
                  true
              }) }}
              </div>
            </template>
          </Column>
          <Column field="tax" header="Tax Amount" headerStyle="width: 15%" bodyStyle="width: 15%"
            class="justify-content-end">
            <template #body="{ data }">
              <div class="label-subheading">
                {{ vueNumberFormat(data.bl8, {
                prefix: '₹ ', decimal: '.', thousand: ',', precision: 2, acceptNegative:
                  true
              }) }}
              </div>
            </template>
          </Column>
          <Column field="total" header="Total Amount" headerStyle="width: 15%" bodyStyle="width: 15%"
            class="justify-content-end">
            <template #body="{ data }">
              <div class="label-subheading">
                {{ vueNumberFormat(data.bl9, {
                prefix: '₹ ', decimal: '.', thousand: ',', precision: 2, acceptNegative:
                  true
              }) }}
              </div>
            </template>
          </Column>
          <Column field="download" header="Download" headerStyle="width: 10%" bodyStyle="width: 10%"
            class="justify-content-center">
            <template #body="{ data }">
              <a v-if="data.bl10 != '' && data.bl10 != null" class="btn custom-outline-download-btn" target="_blank"
                :href="'https://storage.googleapis.com/' + this.receiptstoragefilepath + '/invoice/' + data.bl10 + '.pdf'"
                download="Download Tax Invoice"><i class="pi pi-download download-btn-color"></i></a>
              <span v-if="data.bl10 == '' || data.bl10 == null">-</span>
            </template>
          </Column>
          <Column field="summary" header="Summary" headerStyle="width: 10%" bodyStyle="width: 10%"
            class="justify-content-center">
            <template #body="{ data }">
              <button type="button" title="View Summary" class="btn custom-outline-view-btn"
                @click="billingDashboardPlanModalOpen(data.bl1)">
                <i class="pi pi-eye call-btn-color"></i>
              </button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- tax invoice detail table start here -->
  <div class="modal-mask" v-if="billingplandetailmodalstatus">
    <div class="
        modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Tax Invoice Particulars
          </h5>
          <button type="button" class="btn-close" @click="billingDashboardPlanModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="
              position-relative
              taxinvoice-modal-table-scroll-outer
            ">
            <div v-if="!showdataloader">
              <table>
                <tr class="custom-header-outer">
                  <td scope="col" style="width: 3%" class="text-center">#</td>
                  <td scope="col" style="width: 22%">Service Name</td>
                  <td scope="col" style="width: 10%" class="text-center">Consumption Count</td>
                  <td scope="col" style="width: 10%" class="text-center">Rate</td>
                  <td scope="col" style="width: 10%" class="text-center">Total</td>
                  <td scope="col" style="width: 10%" class="text-center">SGST</td>
                  <td scope="col" style="width: 10%" class="text-center">CGST</td>
                  <td scope="col" style="width: 10%" class="text-center">IGST</td>
                  <td scope="col" style="width: 10%" class="text-center">Total Amount</td>
                </tr>
                <tr v-for="(component, index) in taxInvoiceCompenetsList" v-bind:key="index">
                  <td style="width: 3%" class="text-center">{{ index + 1 }}</td>
                  <td style="width: 25%">{{ component.bm4 }}</td>
                  <td style="width: 10%" class="text-center">{{ component.bm5 }}</td>
                  <td style="width: 10%" class="text-end">{{ vueNumberFormat(component.bm6, {
                prefix: '₹ ', decimal: '.',
                thousand: ',', precision: 2, acceptNegative: true
              }) }}</td>
                  <td style="width: 10%" class="text-end">{{ vueNumberFormat(component.bm7, {
                prefix: '₹ ', decimal: '.',
                thousand: ',', precision: 2, acceptNegative: true
              }) }}</td>
                  <td style="width: 10%" class="text-end">{{ vueNumberFormat(component.bm13, {
                prefix: '₹ ', decimal: '.',
                thousand: ',', precision: 2, acceptNegative: true
              }) }}</td>
                  <td style="width: 10%" class="text-end">{{ vueNumberFormat(component.bm11, {
                prefix: '₹ ', decimal: '.',
                thousand: ',', precision: 2, acceptNegative: true
              }) }}</td>
                  <td style="width: 10%" class="text-end">{{ vueNumberFormat(component.bm15, {
                prefix: '₹ ', decimal: '.',
                thousand: ',', precision: 2, acceptNegative: true
              }) }}</td>
                  <td style="width: 10%" class="text-end">{{ vueNumberFormat(component.bm17, {
                prefix: '₹ ', decimal: '.',
                thousand: ',', precision: 2, acceptNegative: true
              }) }}</td>
                </tr>
                <tr v-if="taxInvoiceCompenetsList == null">
                  <td colspan="9" style="width: 100%" class="text-center">No records found.</td>
                </tr>
              </table>
            </div>
            <div v-if="showdataloader" style="height: 430px">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- tax invoice detail detail table end here -->
  <!-- filter start here -->
  <div class="modal-mask" v-if="accountstatementmodalstatus">
    <div class="
        modal-dialog modal-xs modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Filter By
          </h5>
          <button type="button" class="btn-close" @click="AccountStatementFilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">From Date</label>
                <Calendar v-model="fromdate" :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">To Date</label>
                <Calendar v-model="todate" :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy" :minDate="fromdate" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                  <label class="form-label">Invoice Number</label>
                  <input type="text" v-model="invoice_number" class="form-control" placeholder="Enter Invoice Number" autocomplete="off" />
                </div>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button :disabled="filterButtonShow == false" class="btn filter-apply-btn"
                @click="applyFilteStatement(fromdate, todate, invoice_number)">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" @click="resetFilterMonth">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import moment from 'moment';
export default {
  data() {
    return {
      taxInvoiceList: [],
      totalRecords: 0,
      loading: false,
      companyname: '',
      companynamelist: [],
      billingplandetailmodalstatus: false,
      client_info: '',
      client_id: '',
      receiptstoragefilepath: '',
      taxInvoiceCompenetsList: [],
      selectedCompanyDetails: '',
      baseUrl: window.location.origin,
      showdataloader: false,

      fromdate: null,
      todate: null,
      invoice_number: '',
      filterflag: false,
      dissableFilterButton: false,
      filterButtonShow: false,
      accountstatementmodalstatus: false,
      todaysDate: new Date(),
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.loading = true;
    this.getClientTaxInvoices();
    this.client_info = JSON.parse(localStorage.client_info);
    this.client_id = this.client_info.maa1;
    this.receiptstoragefilepath = this.client_info.maa24;
  },
  watch: {
    fromdate() {
      this.filterChanges();
    },
    todate() {
      this.filterChanges();
    },
    invoice_number() {
      this.filterChanges();
    },
  },
  methods: {
    getClientTaxInvoices(e) {
      this.loading = true;
      this.ApiService.getClientTaxInvoices(e).then((data) => {
        if (data.status == 200) {
          this.taxInvoiceList = data.data;
          this.selectedCompanyDetails = data.companydetails;
          // this.companyname1 = this.selectedCompanyDetails.al2;
          if (this.selectedCompanyDetails.al2 != null && this.selectedCompanyDetails.al2 != "") {
            this.companyname = {
              label: this.selectedCompanyDetails.al2,
              value: '',
            };
          }
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.taxInvoiceList = null;
        }
      });
    },
    changePage(event) {
      this.loading = true;
      this.page_no = event.page;
      this.getClientTaxInvoices({
        page_no: this.page_no,
        fromdate: this.fromdate,
        todate: this.todate,
        invoice_number_status: this.invoice_number,
      });
    },
    billingDashboardPlanModalOpen(e) {
      this.billingplandetailmodalstatus = true;
      this.getTaxInvoiceComponent({ invoiceId: e });

    },
    getTaxInvoiceComponent(e) {
      this.showdataloader = true;
      this.ApiService.getTaxInvoiceComponent(e).then((data) => {
        if (data.success == true) {
          this.showdataloader = false;
          this.taxInvoiceCompenetsList = data.data;
        } else {
          this.showdataloader = false;
          this.taxInvoiceCompenetsList = null;
        }
      });
    },

    billingDashboardPlanModalClose() {
      this.billingplandetailmodalstatus = false;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    filterChanges() {
      if (
        this.fromdate != null ||
        this.todate != null ||
        this.invoice_number != ''
      ) {
        this.filterButtonShow = true;
      } else {
        this.filterButtonShow = false;
      }
    },
    applyFilteStatement(fromdate, todate, invoice_number) {
      this.loading = true;
      this.filterflag = true;
      this.invoice_number = invoice_number;
      this.accountstatementmodalstatus = false;
      if (fromdate != null && fromdate != null) {
        this.fromdate = moment(fromdate).format("YYYY-MM-DD");
      }
      if (todate != null && todate != null) {
        this.todate = moment(todate).format("YYYY-MM-DD");
      }
      this.getClientTaxInvoices({
        page_no: this.page_no,
        fromdate: this.fromdate,
        todate: this.todate,
        invoice_number_status: this.invoice_number,
      });
    },
    resetFilterMonth() {
      this.fromdate = null;
      this.todate = null;
      this.invoice_number = '';
      this.getClientTaxInvoices();
      this.filterflag = false;
    },

    AccountStatementFilterModalOpen() {
      this.accountstatementmodalstatus = true;
      if(this.fromdate != null) {
          this.fromdate = new Date(this.fromdate);
      }
      if(this.todate != null) {
          this.todate = new Date(this.todate);
      }
    },

    AccountStatementFilterModalClose() {
      this.accountstatementmodalstatus = false;
    },
  },
};
</script>
<style scoped>
.custom-outline-download-btn {
  background: #e7f1ff 0% 0% no-repeat padding-box;
  border: 1px solid #5266e5;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-download-btn .download-btn-color {
  color: #5266e5;
  font-size: 13px;
}

.customer-label-group {
  margin-bottom: 20px;
}

.customer-label-group .box-subheader-label {
  margin-bottom: 6px;
  user-select: none;
}

.plan-header-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  user-select: none;
}

.box-subheader-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
}

.custom-accordion-focus-out-btn:focus {
  box-shadow: none;
}

.custom-accordion-focus-out-btn {
  user-select: none;
}

.content-header-section {
  padding: 12px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.tax-invoice-page-scrollbar {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.tax-invoice-page-scrollbar::-webkit-scrollbar,
.taxinvoice-modal-table-scroll-outer::-webkit-scrollbar {
  display: block;
  width: 0.3em;
  height: 0.3em;
}

.tax-invoice-page-scrollbar::-webkit-scrollbar-thumb,
.taxinvoice-modal-table-scroll-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.custom-tax-invoice-scroll-datatable {
  height: calc(100vh - 126px);
}

.taxinvoice-modal-table-scroll-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 513px;
}

.taxinvoice-modal-table-scroll-outer {
  overflow: hidden;
  display: block;
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100vh - 105px) !important;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
  user-select: none;
}

.taxinvoice-modal-table-scroll-outer table {
  width: 100%;
}

.taxinvoice-modal-table-scroll-outer .custom-header-outer {
  position: sticky;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  top: 0;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #e0e5ed;
  color: #4a5463;
  background: #f2f4fb;
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  line-height: 13px;
  font-weight: 400;
  user-select: none;
}

.taxinvoice-modal-table-scroll-outer td {
  padding: 8px;
  padding: 10px 6px;
  border: 1px solid #e0e5ed;
}
</style>
