<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Add Campaign Templates</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
            <button type="button" @click="newtemplateModalOpen()" class="header-btn-outer btn btn-primary">
                Add Campaign Template
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4 " id="intelligent-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#intelligentcampaigndetail" type="button" role="tab" aria-controls="vehicle-details"
                aria-selected="false" @click="redirectIntelligentCampaignApi()">
                Intelligent
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="imessage-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#imessagecampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectiMessageCampaignApi()">
                RCS / iMessage
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="sms-campaign-tab" data-bs-toggle="tab" data-bs-target="#smscampaigndetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="redirectSMSCampaignApi()">
                SMS
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="email-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#emailcampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectEmailCampaignApi()">
                Email
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="audio-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#audiocampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectAudioCampaignApi()">
                Audio Call
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4 active" id="notification-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#notificationcampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="true">
                Push Notification
            </button>
        </li>
    </ul>
    <div class="tab-content mt-2" id="myTabContent">
        <div class="tab-pane fade" id="intelligentcampaigndetail" role="tabpanel"
            aria-labelledby="intelligent-campaign-tab">

        </div>
        <div class="tab-pane fade" id="whatsappcampaigndetail" role="tabpanel" aria-labelledby="whatsapp-campaign-tab">

        </div>
        <div class="tab-pane fade" id="imessagecampaigndetail" role="tabpanel" aria-labelledby="imessage-campaign-tab">

        </div>
        <div class="tab-pane fade" id="smscampaigndetail" role="tabpanel" aria-labelledby="sms-campaign-tab">

        </div>
        <div class="tab-pane fade" id="emailcampaigndetail" role="tabpanel" aria-labelledby="email-campaign-tab">

        </div>
        <div class="tab-pane fade" id="audiocampaigndetail" role="tabpanel" aria-labelledby="audio-campaign-tab">

        </div>
        <div class="tab-pane fade show active" id="notificationcampaigndetail" role="tabpanel"
            aria-labelledby="notification-campaign-tab">
            <div class="custom-ultima-datatable" style="height: calc(100vh - 188px)">
                <DataTable :value="notificationCampaignList" :scrollable="true" scrollHeight="flex"
                    columnResizeMode="fit" :paginator="true" :lazy="true" :rowHover="true"
                    :totalRecords="notificationRecords" :rows="30" dataKey="aj1" @page="changePage($event)"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :loading="loading">
                    <template v-if="!loading" #empty>No records found.</template>
                    <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                    <Column field="Name" header="Campaign Name" headerStyle="width: 30%" bodyStyle="width: 30%">
                        <template #body="{ data }">
                            <div>{{ data.cc3 ? data.cc3 : "N/A" }}</div>
                        </template>
                    </Column>
                    <Column field="message" header="Campaign Message" headerStyle="width: 45%" bodyStyle="width: 45%">
                        <template #body="{ data }">
                            <div>
                                <div class="fw-bold" v-if="data.cc17">{{ data.cc17 ? data.cc17 : "N/A" }}</div>
                                <div>{{ data.cc5 ? data.cc5 : "N/A" }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column field="image" header="Image Preview" headerStyle="width: 20%" bodyStyle="width: 20%">
                        <template #body="{ data }">
                            <div>
                                <img v-if="data.cc12" @click="whatsappimagepreview(data)" class="cursor-pointer"
                                    :src="data.cc12" height="80" />
                            </div>
                        </template>
                    </Column>
                    <Column field="status" header="Status" headerStyle="width: 5%" bodyStyle="width: 5%"
                        class="justify-content-end">
                        <template #body="{ data }">
                            <div class="d-flex align-items-end">
                                <button type="button" title="View Details" class="btn custom-outline-view-btn ms-2"
                                    @click="viewSmsDetailModalOpen(data)">
                                    <i class="pi pi-eye call-btn-color"></i>
                                </button>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <!-- Add Template modal start here -->
    <div class="modal-mask" v-if="$store.state.newtempaltemodalstatus">
        <div class="modal-dialog modal-xl modal-dialog-centered
            custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Campaign Template</h5>
                    <button type="button" class="btn-close" @click="newtemplateModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-12 custom-start-menu-col">
                            <ul class="nav nav-tabs custom-vertical-modal-tab flex-column" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="intelligent-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#intelligentcampaigntarget" type="button" role="tab"
                                        aria-controls="intelligent-campaign" aria-selected="false"
                                        @click="selectedtab((e = 1))">
                                        Intelligent Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="imessage-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#imessagecampaigntarget" type="button" role="tab"
                                        aria-controls="imessage-campaign" aria-selected="false"
                                        @click="selectedtab((e = 3))">
                                        RCS / iMessage Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="sms-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#smscampaigntarget" type="button" role="tab"
                                        aria-controls="sms-campaign" aria-selected="false"
                                        @click="selectedtab((e = 4))">
                                        SMS Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="email-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#emailcampaigntarget" type="button" role="tab"
                                        aria-controls="email-campaign" aria-selected="false"
                                        @click="selectedtab((e = 5))">
                                        Email Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="audio-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#audiocampaigntarget" type="button" role="tab"
                                        aria-controls="audio-campaign" aria-selected="false"
                                        @click="selectedtab((e = 6))">
                                        Audio Call Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="notification-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#notificationcampaigntarget" type="button" role="tab"
                                        aria-controls="notification-campaign" aria-selected="true"
                                        @click="selectedtab((e = 7))">
                                        Push Notification Campaign
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-9 col-md-9 col-12 custom-end-body-col">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade" id="intelligentcampaigntarget" role="tabpanel"
                                    aria-labelledby="intelligent-campaign-tab">
                                    <div v-if="selected_tab == 1">
                                        <IntelligentCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="whatsappcampaigntarget" role="tabpanel"
                                    aria-labelledby="whatsapp-campaign-tab">
                                    <div v-if="selected_tab == 2">
                                        <WhatsAppCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="imessagecampaigntarget" role="tabpanel"
                                    aria-labelledby="imessage-campaign-tab">
                                    <div v-if="selected_tab == 3">
                                        <iMessageCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="smscampaigntarget" role="tabpanel"
                                    aria-labelledby="sms-campaign-tab">
                                    <div v-if="selected_tab == 4">
                                        <SMSCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="emailcampaigntarget" role="tabpanel"
                                    aria-labelledby="email-campaign-tab">
                                    <div v-if="selected_tab == 5">
                                        <EmailCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="audiocampaigntarget" role="tabpanel"
                                    aria-labelledby="audio-campaign-tab">
                                    <div v-if="selected_tab == 6">
                                        <AudioCallCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane show active" id="notificationcampaigntarget" role="tabpanel"
                                    aria-labelledby="notification-campaign-tab">
                                    <div v-if="selected_tab == 7">
                                        <PushNotificationCampaignTab />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Template modal end here -->
    <div v-if="ImagePreviewDialog" class="modal-mask">
        <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Image Preview</h4>
                    <button class="btn-close" @click="whatsappimagepreviewClose"></button>
                </div>
                <div class="modal-body p-0">
                    <img :src="previewimg" style="vertical-align: middle; width: 100%;" />
                </div>
            </div>
        </div>
    </div>
    <!-- View SMS Details start here -->
    <div class="modal-mask" v-if="viewsmstemplatedetailmodalstatus">
        <div class="modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">{{ this.headeraliasename }}</h5>
                    <button type="button" class="btn-close" @click="viewSmsDetailModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Template Name</label>
                                    <div class="from-label-value">
                                        {{ smsTemplateBody.cc3 ? smsTemplateBody.cc3 : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12" v-if="smsTemplateBody.cc17">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Notification Title</label>
                                    <div class="from-label-value">
                                        {{ smsTemplateBody.cc17 ? smsTemplateBody.cc17 : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12" v-if="smsTemplateBody.cc7">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Language</label>
                                    <div class="from-label-value text-capitalize">
                                        {{ smsTemplateBody.cc7 ? smsTemplateBody.cc7 : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Status</label>
                                    <div class="from-label-value">
                                        <span v-if="smsTemplateBody.cc15 == 1">Active</span>
                                        <span v-else>Inactive</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <label class="form-label"><span
                                        v-html="replacebrake(smsTemplateBody.cc5)"></span></label>
                            </div>
                        </div>
                    </div>
                    <div class="voter-exit-box-outer ">
                        <div class="row">
                            <button v-if="this.smsTemplateBody.cc21 == 1" type="button"
                                    class="btn modal-bulk-next-btn w-100 my-1 text-capitalize">{{
                                    this.smsTemplateBody.cc19
                                    }} - {{
                                        this.smsTemplateBody.cc20 }}</button>
                                <button v-if="this.smsTemplateBody.cc24 == 1" type="button"
                                    class="btn modal-bulk-next-btn w-100 my-1 text-capitalize">{{
                                    this.smsTemplateBody.cc22}} - {{
                                        this.smsTemplateBody.cc23 }}</button>
                                <button v-if="this.smsTemplateBody.cc27 == 1" type="button"
                                    class="btn modal-bulk-next-btn w-100 my-1 text-capitalize">{{
                                    this.smsTemplateBody.cc25}} - {{
                                        this.smsTemplateBody.cc26 }}</button>
                                <button v-if="this.smsTemplateBody.cc30 == 1" type="button"
                                    class="btn modal-bulk-next-btn w-100 my-1 text-capitalize">{{
                                    this.smsTemplateBody.cc28}} - {{
                                        this.smsTemplateBody.cc29 }}</button>
                                <button v-if="this.smsTemplateBody.cc33 == 1" type="button"
                                    class="btn modal-bulk-next-btn w-100 my-1 text-capitalize">{{
                                    this.smsTemplateBody.cc31}} - {{
                                        this.smsTemplateBody.cc32 }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- View SMS Details End here -->
</template>
<script>
import ApiService from "../../service/ApiService";

export default {
    data() {
        return {
            notificationCampaignList: [],
            notificationRecords: 0,
            loading: false,
            newtempaltemodalstatus: false,
            selected_tab: 1,
            ImagePreviewDialog: false,
            viewsmstemplatedetailmodalstatus: false,
            smsTemplateBody: '',
            headeraliasename: '',
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        // this.loading = true;
        this.getcampaigntemplates();
        if (localStorage.user) {
            this.client_info = JSON.parse(localStorage.client_info);
            this.voterstorageimgpath = this.client_info.maa24;
        }
    },

    methods: {
        selectedtab(e) {
            this.selected_tab = e;
        },
        newtemplateModalOpen() {
            this.$store.dispatch("addCampaignStatus");
            // this.newtempaltemodalstatus = true;
            this.selected_tab = 7;
            this.getwhatsappcampaigntemplateList();
            this.getcallcampaigntemplateList();
            this.getnotificationcampaigntemplateList();
            this.getsmscampaigntemplates();
        },
        newtemplateModalClose() {
            this.$store.dispatch("addCampaignStatus");
            // this.newtempaltemodalstatus = false;
        },
        getcampaigntemplates(item) {
            this.loading = true;
            this.ApiService.getnotificationcampaigntemplateList(item).then((data) => {
                if (data.success === true) {
                    this.notificationCampaignList = data.data;
                    this.notificationRecords = data.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.notificationCampaignList = [];
                    this.notificationRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getcampaigntemplates({ page_no: this.page_no });
        },
        redirectIntelligentCampaignApi() {
            this.$router.push("/campaigntemplate/intelligent");
        },
        redirectWhatsAppCampaignApi() {
            this.$router.push("/campaigntemplate/whatsapp");
        }, redirectiMessageCampaignApi() {
            this.$router.push("/campaigntemplate/rcsimessage");
        }, redirectSMSCampaignApi() {
            this.$router.push("/campaigntemplate/sms");
        }, redirectEmailCampaignApi() {
            this.$router.push("/campaigntemplate/email");
        },
        redirectAudioCampaignApi() {
            this.$router.push("/campaigntemplate/audiocall");
        },

        getwhatsappcampaigntemplateList() {
            this.ApiService.getwhatsappcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("whatsapp_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("whatsapp_templates", []);
                }
            });
        },
        getcallcampaigntemplateList() {
            this.ApiService.getcallcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("call_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("call_templates", []);
                }
            });
        },
        getnotificationcampaigntemplateList() {
            this.ApiService.getnotificationcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("notification_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("notification_templates", []);
                }
            });
        },
        getsmscampaigntemplates() {
            this.ApiService.getsmscampaigntemplates().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("sms_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("sms_templates", []);
                }
            });
        },
        whatsappimagepreview(data) {
            this.previewimg = data.cc12;
            this.ImagePreviewDialog = true;
        },
        whatsappimagepreviewClose() {
            this.ImagePreviewDialog = false;
        },
        viewSmsDetailModalOpen(e) {
            this.viewsmstemplatedetailmodalstatus = true;
            this.smsTemplateBody = e;
            this.headeraliasename = e.cc2;
        },
        viewSmsDetailModalClose() {
            this.viewsmstemplatedetailmodalstatus = false;
            this.smsTemplateBody = '';
            this.headeraliasename = '';
        },
        replacebrake(html) {
            const doc = html;
            return doc.replace(/(\r)*\n/g, '<br>')
        },
    },
};

</script>
<style scoped>
.custom-start-menu-col {
    width: 21%;
}

.custom-end-body-col {
    width: 79%;
    padding-left: 0;
}

.custom-vertical-modal-tab {
    width: 213px;
    min-width: 213px;
    height: 460px;
}


.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 16px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.modal-body-fixed-height {
    min-height: 422px;
}

.card-template {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 0px 15px;
}

.preview-card-template {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 14px 15px;
    height: auto;
    min-height: 197px;
}

.preview-body-msg {
    font-family: "AcuminPro-Regular";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 17px;
    margin-top: 15px;
}

.preview-footer-msg {
    margin-top: 15px;
}

.showmorebody {
    color: #3f51b5 !important;
    cursor: pointer;
}

.showlessbody {
    color: #3f51b5 !important;
    cursor: pointer;
}
</style>