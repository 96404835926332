<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Payment Receipts</div>
    <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <button type="button" @click="AccountStatementFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
        title="Filter" :disabled="dissableFilterButton == true">
        <span class="custom-filter-apply-alert" v-if="filterflag"></span>
        <i class="pi pi-filter custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
    <DataTable :value="paymentReceiptList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
      :rows="30" :totalRecords="totalRecords" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :lazy="true"
      dataKey="ak1" :loading="loading">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="date" header="Receipt Date" headerStyle="width: 20%" bodyStyle="width: 20%">
        <template #body="{ data }">
          <div class="label-subheading">{{ format_timestamp(data.receipt_datetime) }}</div>
        </template>
      </Column>
      <Column field="amount" header="Amount" headerStyle="width: 15%" bodyStyle="width: 15%" class="justify-content-end">
        <template #body="{ data }">
          <div class="label-subheading">{{ vueNumberFormat(data.br20, {
            prefix: '₹ ', decimal: '.', thousand: ',',
            precision: 2, acceptNegative: true
          }) }}</div>
        </template>
      </Column>
      <Column field="paymentmode" header="Payment Mode" headerStyle="width: 35%" bodyStyle="width: 35%">
        <template #body="{ data }">
          <div class="label-subheading">
            <span v-if="data.br7 == 1">Payment Gateway</span>
            <span v-if="data.br7 == 2">Cheque</span>
            <span v-if="data.br7 == 3">NEFT / RTGS</span>
            <span v-if="data.br7 == 4">IMPS</span>
            <span v-if="data.br7 == 5">Advance Credit</span>
            <span v-if="data.br7 == '' || data.br7 == null">N/A</span>
          </div>
        </template>
      </Column>
      <Column field="status" header="Status" headerStyle="width: 15%" bodyStyle="width: 15%"
        class="justify-content-center">
        <template #body="{ data }">
          <div class="label-subheading">
            <span v-if="data.br19 == 1" class="appointment-badge status-scheduled">Success</span>
            <span v-if="data.br19 == 2" class="appointment-badge status-cancelled">Failed</span>
            <span v-if="data.br19 == '' || data.br19 == null">N/A</span>
          </div>
        </template>
      </Column>
      <Column field="download" header="Download" headerStyle="width: 15%" bodyStyle="width: 15%"
        class="justify-content-center">
        <template #body="{ data }">
          <a v-if="data.br15 != '' && data.br15 != null" class="btn custom-outline-download-btn" target="_blank"
            :href="'https://storage.googleapis.com/' + this.receiptstoragefilepath + '/' + this.client_id + '/payment-receipt/' + data.br15" download="Download Receipt"><i class="pi pi-download download-btn-color"></i></a>
          <span v-if="data.br15 == '' || data.br15 == null">-</span>
        </template>
      </Column>
    </DataTable>
  </div>
  <!-- filter start here -->
  <div class="modal-mask" v-if="accountstatementmodalstatus">
    <div class="
        modal-dialog modal-xs modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Filter By
          </h5>
          <button type="button" class="btn-close" @click="AccountStatementFilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">From Date</label>
                <Calendar v-model="fromdate" :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">To Date</label>
                <Calendar v-model="todate" :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy" :minDate="fromdate" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Payment Mode</label>
                  <Multiselect v-model="paymentmode" :options="paymentmodeList" mode="tags" :createTag="false" label="label"
                      class="multiselect-custom text-capitalize" placeholder="Select" :closeOnSelect="true"
                      :searchable="false" :object="false" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button :disabled="filterButtonShow == false" class="btn filter-apply-btn"
                @click="applyFilteStatement(fromdate, todate, paymentmode)">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" @click="resetFilterMonth">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import moment from 'moment';
export default {
  data() {
    return {
      paymentReceiptList: [],
      totalRecords: 0,
      page_no: 0,
      loading: false,
      client_info: '',
      client_id: '',
      receiptstoragefilepath: '',
      baseUrl :window.location.origin,

      fromdate: null,
      todate: null,
      paymentmode: [],
      filterflag: false,
      dissableFilterButton: false,
      filterButtonShow: false,
      accountstatementmodalstatus: false,
      paymentmodeList: [
        { label: "Payment Gateway", value: 1 },
        { label: "Cheque", value: 2 },
        { label: "NEFT / RTGS", value: 3 },
        { label: "IMPS", value: 4 },
        { label: "Advance Credit", value: 5 },
      ],
      todaysDate: new Date(),
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.client_info = JSON.parse(localStorage.client_info);
    this.client_id = this.client_info.maa1;
    if (this.client_id != '') {
      this.getClientPaymentReceipt({ client_id: this.client_id, page_no: this.page_no, });
    }
    if(this.baseUrl == 'http://localhost:8080' || this.baseUrl == 'http://localhost:8081') {
      this.receiptstoragefilepath = this.client_info.maa24;
    } else {
      this.receiptstoragefilepath = localStorage.masterbucketname;
    }
  },
  watch: {
    fromdate() {
      this.filterChanges();
    },
    todate() {
      this.filterChanges();
    },
    paymentmode() {
      this.filterChanges();
    },
  },
  methods: {
    getClientPaymentReceipt(e) {
      this.loading = true;
      this.ApiService.getClientPaymentReceipt(e).then((data) => {
        if (data.status == 200) {
          this.paymentReceiptList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.paymentReceiptList = null;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getClientPaymentReceipt({ 
        page_no: this.page_no,
        fromdate: this.fromdate,
        todate: this.todate,
        payment_mode: this.paymentmode,
       });
    },
    format_datetime(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return 'N/A';
      }
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return 'N/A';
      }
    },
    filterChanges() {
      if (
        this.fromdate != null ||
        this.todate != null ||
        this.paymentmode != null
      ) {
        this.filterButtonShow = true;
      } else {
        this.filterButtonShow = false;
      }
    },
    applyFilteStatement(fromdate, todate, paymentmode) {
      this.loading = true;
      this.filterflag = true;
      this.paymentmode = paymentmode;
      this.accountstatementmodalstatus = false;
      if (fromdate != null && fromdate != null) {
        this.fromdate = moment(fromdate).format("YYYY-MM-DD");
      }
      if (todate != null && todate != null) {
        this.todate = moment(todate).format("YYYY-MM-DD");
      }
      this.getClientPaymentReceipt({
        page_no: this.page_no,
        fromdate: this.fromdate,
        todate: this.todate,
        payment_mode: this.paymentmode,
      });
    },
    resetFilterMonth() {
      this.fromdate = null;
      this.todate = null;
      this.paymentmode = null;
      this.getClientPaymentReceipt();
      this.filterflag = false;
    },

    AccountStatementFilterModalOpen() {
      this.accountstatementmodalstatus = true;
      if(this.fromdate != null) {
          this.fromdate = new Date(this.fromdate);
      }
      if(this.todate != null) {
          this.todate = new Date(this.todate);
      }
    },

    AccountStatementFilterModalClose() {
      this.accountstatementmodalstatus = false;
    },
  },
};
</script>
<style scoped>
.custom-outline-download-btn {
  background: #e7f1ff 0% 0% no-repeat padding-box;
  border: 1px solid #5266e5;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-download-btn .download-btn-color {
  color: #5266e5;
  font-size: 13px;
}
</style>