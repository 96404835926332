<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Add Campaign Templates</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
            <button type="button" @click="newtemplateModalOpen()" class="header-btn-outer btn btn-primary">
                Add Campaign Template
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4 active" id="intelligent-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#intelligentcampaigndetail" type="button" role="tab" aria-controls="vehicle-details"
                aria-selected="true">
                Intelligent
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="imessage-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#imessagecampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectiMessageCampaignApi()">
                RCS / iMessage
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="sms-campaign-tab" data-bs-toggle="tab" data-bs-target="#smscampaigndetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="redirectSMSCampaignApi()">
                SMS
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="email-campaign-tab" data-bs-toggle="tab" data-bs-target="#emailcampaigndetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="redirectEmailCampaignApi()">
                Email
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="audio-campaign-tab" data-bs-toggle="tab" data-bs-target="#audiocampaigndetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="redirectAudioCallCampaignApi()">
                Audio Call
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="notification-campaign-tab" data-bs-toggle="tab" data-bs-target="#notificationcampaigndetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="redirectNotificationCampaignApi()">
                Push Notification
            </button>
        </li>
    </ul>
    <div class="tab-content mt-2" id="myTabContent">
        <div class="tab-pane fade show active" id="intelligentcampaigndetail" role="tabpanel"
            aria-labelledby="intelligent-campaign-tab">
            <div class="custom-ultima-datatable" style="height: calc(100vh - 188px)">
                <DataTable :value="intelligentCampaignList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit"
                    :paginator="true" :lazy="true" :rowHover="true" :totalRecords="totintelligentRecords" :rows="30"
                    dataKey="aj1" @page="changePage($event)"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :loading="loading">
                    <template v-if="!loading" #empty>No records found.</template>
                    <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                    <Column field="Name" header="Campaign Name" headerStyle="width: 30%" bodyStyle="width: 30%">
                        <template #body="{ data }">
                            <div>{{ data.aj2 ? data.aj2 : "N/A" }}</div>
                        </template>
                    </Column>
                    <Column field="message" header="Campaign Message" headerStyle="width: 70%" bodyStyle="width: 70%">
                        <template #body="{ data }">
                            <div>{{ data.aj4 ? data.aj4 : "N/A" }}</div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <div class="tab-pane fade" id="whatsappcampaigndetail" role="tabpanel" aria-labelledby="whatsapp-campaign-tab">

        </div>
        <div class="tab-pane fade" id="imessagecampaigndetail" role="tabpanel" aria-labelledby="imessage-campaign-tab">
            
        </div>
        <div class="tab-pane fade" id="smscampaigndetail" role="tabpanel" aria-labelledby="sms-campaign-tab">
            
        </div>
        <div class="tab-pane fade" id="emailcampaigndetail" role="tabpanel" aria-labelledby="email-campaign-tab">
           
        </div>
        <div class="tab-pane fade" id="audiocampaigndetail" role="tabpanel" aria-labelledby="audio-campaign-tab">
            
        </div>
        <div class="tab-pane fade" id="notificationcampaigndetail" role="tabpanel" aria-labelledby="notification-campaign-tab">
            
        </div>
    </div>
    <!-- Add Template modal start here -->
    <div class="modal-mask" v-if="$store.state.newtempaltemodalstatus">
        <div class="modal-dialog modal-xl modal-dialog-centered
            custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Campaign Template</h5>
                    <button type="button" class="btn-close" @click="newtemplateModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-12 custom-start-menu-col">
                            <ul class="nav nav-tabs custom-vertical-modal-tab flex-column" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="intelligent-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#intelligentcampaigntarget" type="button" role="tab"
                                        aria-controls="intelligent-campaign" aria-selected="true"
                                        @click="selectedtab((e = 1))">
                                        Intelligent Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="imessage-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#imessagecampaigntarget" type="button" role="tab"
                                        aria-controls="imessage-campaign" aria-selected="false"
                                        @click="selectedtab((e = 3))">
                                        RCS / iMessage Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="sms-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#smscampaigntarget" type="button" role="tab"
                                        aria-controls="sms-campaign" aria-selected="false" @click="selectedtab((e = 4))">
                                        SMS Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="email-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#emailcampaigntarget" type="button" role="tab"
                                        aria-controls="email-campaign" aria-selected="false" @click="selectedtab((e = 5))">
                                        Email Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="audio-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#audiocampaigntarget" type="button" role="tab"
                                        aria-controls="audio-campaign" aria-selected="false" @click="selectedtab((e = 6))">
                                        Audio Call Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="notification-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#notificationcampaigntarget" type="button" role="tab"
                                        aria-controls="notification-campaign" aria-selected="false" @click="selectedtab((e = 7))">
                                        Push Notification Campaign
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-9 col-md-9 col-12 custom-end-body-col">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane show active" id="intelligentcampaigntarget" role="tabpanel"
                                    aria-labelledby="intelligent-campaign-tab">
                                    <div v-if="selected_tab == 1">
                                        <IntelligentCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="whatsappcampaigntarget" role="tabpanel"
                                    aria-labelledby="whatsapp-campaign-tab">
                                    <div v-if="selected_tab == 2">
                                        <WhatsAppCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="imessagecampaigntarget" role="tabpanel"
                                    aria-labelledby="imessage-campaign-tab">
                                    <div v-if="selected_tab == 3">
                                        <iMessageCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="smscampaigntarget" role="tabpanel"
                                    aria-labelledby="sms-campaign-tab">
                                    <div v-if="selected_tab == 4">
                                        <SMSCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="emailcampaigntarget" role="tabpanel"
                                    aria-labelledby="email-campaign-tab">
                                    <div v-if="selected_tab == 5">
                                        <EmailCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="audiocampaigntarget" role="tabpanel"
                                    aria-labelledby="audio-campaign-tab">
                                    <div v-if="selected_tab == 6">
                                        <AudioCallCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="notificationcampaigntarget" role="tabpanel"
                                    aria-labelledby="notification-campaign-tab">
                                    <div v-if="selected_tab == 7">
                                        <PushNotificationCampaignTab />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Template modal end here -->
</template>
<script>
import ApiService from "../../service/ApiService";

export default {
    data() {
        return {
            intelligentCampaignList: [],
            totintelligentRecords: 0,
            loading: false,
            newtempaltemodalstatus: false,
            selected_tab: 1,
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        // this.loading = true;
        // this.getcampaigntemplates();
    },

    methods: {
        selectedtab(e) {
            this.selected_tab = e;
        },
        newtemplateModalOpen() {
            this.$store.dispatch("addCampaignStatus");
            // this.newtempaltemodalstatus = true;
            this.selected_tab = 1;
            this.getwhatsappcampaigntemplateList();
            this.getcallcampaigntemplateList();
            this.getsmscampaigntemplates();
        },
        newtemplateModalClose() {
            this.$store.dispatch("addCampaignStatus");
            // this.newtempaltemodalstatus = false;
        },
        getcampaigntemplates(item) {
            this.ApiService.getcampaigntemplates(item).then((data) => {
                if (data.success === true) {
                    this.intelligentCampaignList = data.data;
                    this.totintelligentRecords = data.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.intelligentCampaignList = [];
                    this.totintelligentRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getcampaigntemplates({ page_no: this.page_no });
        },
        redirectWhatsAppCampaignApi() {
            this.$router.push("/campaigntemplate/whatsapp");
        }, redirectiMessageCampaignApi() {
            this.$router.push("/campaigntemplate/rcsimessage");
        }, redirectSMSCampaignApi() {
            this.$router.push("/campaigntemplate/sms");
        }, redirectEmailCampaignApi() {
            this.$router.push("/campaigntemplate/email");
        }, redirectAudioCallCampaignApi() {
            this.$router.push("/campaigntemplate/audiocall");
        }, redirectNotificationCampaignApi() {
            this.$router.push("/campaigntemplate/pushnotification");
        },

        getwhatsappcampaigntemplateList() {
            this.ApiService.getwhatsappcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("whatsapp_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("whatsapp_templates", []);
                }
            });
        },
        getcallcampaigntemplateList() {
            this.ApiService.getcallcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("call_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("call_templates", []);
                }
            });
        },
        getsmscampaigntemplates() {
            this.ApiService.getsmscampaigntemplates().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("sms_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("sms_templates", []);
                }
            });
        },
    },
};
</script>
<style scoped>
.custom-start-menu-col {
    width: 21%;
}

.custom-end-body-col {
    width: 79%;
    padding-left: 0;
}

.custom-vertical-modal-tab {
    width: 213px;
    min-width: 213px;
    height: 460px;
}
</style>