<template>
  <div class="col-md-3 col-lg-2 header-navbar-outer">
    <div class="w-100">
      <div class="navbar-brand-fixed-outer">
        <header class="navbar navbar-dark bg-dark flex-md-nowrap">
          <div class="navbar-brand me-0">
            <div class="navbar-brand-image d-lg-flex d-block justify-content-between">
              <span>
              <img v-if="this.client_info.maa66 != null && this.client_info.maa66 != ''" :src="sub_leader_logo"
                class="rounded-circle dashboard-img-border d-block" alt="sub_leader_logo" width="40" height="40" />
              </span>
              <span>
              <img v-if="this.client_info.maa47 != null && this.client_info.maa47 != ''" :src="leader_logo"
                class="rounded-circle dashboard-img-border d-block" alt="chairman-photo" width="40" height="40" />
              </span>
            </div>
            <div class="navbar-brand-label text-capitalize">{{ client_partyname }}</div>
          </div>
          <button class="navbar-toggler d-md-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </header>
      </div>
      <nav id="sidebarMenu" class="d-md-block collapse nav-navlink-outer">
        <div class="menubar-outer">
          <ul class="nav flex-column" id="accordionNavLinkParent">
            <li class="nav-item">
              <router-link class="nav-link" to="/dashboard"><img src="/assets/images/icon/overview-tab-icon.svg"
                  class="navlink-tab-icon" alt="" width="16" />Dashboard</router-link>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('2')">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-settings-collapse" aria-expanded="false">
                <span class="mr-auto"><img src="/assets/images/icon/setting-menu-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />Settings</span>
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-settings-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li v-show="this.permission_fks.includes('3')">
                    <router-link class="nav-link" to="/underprogress">API</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('11')">
                    <router-link class="nav-link" to="/underprogress">DLT</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('4')">
                    <router-link class="nav-link" to="/underprogress">Templates</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('5')">
                    <router-link class="nav-link" to="/underprogress">Offices</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('52')">
                    <router-link class="nav-link" to="/settings/designation">Designations</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('35')">
                    <router-link class="nav-link" to="/tags">Voter Tags</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('36')">
                    <router-link class="nav-link" to="/eventsettings/sms">Event Settings</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('6')">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-voter-collapse" aria-expanded="false">
                <img src="/assets/images/icon/voters-menu-icon.svg" class="navlink-tab-icon" alt="" width="16" />Voter's List
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-voter-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li v-show="this.permission_fks.includes('12')">
                    <router-link class="nav-link" to="/voters/addusingaadhar">Add Voter</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('13')">
                    <router-link class="nav-link" to="/voters/incomplete">Incomplete Voters</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('14')">
                    <router-link class="nav-link" to="/voters/myvoters">Voter's List</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('15')">
                    <router-link class="nav-link disabled-router" to="/voters/mismatchedbyaddress">Mismatched Voters</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('17')">
                    <router-link class="nav-link" to="/voters/unassigned">Unassigned Voters</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('16')">
                    <router-link class="nav-link" to="/voters/search">Search Voters</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('18')">
                    <router-link class="nav-link" to="/voters/import">Import Voters</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('69')"> 
              <router-link class="nav-link" to="/appusers/usage"><img src="/assets/images/icon/app-install.svg"
                  class="navlink-tab-icon" alt="" width="16" />App Users</router-link>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('67')"> 
              <router-link class="nav-link" to="/birthdays/todays"><img src="/assets/images/icon/appointment-tab-icon.svg"
                  class="navlink-tab-icon" alt="" width="16" />Birthdays</router-link>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('63')">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-my-request" aria-expanded="false">
                <img src="/assets/images/icon/campaign-menu-icon.svg" class="navlink-tab-icon" alt=""
                  width="16" />Approvals
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-my-request">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li v-show="this.permission_fks.includes('64')">
                    <router-link class="nav-link" to="/approvals/myrequest">My Requests</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('65')">
                    <router-link class="nav-link" to="/approvals/approverequest">Requests To Approve</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('10')">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-new-campaign-collapse" aria-expanded="false">
                <img src="/assets/images/icon/campaign-menu-icon.svg" class="navlink-tab-icon" alt=""
                  width="16" />Campaign
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-new-campaign-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li v-show="this.permission_fks.includes('19')">
                    <router-link class="nav-link" to="/campaign">Campaign Listing</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('20')">
                    <router-link class="nav-link" to="/campaigntemplate/intelligent">Campaign Template</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('21')">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-upcoming-users-collapse" aria-expanded="false">
                <img src="/assets/images/icon/customers-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Users
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-upcoming-users-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li v-show="this.permission_fks.includes('22')">
                    <router-link class="nav-link" to="/user/add">Add User</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('23')">
                    <router-link class="nav-link" to="/user/listing">List Users</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('24')">
                    <router-link class="nav-link" to="/user/permissionsets">Permission Sets</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('25')">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-upcoming-karyakartas-collapse" aria-expanded="false">
                <img src="/assets/images/icon/users-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Karyakartas
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-upcoming-karyakartas-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li v-show="this.permission_fks.includes('26')">
                    <router-link class="nav-link" to="/karyakartas/add">Add Karyakarta</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('27')">
                    <router-link class="nav-link" to="/karyakartas/list">List Karyakartas</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('62')">
              <router-link class="nav-link" to="/jantadarbar"><img src="/assets/images/icon/campaign-menu-icon.svg"
                  class="navlink-tab-icon" alt="" width="16" />Janta Darbar</router-link>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('32')">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-system-phone-collapse" aria-expanded="false">
                <img src="/assets/images/icon/cloud-telephony-menu-icon.svg" class="navlink-tab-icon" alt=""
                  width="16" />Cloud Telephony
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-system-phone-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li v-show="this.permission_fks.includes('33')">
                    <router-link class="nav-link" to="/cloudtelephony/virtualnumbers">Virtual Numbers</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('55')">
                    <router-link class="nav-link" to="/cloudtelephony/customtunes">Custom Tunes</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('68')">
              <router-link class="nav-link" to="/generateaudio"><img src="/assets/images/icon/campaign-menu-icon.svg"
                  class="navlink-tab-icon" alt="" width="16" />Generate Audio</router-link>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('37') && (this.billingaccess == 1 || this.billingaccess == 2 || this.billingaccess == 4)">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-system-billing-collapse" aria-expanded="false">
                <img src="/assets/images/icon/billing-menu-icon.svg" class="navlink-tab-icon" alt="" width="16" />Billing
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-system-billing-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li v-show="this.permission_fks.includes('38')">
                    <router-link class="nav-link" to="/billing/dashboard">Dashboard</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('39')">
                    <router-link class="nav-link" to="/billing/invoice">Tax Invoices</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('40')">
                    <router-link class="nav-link" to="/billing/statement">Account Statement</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('41')">
                    <router-link class="nav-link" to="/billing/receipt">Payment Receipts</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('34')">
                    <router-link class="nav-link" to="/companydetails">Company Details</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" v-show="this.permission_fks.includes('42')">
              <router-link class="nav-link" to="/support/new"><img src="/assets/images/icon/support-menu-icon.svg"
                  class="navlink-tab-icon" alt="" width="16" />Support Tickets</router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/opensms"><img src="/assets/images/icon/feedback-tab-icon.svg"
                  class="navlink-tab-icon" alt="" width="16" />Open SMS</router-link>
            </li> -->
            <li class="nav-item" v-show="this.permission_fks.includes('46')">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#nav-logs-collapse" aria-expanded="false">
                <img src="/assets/images/icon/system-logs-menu-icon.svg" class="navlink-tab-icon" alt=""
                  width="16" />System Logs
              </button>
              <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-logs-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li v-show="this.permission_fks.includes('48')">
                    <router-link class="nav-link" to="/logs/webbotlogs">Web Bot Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('47')">
                    <router-link class="nav-link" to="/logs/sms">SMS Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('50')">
                    <router-link class="nav-link" to="/logs/myincoming">Incoming Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('51')">
                    <router-link class="nav-link" to="/logs/myoutgoing">Outgoing Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('46')">
                    <router-link class="nav-link disabled-router" to="/underprogress">RCS Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('46')">
                    <router-link class="nav-link disabled-router" to="/underprogress">iMessage Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('49')">
                    <router-link class="nav-link" to="/logs/email">Email Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('56')">
                    <router-link class="nav-link" to="/logs/aadhar-v1-card">Aadhar Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('57')">
                    <router-link class="nav-link" to="/logs/voter/_search">Voter Card Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('58')">
                    <router-link class="nav-link" to="/logs/pan-card">Pan Card Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('59')">
                    <router-link class="nav-link" to="/logs/driving-licence">Driving Licence Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('46')">
                    <router-link class="nav-link disabled-router" to="/underprogress">RTO Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('60')">
                    <router-link class="nav-link" to="/logs/passport">Passport Logs</router-link>
                  </li>
                  <li v-show="this.permission_fks.includes('61')">
                    <router-link class="nav-link" to="/logs/importvoter">Import Logs</router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div class="copyright-outer mt-auto">
        <div class="copyright-inner">
          <div class="logo-outer">
            <img src="/assets/images/meraneta-logo-white.png" class="img-fluid" alt="" width="74" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      client_name: "",
      client_partyname: "",
      client_info: "",
      sub_leader_logo: "",
      leader_logo: "",
      permission_fks: localStorage.permission_fks,
      baseUrl :window.location.origin,
      billingaccess: '',
    };
  },
  mounted() {
    var whatsappmessageinput = localStorage.permission_fks.replaceAll('"', '');
    if (this.permission_fks) {
      this.permission_fks = whatsappmessageinput.split(",");
    } else {
      this.permission_fks = [];
    }
    var userData = JSON.parse(localStorage.user);
    this.billingaccess = userData.ak40;
    this.client_info = JSON.parse(localStorage.client_info);
    this.client_partyname = this.client_info.maa65;
    if(this.baseUrl == 'http://localhost:8080' || this.baseUrl == 'http://localhost:8081') {
      this.client_path = this.client_info.maa24;
    } else {
      this.client_path = localStorage.masterbucketname;
    }
    this.sub_leader_logo = "https://storage.googleapis.com/" + this.client_path + "/Client/PartyLeaderLogo/" + this.client_info.maa66;
    this.leader_logo = "https://storage.googleapis.com/" + this.client_path + "/Client/PartyLogo/" + this.client_info.maa47;
  },
};
</script>
<style scoped>
.disabled-router {
  opacity: 0.5;
    pointer-events: none;
}</style>

