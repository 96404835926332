<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-3 col-12"></div>
    <div class="col-lg-9 col-12
        text-end
        d-flex
        justify-content-end">
      <div>
        <InputSwitch class="me-3" v-model="ApiLoadingSwitch" @change="switchLoadingStatus()" />
        <button type="button" title="Refresh" class="btn custom-outline-view-btn me-3" @click="this.getAppUsageByVotersList({
          page_no: this.page_no,
          items_per_page: this.items_per_page,
          date_type: this.filterlistby, is_app_usages_voters: 0
        });">
          <i class="pi pi-refresh call-btn-color"></i>
        </button>
      </div>
      <div class="datatable-input-filter">
        <Multiselect v-model="filterlistby" :options="filterlistbyList" :searchable="false" label="label"
          placeholder="Select" class="multiselect-custom text-capitalize me-0" :canDeselect="false" :canClear="false"
          :closeOnSelect="true" :object="false" :style="{ width: '320px' }" @select="applyoptionFilter()" />
      </div>
      <button type="button" @click="ticketFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
        title="Filter">
        <span class="custom-filter-apply-alert"></span>
        <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link custom-navbar-navlink " id="lastused-details-tab" data-bs-toggle="tab"
        data-bs-target="#lastusedappdetail" type="button" role="tab" aria-controls="vehicle-details"
        aria-selected="false" :disabled="loading" @click="redirectlastusedapp()"><i class="pi pi-calendar me-1"
          :style="{ fontSize: '0.7rem' }"></i>App Usage
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link custom-navbar-navlink active" id="installed-details-tab" data-bs-toggle="tab"
        data-bs-target="#installedappdetail" type="button" role="tab" aria-controls="customer-details"
        aria-selected="true" @click="redirectinstalledapp()" :disabled="loading">
        <i class="pi pi-calendar-plus me-1" :style="{ fontSize: '0.7rem' }"></i>App Installs
      </button>
    </li>
  </ul>
  <div class="tab-content mt-2" id="myTabContent">
    <div class="tab-pane fade " id="lastusedappdetail" role="tabpanel" aria-labelledby="lastused-details-tab">
      App Installed User
    </div>
    <div class="tab-pane fade show active" id="installedappdetail" role="tabpanel"
      aria-labelledby="installed-details-tab">
      <div class="custom-ultima-datatable" style="height: calc(100vh - 185px)">
        <DataTable :value="appInstalledList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
          :paginator="true" :rows="items_per_page" :lazy="true" dataKey="ak1" :rowHover="true"
          :totalRecords="totalRecords" @page="changePage($event)"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          :loading="loading">
          <template v-if="!loading" #empty>No records found.</template>
          <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
          <Column field="installeddate" header="App Installs Date" headerStyle="width: 16%" bodyStyle="width: 16%">
            <template #body="{ data }">
              <div class="from-label-value">{{ format_timestamp(data.ak229) }}</div>
            </template>
          </Column>
          <Column field="modelname" header="Mobile Brand / Model Name" headerStyle="width: 24%" bodyStyle="width: 24%">
            <template #body="{ data }">
              <div class="w-100">
                <div class="label-subheading text-capitalize cursor-default"><span>Brand : </span><span
                    class="text-muted">{{ data.ak227 ? data.ak227 : "N/A" }}</span></div>
                <Divider type="dashed" v-if="data.ak226" />
                <div class="label-subheading cursor-default" v-if="data.ak226"><span>Model : </span><span
                    class="text-muted">{{ data.ak226 ? data.ak226 : "N/A" }}</span></div>
              </div>
            </template>
          </Column>
          <Column field="modelname" header="Mobile OS Version" headerStyle="width: 15%" bodyStyle="width: 15%">
            <template #body="{ data }">
              <div class="w-100">
                <div class="label-subheading cursor-default">
                  <span v-if="data.ak228 == 1">Android Version : </span>
                  <span v-if="data.ak228 == 2">iOS Version : </span>
                  <span>
                    <span v-if="data.ak230" class="text-muted cursor-default" title="Version">{{ data.ak230 ? data.ak230
                      :
                      "N/A" }}</span>
                    <span v-else>N/A</span>
                  </span>
                </div>
              </div>
            </template>
          </Column>
          <Column field="name" header="Voter Details" headerStyle="width: 29%" bodyStyle="width: 29%">
            <template #body="{ data }">
              <div class="w-100">
                <div class="label-subheading text-capitalize"> {{ data.ak5 ? data.ak5 : "N/A" }}</div>
                <Divider type="dashed" v-if="data.ak61" />
                <div class="label-subheading text-capitalize" v-if="data.ak61">
                  <span>Constituency : </span><span class="text-muted">{{ data.ak61 ? data.ak61 : "N/A" }}</span>
                </div>
                <Divider type="dashed" v-if="data.ak46 || data.ak55 || data.ak171" />
                <div class="label-subheading text-capitalize" v-if="data.ak46 || data.ak55 || data.ak171">
                  <span>City : </span>
                  <span class="text-muted" v-if="data.ak46">{{ data.ak46 }}</span>
                  <span class="text-muted" v-else-if="data.ak55">{{ data.ak55 }}</span>
                  <span class="text-muted" v-else-if="data.ak171">{{ data.ak171 }}</span>
                  <span v-else>N/A</span>
                </div>
                <Divider type="dashed" v-if="data.ak24" />
                <div class="label-subheading" v-if="this.allowshowusermobile == 0 && data.ak24">
                  +91 {{ data.ak24 ? maskedNumber(data.ak24) : "N/A" }}
                </div>
                <div class="label-subheading" v-if="this.allowshowusermobile == 1 && data.ak24">
                  +91 {{ data.ak24 ? data.ak24 : "N/A" }}
                </div>
              </div>
            </template>
          </Column>
          <Column field="updateddate" header="App Usage Date" headerStyle="width: 16%" bodyStyle="width: 16%">
            <template #body="{ data }">
              <div class="from-label-value">{{ format_timestamp(data.ak224) }}</div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- filter start here -->
  <div class="modal-mask" v-if="ticketfiltermodalsflag">
    <div class="modal-sm modal-dialog-scrollable modal-fullscreen ms-auto custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
          <button type="button" class="btn-close" @click="ticketFilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">App Installs From Date</label>
                <Calendar v-model="filter.installedfromdate" :showIcon="true" class="custom-ultima-calendar"
                  placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :maxDate="todaysDate" :manualInput="false"
                  :monthNavigator="true" :yearNavigator="true" :yearRange="'2000:' + +new Date().getFullYear()"
                  appendTo="body" :disabled="this.filterlistby != 0" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">To Date</label>
                <Calendar v-model="filter.installedtodate" :showIcon="true" class="custom-ultima-calendar"
                  placeholder="DD/MM/YYYY" :disabled="filter.installedfromdate == null" dateFormat="dd/mm/yy"
                  :minDate="filter.installedfromdate" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true"
                  :yearNavigator="true" :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">App Usage From Date</label>
                <Calendar v-model="filter.updatedfromdate" :showIcon="true" class="custom-ultima-calendar"
                  placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :maxDate="todaysDate" :manualInput="false"
                  :monthNavigator="true" :yearNavigator="true" :yearRange="'2000:' + +new Date().getFullYear()"
                  appendTo="body" :disabled="this.filterlistby != 0" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">To Date</label>
                <Calendar v-model="filter.updatedtodate" :showIcon="true" class="custom-ultima-calendar"
                  placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :minDate="filter.updatedfromdate" :maxDate="todaysDate"
                  :manualInput="false" :monthNavigator="true" :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()" appendTo="body"
                  :disabled="filter.updatedfromdate == null" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Voter Name</label>
                <input type="text" v-model="filter.votername" @keypress="isLetterWithSpace($event)"
                  class="form-control text-capitalize" placeholder="Enter Voter Name" maxlength="30"
                  autocomplete="off" />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Voter Mobile Number</label>
                <div class="input-group custom-input-group">
                  <div class="col-lg-3 col-md-3 col-12">
                    <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true" label="label"
                      placeholder="Select" disabled class="multiselect-custom custom-title-multiselect"
                      :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                      :canDeselect="false" />
                  </div>
                  <div class="col-lg-9 col-md-9 col-12">
                    <input type="text" v-model="filter.mobilenumber" class="form-control"
                      placeholder="Enter Voter Mobile Number" maxlength="10" id="formMobileNoInput"
                      @keypress="onlyNumberMobile" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">Mobile Brand Name</label>
                <input type="text" v-model="filter.brandname" class="form-control text-capitalize"
                  placeholder="Enter Brand Name" maxlength="30" autocomplete="off" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">Type of Mobile</label>
                <div class="custom-group-radio-box-btn">
                  <input type="radio" class="btn-check" v-model="filter.typemobile" name="callstatus-option"
                    id="typeandroid" autocomplete="off" value="1" />
                  <label class="btn custom-radio-box-btn text-capitalize" for="typeandroid">Android</label>
                  <input type="radio" class="btn-check" v-model="filter.typemobile" name="callstatus-option"
                    id="typeios" autocomplete="off" value="2" />
                  <label class="btn" for="typeios">iOS</label>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="custom-form-group">
                <label class="form-label">OS Version</label>
                <input type="text" v-model="filter.mobileversion" class="form-control text-capitalize"
                  placeholder="Enter OS Version" maxlength="30" autocomplete="off" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button class="btn filter-apply-btn" @click="
                applyFilter()" :disabled="filter == ''">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" :disabled="filter == ''" @click="resetVoterFilter()">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import moment from 'moment';
export default {
  data() {
    return {
      appInstalledList: null,
      totalRecords: 0,
      page_no: 0,
      items_per_page: 30,
      loading: false,

      filterlistby: 1,
      filterlistbyList: [
        { value: 1, label: "Today's" },
        { value: 2, label: "Yesterday's" },
        { value: 3, label: "This Week's (From Monday Till Now)" },
        { value: 4, label: "This Month's (From 1st Till Now)" },
        { value: 0, label: "All Records" },
      ],
      ticketfiltermodalsflag: false,

      searchscamdate: null,
      searchreporteddate: null,
      maxDate: new Date(),
      filter: {
        installedfromdate: null,
        installedtodate: null,
        updatedfromdate: null,
        updatedtodate: null,
        votername: '',
        mobilenumber: '',
        brandname: '',
        typemobile: '',
        mobileversion: '',
      },
      todaysDate: new Date(),
      ApiLoadingSwitch: false,
      user_info: '',
      allowshowusermobile: '',
      countrycode: { value: 101, label: "+91" },
      countryCodeList: [],
    };
  },
  beforeUnmount() {
    clearInterval(this.timer5);
  },
  mounted() {
    this.getAppUsageByVotersList({ is_app_usages_voters: 0, date_type: 1 });
    this.user_info = JSON.parse(localStorage.user);
    this.allowshowusermobile = this.user_info.ak105
  },

  ApiService: null,
  interval: null,

  created() {
    this.ApiService = new ApiService();
  },

  methods: {

    getAppUsageByVotersList(event) {
      this.loading = true;
      this.ApiService.getAppUsageByVotersList(event).then((items) => {
        if (items.success == true) {
          this.appInstalledList = items.data;
          this.totalRecords = items.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.appInstalledList = null;
          this.totalRecords = 0;
        }
      });
    },

    changePage(event) {
      this.page_no = event.page;
      this.getAppUsageByVotersList({
        page_no: this.page_no,
        items_per_page: this.items_per_page,
        is_app_usages_voters: 0,
        ak229from: this.filter.installedfromdate,
        ak229to: this.filter.installedtodate,
        ak224from: this.filter.updatedfromdate,
        ak224to: this.filter.updatedtodate,
        ak5: this.filter.votername,
        ak24: this.filter.mobilenumber,
        ak227: this.filter.brandname,
        ak228: this.filter.typemobile,
        ak230: this.filter.mobileversion,
        date_type: this.filterlistby,
      });
    },
    ticketFilterModalOpen() {
      this.ticketfiltermodalsflag = true;
      this.ApiLoadingSwitch = false;
      clearInterval(this.timer5);
    },
    ticketFilterModalClose() {
      this.ticketfiltermodalsflag = false;
    },
    applyoptionFilter() {
      this.filter = {},
        this.getAppUsageByVotersList({
          page_no: this.page_no,
          items_per_page: this.items_per_page,
          date_type: this.filterlistby,
          is_app_usages_voters: 0
        });
    },
    applyFilter() {
      this.ticketfiltermodalsflag = false;
      this.getAppUsageByVotersList({
        page_no: this.page_no,
        items_per_page: this.items_per_page,
        ak229from: this.filter.installedfromdate ? moment(this.filter.installedfromdate).format("YYYY-MM-DD") : null,
        ak229to: this.filter.installedtodate ? moment(this.filter.installedtodate).format("YYYY-MM-DD") : null,
        ak224from: this.filter.updatedfromdate ? moment(this.filter.updatedfromdate).format("YYYY-MM-DD") : null,
        ak224to: this.filter.updatedtodate ? moment(this.filter.updatedtodate).format("YYYY-MM-DD") : null,
        ak5: this.filter.votername,
        ak24: this.filter.mobilenumber,
        ak227: this.filter.brandname,
        ak228: this.filter.typemobile,
        ak230: this.filter.mobileversion,
        date_type: this.filterlistby,
        is_app_usages_voters: 0
      });
    },
    resetVoterFilter() {
      this.filter = {},
        this.getAppUsageByVotersList({
          page_no: this.page_no,
          items_per_page: this.items_per_page,
          date_type: this.filterlistby,
          is_app_usages_voters: 0
        });
      this.ticketfiltermodalsflag = false;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return '-';
      }
    },
    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },
    switchLoadingStatus() {
      if (this.ApiLoadingSwitch == true) {
        this.timer5 = window.setInterval(() => {
          this.reloadAppUserList({
            page_no: this.page_no,
            items_per_page: this.items_per_page,
            date_type: this.filterlistby,
            is_app_usages_voters: 0
          });
        }, 2000);
      } else {
        clearInterval(this.timer5);
      }
    },
    reloadAppUserList(event) {
      this.ApiService.getAppUsageByVotersList(event).then((items) => {
        if (items.success == true) {
          this.appInstalledList = items.data;
          this.totalRecords = items.count;
        } else {
          this.appInstalledList = null;
          this.totalRecords = 0;
        }
      });
    },
    redirectlastusedapp() {
      this.$router.push("/appusers/usage");
    },
    redirectinstalledapp() {
      this.$router.push("/appusers/installs");
    },
    maskedNumber(getvalue) {
      const maskedDigits = 'X'.repeat(getvalue.length - 4);
      const FirstThreeDigits = getvalue.slice(0, 2);
      const lastThreeDigits = getvalue.slice(-2);
      return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
    onlyNumberMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var mobileInput = document.getElementById("formMobileNoInput");
      if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
        $event.preventDefault();
      }
    },
  },
};
</script>

<style scoped></style>