<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Add Campaign Templates</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
            <button type="button" @click="newtemplateModalOpen()" class="header-btn-outer btn btn-primary">
                Add Campaign Template
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4 " id="intelligent-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#intelligentcampaigndetail" type="button" role="tab" aria-controls="vehicle-details"
                aria-selected="false" @click="redirectIntelligentCampaignApi()">
                Intelligent
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="imessage-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#imessagecampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectiMessageCampaignApi()">
                RCS / iMessage
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="sms-campaign-tab" data-bs-toggle="tab" data-bs-target="#smscampaigndetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="redirectSMSCampaignApi()">
                SMS
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="email-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#emailcampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectEmailCampaignApi()">
                Email
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4 active" id="audio-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#audiocampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="true">
                Audio Call
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="notification-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#notificationcampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectNotificationCampaignApi()">
                Push Notification
            </button>
        </li>
    </ul>
    <div class="tab-content mt-2" id="myTabContent">
        <div class="tab-pane fade" id="intelligentcampaigndetail" role="tabpanel"
            aria-labelledby="intelligent-campaign-tab">

        </div>
        <div class="tab-pane fade" id="whatsappcampaigndetail" role="tabpanel" aria-labelledby="whatsapp-campaign-tab">

        </div>
        <div class="tab-pane fade" id="imessagecampaigndetail" role="tabpanel" aria-labelledby="imessage-campaign-tab">

        </div>
        <div class="tab-pane fade" id="smscampaigndetail" role="tabpanel" aria-labelledby="sms-campaign-tab">

        </div>
        <div class="tab-pane fade" id="emailcampaigndetail" role="tabpanel" aria-labelledby="email-campaign-tab">

        </div>
        <div class="tab-pane fade show active" id="audiocampaigndetail" role="tabpanel"
            aria-labelledby="audio-campaign-tab">
            <div class="custom-ultima-datatable" style="height: calc(100vh - 188px)">
                <DataTable :value="audioCampaignList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit"
                    :paginator="true" :lazy="true" :rowHover="true" :totalRecords="audioRecords" :rows="30"
                    dataKey="cl1" @page="changePage($event)"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :loading="loading">
                    <template v-if="!loading" #empty>No records found.</template>
                    <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                    <Column field="Name" header="Campaign Name" headerStyle="width: 60%" bodyStyle="width: 60%">
                        <template #body="{ data }">
                            <div>{{ data.cl2 ? data.cl2 : "N/A" }}</div>
                        </template>
                    </Column>
                    <Column field="message" header="Campaign Message" headerStyle="width: 30%" bodyStyle="width: 30%">
                        <template #body="{ data }">
                            <div v-if="data.cl3 != null && data.cl3 != ''"><audio controls controlsList="download">
                                    <source
                                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/callcampaign/' + data.cl3"
                                        type="audio/mpeg" />
                                </audio>
                            </div>
                            <div v-if="data.cl3 == null">-</div>
                        </template>
                    </Column>
                    <Column field="status" header="Status" headerStyle="width: 10%" bodyStyle="width: 10%">
                        <template #body="{ data }">
                            <div class="d-flex align-items-center">
                                <button type="button" title="View Details" class="btn custom-outline-view-btn ms-2"
                                    @click="viewCallDetailModalOpen(data)">
                                    <i class="pi pi-eye call-btn-color"></i>
                                </button>
                                <button type="button" title="Edit" class="btn custom-outline-view-btn ms-2"
                                    @click="editCallTemplateModalOpen(data)">
                                    <i class="pi pi-pencil call-btn-color"></i>
                                </button>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <div class="tab-pane fade" id="notificationcampaigndetail" role="tabpanel"
            aria-labelledby="notification-campaign-tab">

        </div>
    </div>
    <!-- Add Template modal start here -->
    <div class="modal-mask" v-if="$store.state.newtempaltemodalstatus">
        <div class="modal-dialog modal-xl modal-dialog-centered
            custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Campaign Template</h5>
                    <button type="button" class="btn-close" @click="newtemplateModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-12 custom-start-menu-col">
                            <ul class="nav nav-tabs custom-vertical-modal-tab flex-column" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="intelligent-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#intelligentcampaigntarget" type="button" role="tab"
                                        aria-controls="intelligent-campaign" aria-selected="false"
                                        @click="selectedtab((e = 1))">
                                        Intelligent Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="imessage-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#imessagecampaigntarget" type="button" role="tab"
                                        aria-controls="imessage-campaign" aria-selected="false"
                                        @click="selectedtab((e = 3))">
                                        RCS / iMessage Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="sms-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#smscampaigntarget" type="button" role="tab"
                                        aria-controls="sms-campaign" aria-selected="false"
                                        @click="selectedtab((e = 4))">
                                        SMS Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="email-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#emailcampaigntarget" type="button" role="tab"
                                        aria-controls="email-campaign" aria-selected="false"
                                        @click="selectedtab((e = 5))">
                                        Email Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="audio-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#audiocampaigntarget" type="button" role="tab"
                                        aria-controls="audio-campaign" aria-selected="true"
                                        @click="selectedtab((e = 6))">
                                        Audio Call Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="notification-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#notificationcampaigntarget" type="button" role="tab"
                                        aria-controls="notification-campaign" aria-selected="false"
                                        @click="selectedtab((e = 7))">
                                        Push Notification Campaign
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-9 col-md-9 col-12 custom-end-body-col">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade" id="intelligentcampaigntarget" role="tabpanel"
                                    aria-labelledby="intelligent-campaign-tab">
                                    <div v-if="selected_tab == 1">
                                        <IntelligentCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="whatsappcampaigntarget" role="tabpanel"
                                    aria-labelledby="whatsapp-campaign-tab">
                                    <div v-if="selected_tab == 2">
                                        <WhatsAppCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="imessagecampaigntarget" role="tabpanel"
                                    aria-labelledby="imessage-campaign-tab">
                                    <div v-if="selected_tab == 3">
                                        <iMessageCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="smscampaigntarget" role="tabpanel"
                                    aria-labelledby="sms-campaign-tab">
                                    <div v-if="selected_tab == 4">
                                        <SMSCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="emailcampaigntarget" role="tabpanel"
                                    aria-labelledby="email-campaign-tab">
                                    <div v-if="selected_tab == 5">
                                        <EmailCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane show active" id="audiocampaigntarget" role="tabpanel"
                                    aria-labelledby="audio-campaign-tab">
                                    <div v-if="selected_tab == 6">
                                        <AudioCallCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="notificationcampaigntarget" role="tabpanel"
                                    aria-labelledby="notification-campaign-tab">
                                    <div v-if="selected_tab == 7">
                                        <PushNotificationCampaignTab />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Template modal end here -->
    <!-- View Email Body start here -->
    <div class="modal-mask" v-if="viewcalltemplatedetailmodalstatus">
        <div class="modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">{{ this.headeraliasename }}</h5>
                    <button type="button" class="btn-close" @click="viewCallDetailModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Header Name</label>
                                    <div class="from-label-value">
                                        {{ callTemplateBody.cl3 ? callTemplateBody.cl3 : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12" v-if="callTemplateBody.cl8">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Campaign Response Type</label>
                                    <div class="from-label-value text-capitalize">
                                        <span v-if="callTemplateBody.cl8 == 0">No Response</span>
                                        <span v-if="callTemplateBody.cl8 == 1">With Response</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Status</label>
                                    <div class="from-label-value">
                                        <span v-if="callTemplateBody.cl6 == 1">Active</span>
                                        <span v-else>Inactive</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="voter-exit-box-outer">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <div v-if="callTemplateBody.cl3 != null && callTemplateBody.cl3 != ''"><audio
                                            controls controlsList="download" style="height: 35px !important;">
                                            <source
                                                :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/callcampaign/' + callTemplateBody.cl3"
                                                type="audio/mpeg" />
                                        </audio>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <button v-if="callTemplateBody.cl11 == 1" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 me-2" disabled><span
                                            class="me-2 fw-bold">{{
                callTemplateBody.cl9
            }}</span>- {{
                    callTemplateBody.cl10 }}</button>
                                    <button v-if="callTemplateBody.cl14 == 1" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 me-2" disabled><span
                                            class="me-2 fw-bold">{{
                callTemplateBody.cl12
            }}</span>- {{
                    callTemplateBody.cl13 }}</button>
                                    <button v-if="callTemplateBody.cl17 == 1" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 me-2" disabled><span
                                            class="me-2 fw-bold">{{
                callTemplateBody.cl15
            }}</span>- {{
                    callTemplateBody.cl16 }}</button>
                                    <button v-if="callTemplateBody.cl20 == 1" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 me-2" disabled><span
                                            class="me-2 fw-bold">{{
                callTemplateBody.cl18
            }}</span>- {{
                    callTemplateBody.cl19 }}</button>
                                    <button v-if="callTemplateBody.cl23 == 1" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 me-2" disabled><span
                                            class="me-2 fw-bold">{{
                callTemplateBody.cl21
            }}</span>- {{
                    callTemplateBody.cl22 }}</button>
                                    <button v-if="callTemplateBody.cl26 == 1" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 me-2" disabled><span
                                            class="me-2 fw-bold">{{
                callTemplateBody.cl24
            }}</span>- {{
                    callTemplateBody.cl25 }}</button>
                                    <button v-if="callTemplateBody.cl29 == 1" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 me-2" disabled><span
                                            class="me-2 fw-bold">{{
                callTemplateBody.cl27
            }}</span>- {{
                    callTemplateBody.cl28 }}</button>
                                    <button v-if="callTemplateBody.cl32 == 1" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 me-2" disabled><span
                                            class="me-2 fw-bold">{{
                callTemplateBody.cl30
            }}</span>- {{
                    callTemplateBody.cl31 }}</button>
                                    <button v-if="callTemplateBody.cl35 == 1" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 me-2" disabled><span
                                            class="me-2 fw-bold">{{
                callTemplateBody.cl33
            }}</span>- {{
                    callTemplateBody.cl34 }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- View Email Body End here -->
    <!-- Edit Call Template modal stat here -->
    <div class="modal-mask" v-if="editCallTemplateDialog">
        <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Edit Audio Call Campaign Template</h5>
                    <button type="button" class="btn-close" @click="editCallTemplateModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="campaign-tab-outer-scroll" :style="{ height: '550px' }">
                        <div v-if="!showmodalloader">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Template Name <span
                                                class="text-danger">*</span></label>
                                        <input type="text" v-model="templatename" v-on:keyup="checkTemplateName()"
                                            class="form-control" placeholder="Enter Template Name" maxlength="250"
                                            autocomplete="off" />
                                        <div class="custom-error" v-if="v$.templatename.$error">
                                            {{ v$.templatename.$errors[0].$message }}
                                        </div>
                                        <div class="custom-error" v-if="duplicateTemplateMsg">
                                            {{ duplicateTemplateMsg }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Language<span
                                                class="text-danger">*</span></label>
                                        <Multiselect v-model="languagevalue" :options="languagelist" :searchable="true"
                                            label="label" placeholder="Select Language"
                                            class="multiselect-custom text-capitalize" :canClear="false"
                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                                        <div class="custom-error" v-if="v$.languagevalue.$error">
                                            {{ v$.languagevalue.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Response Type<span
                                                class="text-danger">*</span></label>
                                        <div class="custom-group-radio-box-btn">
                                            <input type="radio" class="btn-check" v-model="responsetype"
                                                name="none-option" id="noresponse" autocomplete="off" value="0" />
                                            <label class="btn custom-radio-box-btn text-capitalize" for="noresponse">No
                                                Response</label>
                                            <input type="radio" class="btn-check" v-model="responsetype"
                                                name="with-option" id="withresponse" autocomplete="off" value="1" />
                                            <label class="btn custom-radio-box-btn text-capitalize"
                                                for="withresponse">With
                                                Response</label>
                                        </div>
                                        <div class="custom-error" v-if="v$.responsetype.$error">
                                            {{ v$.responsetype.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Audio<span class="text-danger">*</span></label>
                                        <FileUpload mode="basic" accept=".mp3" ref="headeraudioref"
                                            class="custom-upload-btn-ultima" v-model="headeraudio"
                                            v-on:change="handlerAudioUploader()" />
                                        <div class="custom-error" v-if="errortuneaddfile">{{ errortuneaddfile }}</div>
                                        <div class="custom-error" v-if="v$.headeraudio.$error">
                                            {{ v$.headeraudio.$errors[0].$message }}
                                        </div>
                                    </div>
                                    <!-- <div v-if="editheaderaudio != null && exitaddpreviewurl == null">
                                        <audio controls controlsList="download">
                                            <source :src="editheaderaudio" type="audio/mpeg" />
                                        </audio>
                                    </div> -->
                                    <div class="text-primary mb-2" v-if="editheaderaudio != null && exitaddpreviewurl == null">{{ this.editheaderaudio }}</div>
                                    <div class="text-primary mb-2" v-if="exitaddpreviewurl != null">{{ exitaddpreviewurl.name }}</div>
                                </div>
                            </div>
                            <!--  -->
                            <div class="row" v-if="responsetype == 1">
                                <div class="col-lg-12 col-md-12 col-12 mb-3">
                                    <div class="form-box-outer">
                                        <!-- Response One  -->
                                        <div class="custom-form-group">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="responsenumberone"
                                                            id="responsenumberone"
                                                            class="form-control text-center fw-bold" disabled
                                                            maxlength="1" @keypress="onlyResponseOneFormat"
                                                            autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput"
                                                            style="width: 88%;">
                                                            <input type="text" v-model="responsevalueone"
                                                                class="form-control" placeholder="Enter Response Value"
                                                                maxlength="30" @keypress="isLetterWithSpace($event)"
                                                                autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.responsevalueone.$error">
                                                        {{ v$.responsevalueone.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="responsestatusone" class="mt-1"
                                                        @change="changeStatusOne(responsestatusone)" />
                                                    <div class="custom-error" v-if="v$.responsestatusone.$error">
                                                        {{ v$.responsestatusone.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="secondrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreSecondRow()"
                                                        class="btn custom-view-detail-btn"><i class="pi pi-plus me-1"
                                                            style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="secondrowshowstatus" />
                                        <!-- Response Two  -->
                                        <div class="custom-form-group" v-if="secondrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="responsenumbertwo"
                                                            id="responsenumbertwo"
                                                            class="form-control text-center fw-bold" disabled
                                                            maxlength="1" @keypress="onlyResponseTwoFormat"
                                                            autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput"
                                                            style="width: 88%;">
                                                            <input type="text" v-model="responsevaluetwo"
                                                                class="form-control" placeholder="Enter Response Value"
                                                                maxlength="30" @keypress="isLetterWithSpace($event)"
                                                                autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.responsevaluetwo.$error">
                                                        {{ v$.responsevaluetwo.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="responsestatustwo" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.responsestatustwo.$error">
                                                        {{ v$.responsestatustwo.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="thirdrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreThirdRow()"
                                                        class="btn custom-view-detail-btn"><i class="pi pi-plus me-1"
                                                            style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="thirdrowshowstatus" />
                                        <!-- Response Three  -->
                                        <div class="custom-form-group" v-if="thirdrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="responsenumberthree"
                                                            id="responsenumberthree"
                                                            class="form-control text-center fw-bold" disabled
                                                            maxlength="1" @keypress="onlyResponseThreeFormat"
                                                            autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput"
                                                            style="width: 88%;">
                                                            <input type="text" v-model="responsevaluethree"
                                                                class="form-control" placeholder="Enter Response Value"
                                                                maxlength="30" @keypress="isLetterWithSpace($event)"
                                                                autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.responsevaluethree.$error">
                                                        {{ v$.responsevaluethree.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="responsestatusthree" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.responsestatusthree.$error">
                                                        {{ v$.responsestatusthree.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="fourthrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreFourthRow()"
                                                        class="btn custom-view-detail-btn"><i class="pi pi-plus me-1"
                                                            style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="fourthrowshowstatus" />
                                        <!-- Response Four  -->
                                        <div class="custom-form-group" v-if="fourthrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="responsenumberfour"
                                                            id="responsenumberfour"
                                                            class="form-control text-center fw-bold" disabled
                                                            maxlength="1" @keypress="onlyResponseFourFormat"
                                                            autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput"
                                                            style="width: 88%;">
                                                            <input type="text" v-model="responsevaluefour"
                                                                class="form-control" placeholder="Enter Response Value"
                                                                maxlength="30" @keypress="isLetterWithSpace($event)"
                                                                autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.responsevaluefour.$error">
                                                        {{ v$.responsevaluefour.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="responsestatusfour" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.responsestatusfour.$error">
                                                        {{ v$.responsestatusfour.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="fifthrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreFifthRow()"
                                                        class="btn custom-view-detail-btn"><i class="pi pi-plus me-1"
                                                            style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="fifthrowshowstatus" />
                                        <!-- Response Five  -->
                                        <div class="custom-form-group" v-if="fifthrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="responsenumberfive"
                                                            id="responsenumberfive"
                                                            class="form-control text-center fw-bold" disabled
                                                            maxlength="1" @keypress="onlyResponseFiveFormat"
                                                            autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput"
                                                            style="width: 88%;">
                                                            <input type="text" v-model="responsevaluefive"
                                                                class="form-control" placeholder="Enter Response Value"
                                                                maxlength="30" @keypress="isLetterWithSpace($event)"
                                                                autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.responsevaluefive.$error">
                                                        {{ v$.responsevaluefive.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="responsestatusfive" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.responsestatusfive.$error">
                                                        {{ v$.responsestatusfive.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="sixthrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreSixthRow()"
                                                        class="btn custom-view-detail-btn"><i class="pi pi-plus me-1"
                                                            style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="sixthrowshowstatus" />
                                        <!-- Response Six  -->
                                        <div class="custom-form-group" v-if="sixthrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="responsenumbersix"
                                                            id="responsenumbersix"
                                                            class="form-control text-center fw-bold" disabled
                                                            maxlength="1" @keypress="onlyResponseSixFormat"
                                                            autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput"
                                                            style="width: 88%;">
                                                            <input type="text" v-model="responsevaluesix"
                                                                class="form-control" placeholder="Enter Response Value"
                                                                maxlength="30" @keypress="isLetterWithSpace($event)"
                                                                autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.responsevaluesix.$error">
                                                        {{ v$.responsevaluesix.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="responsestatussix" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.responsestatussix.$error">
                                                        {{ v$.responsestatussix.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="seventhrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreSeventhRow()"
                                                        class="btn custom-view-detail-btn"><i class="pi pi-plus me-1"
                                                            style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="seventhrowshowstatus" />
                                        <!-- Response Seven  -->
                                        <div class="custom-form-group" v-if="seventhrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="responsenumberseven"
                                                            id="responsenumberseven"
                                                            class="form-control text-center fw-bold" disabled
                                                            maxlength="1" @keypress="onlyResponseSevenFormat"
                                                            autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput"
                                                            style="width: 88%;">
                                                            <input type="text" v-model="responsevalueseven"
                                                                class="form-control" placeholder="Enter Response Value"
                                                                maxlength="30" @keypress="isLetterWithSpace($event)"
                                                                autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.responsevalueseven.$error">
                                                        {{ v$.responsevalueseven.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="responsestatusseven" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.responsestatusseven.$error">
                                                        {{ v$.responsestatusseven.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="eighthrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreEighthRow()"
                                                        class="btn custom-view-detail-btn"><i class="pi pi-plus me-1"
                                                            style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="eighthrowshowstatus" />
                                        <!-- Response Eight  -->
                                        <div class="custom-form-group" v-if="eighthrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="responsenumbereight"
                                                            id="responsenumbereight"
                                                            class="form-control text-center fw-bold" disabled
                                                            maxlength="1" @keypress="onlyResponseEightFormat"
                                                            autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput"
                                                            style="width: 88%;">
                                                            <input type="text" v-model="responsevalueeight"
                                                                class="form-control" placeholder="Enter Response Value"
                                                                maxlength="30" @keypress="isLetterWithSpace($event)"
                                                                autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.responsevalueeight.$error">
                                                        {{ v$.responsevalueeight.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="responsestatuseight" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.responsestatuseight.$error">
                                                        {{ v$.responsestatuseight.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="ninethrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreNinethRow()"
                                                        class="btn custom-view-detail-btn"><i class="pi pi-plus me-1"
                                                            style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="ninethrowshowstatus" />
                                        <!-- Response Nine  -->
                                        <div class="custom-form-group" v-if="ninethrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="responsenumbernine"
                                                            id="responsenumbernine"
                                                            class="form-control text-center fw-bold" disabled
                                                            maxlength="1" @keypress="onlyResponseNineFormat"
                                                            autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput"
                                                            style="width: 88%;">
                                                            <input type="text" v-model="responsevaluenine"
                                                                class="form-control" placeholder="Enter Response Value"
                                                                maxlength="30" @keypress="isLetterWithSpace($event)"
                                                                autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.responsevaluenine.$error">
                                                        {{ v$.responsevaluenine.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="responsestatusnine" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.responsestatusnine.$error">
                                                        {{ v$.responsestatusnine.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div v-if="showmodalloader">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-lg-12 col-md-12 col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" style="width: 100px;" @click="updateCallTemplateBtn()"
                            :disabled="audiogbtnloader">
                            <span v-if="!audiogbtnloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="audiogbtnloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Edit Call Template modal stat here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
export default {
    data() {
        return {
            v$: useValidate(),
            audioCampaignList: [],
            audioRecords: 0,
            loading: false,
            newtempaltemodalstatus: false,
            selected_tab: 1,
            client_info: '',
            voterstorageimgpath: '',
            viewcalltemplatedetailmodalstatus: false,
            callTemplateBody: '',
            headeraliasename: '',
            templatename: '',
            languagevalue: '',
            responsetype: '',
            headeraudio: null,
            duplicateTemplateMsg: '',
            errortuneaddfile: '',
            editCallTemplateDialog: false,
            showmodalloader: false,
            exitaddpreviewurl: null,
            languagelist: [],

            responsenumberone: 1,
            responsevalueone: '',
            responsestatusone: '',

            responsenumbertwo: 2,
            responsevaluetwo: '',
            responsestatustwo: '',

            responsenumberthree: 3,
            responsevaluethree: '',
            responsestatusthree: '',

            responsenumberfour: 4,
            responsevaluefour: '',
            responsestatusfour: '',

            responsenumberfive: 5,
            responsevaluefive: '',
            responsestatusfive: '',

            responsenumbersix: 6,
            responsevaluesix: '',
            responsestatussix: '',

            responsenumberseven: 7,
            responsevalueseven: '',
            responsestatusseven: '',

            responsenumbereight: 8,
            responsevalueeight: '',
            responsestatuseight: '',

            responsenumbernine: 9,
            responsevaluenine: '',
            responsestatusnine: '',

            switchuncheck: false,
            dynamicTrueActiveValue: 1,
            dynamicFalseInactiveValue: 0,

            secondrowshowstatus: false,
            secondrowbtnstatus: true,

            thirdrowshowstatus: false,
            thirdrowbtnstatus: false,

            fourthrowshowstatus: false,
            fourthrowbtnstatus: false,

            fifthrowshowstatus: false,
            fifthrowbtnstatus: false,

            sixthrowshowstatus: false,
            sixthrowbtnstatus: false,

            seventhrowshowstatus: false,
            seventhrowbtnstatus: false,

            eighthrowshowstatus: false,
            eighthrowbtnstatus: false,

            ninethrowshowstatus: false,
            ninethrowbtnstatus: false,
            audiogbtnloader: false,
            editheaderaudio: null,
            editrowfk: '',
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            templatename: {
                required: helpers.withMessage("Please enter template name", required),
            },
            languagevalue: {
                required: helpers.withMessage("Please select Language", required),
            },
            responsetype: {
                required: helpers.withMessage("Please select response type", required),
            },
            headeraudio: helpers.withMessage(
                "Please upload audio file",
                requiredIf(
                    this.editheaderaudio != '' && this.editheaderaudio != null
                )
            ),
            // response one
            responsevalueone: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsetype == 1
                    )
                ),
            },
            responsestatusone: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsetype == 1
                    )
                ),
            },
            // response two
            responsevaluetwo: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatustwo == true
                    )
                ),
            },
            responsestatustwo: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluetwo != ''
                    )
                ),
            },
            // response three
            responsevaluethree: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusthree == true
                    )
                ),
            },
            responsestatusthree: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluethree != ''
                    )
                ),
            },
            // response four
            responsevaluefour: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusfour == true
                    )
                ),
            },
            responsestatusfour: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluefour != ''
                    )
                ),
            },
            // response five
            responsevaluefive: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusfive == true
                    )
                ),
            },
            responsestatusfive: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluefive != ''
                    )
                ),
            },
            // response six
            responsevaluesix: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatussix == true
                    )
                ),
            },
            responsestatussix: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluesix != ''
                    )
                ),
            },
            // response seven
            responsevalueseven: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusseven == true
                    )
                ),
            },
            responsestatusseven: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevalueseven != ''
                    )
                ),
            },
            // response eight
            responsevalueeight: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatuseight == true
                    )
                ),
            },
            responsestatuseight: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevalueeight != ''
                    )
                ),
            },
            // response nine
            responsevaluenine: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusnine == true
                    )
                ),
            },
            responsestatusnine: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluenine != ''
                    )
                ),
            },
        };
    },
    mounted() {
        this.getcallcampaigntemplates();
        if (localStorage.user) {
            this.client_info = JSON.parse(localStorage.client_info);
            this.voterstorageimgpath = this.client_info.maa24;
        }
        this.getlanguages();
    },

    methods: {
        selectedtab(e) {
            this.selected_tab = e;
        },
        newtemplateModalOpen() {
            this.$store.dispatch("addCampaignStatus");
            // this.newtempaltemodalstatus = true;
            this.selected_tab = 6;
            this.getwhatsappcampaigntemplateList();
            this.getcallcampaigntemplateList();
            this.getsmscampaigntemplates();
        },
        newtemplateModalClose() {
            this.$store.dispatch("addCampaignStatus");
            // this.newtempaltemodalstatus = false;
        },
        getcallcampaigntemplates(item) {
            this.loading = true;
            this.ApiService.getcallcampaigntemplates(item).then((data) => {
                if (data.success === true) {
                    this.audioCampaignList = data.data;
                    this.audioRecords = data.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.audioCampaignList = [];
                    this.audioRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getcallcampaigntemplates({ page_no: this.page_no });
        },
        redirectIntelligentCampaignApi() {
            this.$router.push("/campaigntemplate/intelligent");
        },
        redirectWhatsAppCampaignApi() {
            this.$router.push("/campaigntemplate/whatsapp");
        }, redirectiMessageCampaignApi() {
            this.$router.push("/campaigntemplate/rcsimessage");
        }, redirectSMSCampaignApi() {
            this.$router.push("/campaigntemplate/sms");
        }, redirectEmailCampaignApi() {
            this.$router.push("/campaigntemplate/email");
        }, redirectNotificationCampaignApi() {
            this.$router.push("/campaigntemplate/pushnotification");
        },

        getwhatsappcampaigntemplateList() {
            this.ApiService.getwhatsappcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("whatsapp_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("whatsapp_templates", []);
                }
            });
        },
        getcallcampaigntemplateList() {
            this.ApiService.getcallcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("call_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("call_templates", []);
                }
            });
        },
        getsmscampaigntemplates() {
            this.ApiService.getsmscampaigntemplates().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("sms_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("sms_templates", []);
                }
            });
        },
        viewCallDetailModalOpen(e) {
            this.viewcalltemplatedetailmodalstatus = true;
            this.callTemplateBody = e;
            this.headeraliasename = e.cl2;
        },
        viewCallDetailModalClose() {
            this.viewcalltemplatedetailmodalstatus = false;
            this.callTemplateBody = '';
            this.headeraliasename = '';
        },
        editCallTemplateModalOpen(data) {

            this.editCallTemplateDialog = true;

            this.showmodalloader = true;
            this.editrowfk = '';
            this.templatename = '';
            this.languagevalue = '';
            this.responsetype = '';
            this.headeraudio = null;
            this.editheaderaudio = null;
            this.duplicateTemplateMsg = '';
            this.errortuneaddfile = '';

            this.exitaddpreviewurl = null;
            this.responsenumberone = 1;
            this.responsevalueone = '';
            this.responsestatusone = '';
            this.responsenumbertwo = 2;
            this.responsevaluetwo = '';
            this.responsestatustwo = '';

            this.responsenumberthree = 3;
            this.responsevaluethree = '';
            this.responsestatusthree = '';

            this.responsenumberfour = 4;
            this.responsevaluefour = '';
            this.responsestatusfour = '';

            this.responsenumberfive = 5;
            this.responsevaluefive = '';
            this.responsestatusfive = '';

            this.responsenumbersix = 6;
            this.responsevaluesix = '';
            this.responsestatussix = '';

            this.responsenumberseven = 7;
            this.responsevalueseven = '';
            this.responsestatusseven = '';

            this.responsenumbereight = 8;
            this.responsevalueeight = '';
            this.responsestatuseight = '';

            this.responsenumbernine = 9;
            this.responsevaluenine = '';
            this.responsestatusnine = '';

            this.switchuncheck = false;
            this.dynamicTrueActiveValue = 1;
            this.dynamicFalseInactiveValue = 0;

            this.secondrowshowstatus = false;
            this.secondrowbtnstatus = true;

            this.thirdrowshowstatus = false;
            this.thirdrowbtnstatus = false;

            this.fourthrowshowstatus = false;
            this.fourthrowbtnstatus = false;

            this.fifthrowshowstatus = false;
            this.fifthrowbtnstatus = false;

            this.sixthrowshowstatus = false;
            this.sixthrowbtnstatus = false;

            this.seventhrowshowstatus = false;
            this.seventhrowbtnstatus = false;

            this.eighthrowshowstatus = false;
            this.eighthrowbtnstatus = false;

            this.ninethrowshowstatus = false;
            this.ninethrowbtnstatus = false;
            this.editrowfk = data.cl1;
            this.templatename = data.cl2;
            this.responsetype = data.cl8;
            if (data.cl8 == 1) {
                this.responsenumberone = data.cl9;
                this.responsevalueone = data.cl10;
                if (data.cl11 == 1) {
                    this.responsestatusone = true;
                } else {
                    this.responsestatusone = false;
                }
                if (data.cl13) {
                    this.responsenumbertwo = data.cl12;
                    this.responsevaluetwo = data.cl13;
                    if (data.cl14 == 1) {
                        this.responsestatustwo = true;
                    } else {
                        this.responsestatustwo = false;
                    }
                    this.addMoreSecondRow();
                }
                if (data.cl16) {
                    this.responsenumberthree = data.cl15;
                    this.responsevaluethree = data.cl16;
                    if (data.cl17 == 1) {
                        this.responsestatusthree = true;
                    } else {
                        this.responsestatusthree = false;
                    }
                    this.addMoreThirdRow();
                }
                if (data.cl19) {
                    this.responsenumberfour = data.cl18;
                    this.responsevaluefour = data.cl19;
                    if (data.cl20 == 1) {
                        this.responsestatusfour = true;
                    } else {
                        this.responsestatusfour = false;
                    }
                    this.addMoreFourthRow();
                }
                if (data.cl22) {
                    this.responsenumberfive = data.cl21;
                    this.responsevaluefive = data.cl22;
                    if (data.cl23 == 1) {
                        this.responsestatusfive = true;
                    } else {
                        this.responsestatusfive = false;
                    }
                    this.addMoreFifthRow();
                }
                if (data.cl25) {
                    this.responsenumbersix = data.cl24;
                    this.responsevaluesix = data.cl25;
                    if (data.cl26 == 1) {
                        this.responsestatussix = true;
                    } else {
                        this.responsestatussix = false;
                    }
                    this.addMoreSixthRow();
                }
                if (data.cl28) {
                    this.responsenumberseven = data.cl27;
                    this.responsevalueseven = data.cl28;
                    if (data.cl29 == 1) {
                        this.responsestatusseven = true;
                    } else {
                        this.responsestatusseven = false;
                    }
                    this.addMoreSeventhRow();
                }
                if (data.cl31) {
                    this.responsenumbereight = data.cl30;
                    this.responsevalueeight = data.cl31;
                    if (data.cl32 == 1) {
                        this.responsestatuseight = true;
                    } else {
                        this.responsestatuseight = false;
                    }
                    this.addMoreEighthRow();
                }
                if (data.cl34) {
                    this.responsenumbernine = data.cl33;
                    this.responsevaluenine = data.cl34;
                    if (data.cl35 == 1) {
                        this.responsestatusnine = true;
                    } else {
                        this.responsestatusnine = false;
                    }
                    this.addMoreNinethRow();
                }
            }
            if (data.cl3 != null) {
                this.editheaderaudio = data.cl3;
            } else {
                this.editheaderaudio = null;
            }
            if (data.cl7 != null) {
                let datafk = data.cl7;
                let vals = this.languagelist.filter(function (item) {
                    return item.value == datafk;
                });
                this.languagevalue = { value: vals[0].value, label: vals[0].label };
            }
            this.showmodalloader = false;
        },
        editCallTemplateModalClose() {
            this.editCallTemplateDialog = false;
        },
        updateCallTemplateBtn() {
            this.v$.$validate();
            var formData = new FormData();
            formData.append("cl1", this.editrowfk);
            formData.append("cl2", this.templatename);
            if (this.languagevalue) {
                formData.append('cl7', this.languagevalue.value);
            }
            formData.append("cl8", this.responsetype);
            if (this.headeraudio != null) {
                formData.append("media_file", this.headeraudio);
            }
            if (this.responsetype == 1) {
                formData.append("cl9", this.responsenumberone);
                formData.append("cl10", this.responsevalueone);
                if (this.responsestatusone == true) {
                    formData.append("cl11", 1);
                } else {
                    formData.append("cl11", 0);
                }
                if (this.responsevaluetwo) {
                    formData.append("cl12", this.responsenumbertwo);
                    formData.append("cl13", this.responsevaluetwo);
                    if (this.responsestatustwo == true) {
                        formData.append("cl14", 1);
                    } else {
                        formData.append("cl14", 0);
                    }
                }
                if (this.responsevaluethree) {
                    formData.append("cl15", this.responsenumberthree);
                    formData.append("cl16", this.responsevaluethree);
                    if (this.responsestatusthree == true) {
                        formData.append("cl17", 1);
                    } else {
                        formData.append("cl17", 0);
                    }
                }
                if (this.responsevaluefour) {
                    formData.append("cl18", this.responsenumberfour);
                    formData.append("cl19", this.responsevaluefour);
                    if (this.responsestatusfour == true) {
                        formData.append("cl20", 1);
                    } else {
                        formData.append("cl20", 0);
                    }
                }
                if (this.responsevaluefive) {
                    formData.append("cl21", this.responsenumberfive);
                    formData.append("cl22", this.responsevaluefive);
                    if (this.responsestatusfive == true) {
                        formData.append("cl23", 1);
                    } else {
                        formData.append("cl23", 0);
                    }
                }
                if (this.responsevaluesix) {
                    formData.append("cl24", this.responsenumbersix);
                    formData.append("cl25", this.responsevaluesix);
                    if (this.responsestatussix == true) {
                        formData.append("cl26", 1);
                    } else {
                        formData.append("cl26", 0);
                    }
                }
                if (this.responsevalueseven) {
                    formData.append("cl27", this.responsenumberseven);
                    formData.append("cl28", this.responsevalueseven);
                    if (this.responsestatusseven == true) {
                        formData.append("cl29", 1);
                    } else {
                        formData.append("cl29", 0);
                    }
                }
                if (this.responsevalueeight) {
                    formData.append("cl30", this.responsenumbereight);
                    formData.append("cl31", this.responsevalueeight);
                    if (this.responsestatuseight == true) {
                        formData.append("cl32", 1);
                    } else {
                        formData.append("cl32", 0);
                    }
                }
                if (this.responsevaluenine) {
                    formData.append("cl33", this.responsenumbernine);
                    formData.append("cl34", this.responsevaluenine);
                    if (this.responsestatusnine == true) {
                        formData.append("cl35", 1);
                    } else {
                        formData.append("cl35", 0);
                    }
                }

            }
            if (!this.v$.$error && !this.errortuneaddfile && !this.duplicateTemplateMsg) {
                this.audiogbtnloader = true;
                this.ApiService.addCallCampaignTemplate(formData).then((data) => {
                    if (data.success == true) {
                        this.audiogbtnloader = false;
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.editCallTemplateDialog = false;
                        this.getcallcampaigntemplates();
                    } else {
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.audiogbtnloader = false;
                        this.editCallTemplateDialog = true;
                    }
                });
            } else {
                this.$error;
            }
        },
        handlerAudioUploader() {
            var file = this.$refs.headeraudioref.files[0];
            let allowedExtensions = /(\.mp3)$/i;
            // if (file.size > 2000000) {
            //     this.errortuneaddfile = 'File size cannot exceed more than 2MB';
            // } else
            if (!file.name.match(allowedExtensions)) {
                this.errortuneaddfile = 'Invalid file type';
            } else {
                this.errortuneaddfile = '';
                this.headeraudio = this.$refs.headeraudioref.files[0];
                this.exitaddpreviewurl = this.headeraudio;
            }
        },

        checkTemplateName() {
            if ((this.templatename).length > 3 && localStorage.getItem('sms_templates') !== null) {
                var templates = JSON.parse(localStorage.getItem('sms_templates'));
                for (var i = 0, len = templates.length; i < len; i++) {
                    if ((templates[i]['cl2']).toLowerCase() === (this.templatename).toLowerCase()) {
                        this.duplicateTemplateMsg = 'Template name already exits.';
                        break;
                    } else {
                        this.duplicateTemplateMsg = '';
                    }
                }
            }
        },
        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        onlyResponseOneFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberone = document.getElementById("responsenumberone");
            if ((responsenumberone.value == '' || responsenumberone.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        onlyResponseTwoFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbertwo = document.getElementById("responsenumbertwo");
            if ((responsenumbertwo.value == '' || responsenumbertwo.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },


        onlyResponseThreeFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberthree = document.getElementById("responsenumberthree");
            if ((responsenumberthree.value == '' || responsenumberthree.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseFourFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberfour = document.getElementById("responsenumberfour");
            if ((responsenumberfour.value == '' || responsenumberfour.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseFiveFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberfive = document.getElementById("responsenumberfive");
            if ((responsenumberfive.value == '' || responsenumberfive.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseSixFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbersix = document.getElementById("responsenumbersix");
            if ((responsenumbersix.value == '' || responsenumbersix.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseSevenFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberseven = document.getElementById("responsenumberseven");
            if ((responsenumberseven.value == '' || responsenumberseven.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseEightFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbereight = document.getElementById("responsenumbereight");
            if ((responsenumbereight.value == '' || responsenumbereight.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseNineFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbernine = document.getElementById("responsenumbernine");
            if ((responsenumbernine.value == '' || responsenumbernine.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        clearresponse() {
            this.responsevalueone = '';
            this.responsestatusone = '';

            this.responsevaluetwo = '';
            this.responsestatustwo = '';

            this.responsevaluethree = '';
            this.responsestatusthree = '';

            this.responsevaluefour = '';
            this.responsestatusfour = '';

            this.responsevaluefive = '';
            this.responsestatusfive = '';

            this.responsevaluesix = '';
            this.responsestatussix = '';

            this.responsevalueseven = '';
            this.responsestatusseven = '';

            this.responsevalueeight = '';
            this.responsestatuseight = '';

            this.responsevaluenine = '';
            this.responsestatusnine = '';
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        changeStatusOne(e) {
            if (e == true) {
                this.responsestatusone = 1;
            } else if (e == false) {
                this.responsestatusone = 0;
            }
        },
        addMoreSecondRow() {
            this.secondrowshowstatus = true;
            this.secondrowbtnstatus = false;
            this.thirdrowbtnstatus = true;
        },
        addMoreThirdRow() {
            this.thirdrowshowstatus = true;
            this.thirdrowbtnstatus = false;
            this.fourthrowbtnstatus = true;
        },
        addMoreFourthRow() {
            this.fourthrowshowstatus = true;
            this.fourthrowbtnstatus = false;
            this.fifthrowbtnstatus = true;
        },

        addMoreFifthRow() {
            this.fifthrowshowstatus = true;
            this.fifthrowbtnstatus = false;
            this.sixthrowbtnstatus = true;
        },

        addMoreSixthRow() {
            this.sixthrowshowstatus = true;
            this.sixthrowbtnstatus = false;
            this.seventhrowbtnstatus = true;
        },

        addMoreSeventhRow() {
            this.seventhrowshowstatus = true;
            this.seventhrowbtnstatus = false;
            this.eighthrowbtnstatus = true;
        },

        addMoreEighthRow() {
            this.eighthrowshowstatus = true;
            this.eighthrowbtnstatus = false;
            this.ninethrowbtnstatus = true;
        },

        addMoreNinethRow() {
            this.ninethrowshowstatus = true;
            this.ninethrowbtnstatus = false;
        },
        getlanguages(e) {
            this.ApiService.getlanguages(e).then((data) => {
                if (data.status == 200) {
                    this.languagelist = data.data;
                } else {
                    this.languagelist = [];
                }
            });
        },
    },
};

</script>
<style scoped>
.custom-start-menu-col {
    width: 21%;
}

.custom-end-body-col {
    width: 79%;
    padding-left: 0;
}

.custom-vertical-modal-tab {
    width: 213px;
    min-width: 213px;
    height: 460px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 16px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 10px;
    font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

.modal-body-fixed-height {
    min-height: 422px;
}

.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}
</style>