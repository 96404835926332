<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Add Campaign Templates</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
            <button v-if="selected_resp_tab == 1" type="button" @click="newtemplateModalOpen()"
                class="header-btn-outer btn btn-primary mr-2">
                Add Campaign Template
            </button>
            <button v-if="selected_resp_tab == 2" type="button" class="btn custom-view-detail-btn ms-2"
                @click="addResponseTemplateModalOpen()">
                Add Response Template
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4 " id="intelligent-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#intelligentcampaigndetail" type="button" role="tab" aria-controls="vehicle-details"
                aria-selected="false" @click="redirectIntelligentCampaignApi()">
                Intelligent
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="imessage-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#imessagecampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectiMessageCampaignApi()">
                RCS / iMessage
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="sms-campaign-tab" data-bs-toggle="tab" data-bs-target="#smscampaigndetail"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="redirectSMSCampaignApi()">
                SMS
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="email-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#emailcampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectEmailCampaignApi()">
                Email
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="audio-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#audiocampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectAudioCallCampaignApi()">
                Audio Call
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="notification-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#notificationcampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectNotificationCampaignApi()">
                Push Notification
            </button>
        </li>
    </ul>
    <div class="tab-content mt-2" id="myTabContent">
        <div class="tab-pane fade " id="intelligentcampaigndetail" role="tabpanel"
            aria-labelledby="intelligent-campaign-tab">
        </div>
        <div class="tab-pane fade show active" id="whatsappcampaigndetail" role="tabpanel"
            aria-labelledby="whatsapp-campaign-tab">
            <ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
                <li class="nav-item w-50" role="presentation">
                    <button class="nav-link custom-navbar-navlink active w-100 m-0" id="whatsapp-campaign-details-tab"
                        data-bs-toggle="tab" data-bs-target="#campaignlist" type="button" role="tab"
                        aria-controls="vehicle-details" aria-selected="true" @click="selectedResponseTab((e = 1))">
                        Campaign Template
                    </button>
                </li>
                <li class="nav-item w-50" role="presentation">
                    <button class="nav-link custom-navbar-navlink w-100 m-0" id="whatsapp-response-tab"
                        data-bs-toggle="tab" data-bs-target="#responsetemplate" type="button" role="tab"
                        aria-controls="customer-details" aria-selected="false" @click="selectedResponseTab((e = 2))">
                        Response Template
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active p-3" id="campaignlist" role="tabpanel"
                    aria-labelledby="whatsapp-campaign-details-tab">
                    <div class="custom-ultima-datatable" style="height: calc(100vh - 264px)">
                        <DataTable :value="whatsAppCampaignList" :scrollable="true" scrollHeight="flex"
                            columnResizeMode="fit" :paginator="true" :lazy="true" :rowHover="true"
                            :totalRecords="whatsAppRecords" :rows="30" dataKey="cg1" @page="changePageCamp($event)"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            responsiveLayout="scroll"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :loading="loading">
                            <template v-if="!loading" #empty>No records found.</template>
                            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                            <Column field="Name" header="Template Name" headerStyle="width: 25%" bodyStyle="width: 25%">
                                <template #body="{ data }">
                                    <div>
                                        <div class="fw-bold" title="Alias Name">{{ data.cg2 ? data.cg2 : "N/A" }}</div>
                                        <div title="Template Name">{{ data.cg3 ? data.cg3 : "N/A" }}
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="message" header="Template Message" headerStyle="width: 55%"
                                bodyStyle="width: 55%">
                                <template #body="{ data }">
                                    <div>
                                        <div v-if="data.cg5"><span v-html="replacebrake(data.cg5)"></span></div>
                                        <div v-else>N/A</div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="lanuage" header="Language" headerStyle="width: 10%" bodyStyle="width: 10%">
                                <template #body="{ data }">
                                    <div>{{ data.cg13 ? data.cg13 : "N/A" }}</div>
                                </template>
                            </Column>
                            <Column field="Status" header="Action" class="justify-content-end" headerStyle="width: 10%"
                                bodyStyle="width: 10%">
                                <template #body="{ data }">
                                    <button type="button" title="View Details" class="btn custom-outline-view-btn"
                                        @click="
                campaginTemplateDetailModalOpen(data)
                ">
                                        <i class="pi pi-eye call-btn-color"></i> View
                                    </button>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div class="tab-pane fade p-3" id="responsetemplate" role="tabpanel"
                    aria-labelledby="whatsapp-response-tab">
                    <div class="custom-ultima-datatable" style="height: calc(100vh - 264px)">
                        <DataTable :value="whatsAppResponseTemplateList" :scrollable="true" scrollHeight="flex"
                            columnResizeMode="fit" :paginator="true" :lazy="true" :rowHover="true"
                            :totalRecords="responseRecords" :rows="30" dataKey="cg1" @page="changePageResp($event)"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            responsiveLayout="scroll"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :loading="loading">
                            <template v-if="!loading" #empty>No records found.</template>
                            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                            <Column field="Name" header="Template Name" headerStyle="width: 25%" bodyStyle="width: 25%">
                                <template #body="{ data }">
                                    <div>
                                        <div class="fw-bold" title="Template Name">{{ data.cg3 ? data.cg3 : "N/A" }}
                                        </div>
                                        <div title="Alias Name">{{ data.cg2 ? data.cg2 : "N/A" }}</div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="message" header="Template Message" headerStyle="width: 60%"
                                bodyStyle="width: 60%">
                                <template #body="{ data }">
                                    <div>
                                        <div v-if="data.cg5"><span v-html="replacebrake(data.cg5)"></span></div>
                                        <div v-else>N/A</div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="lanuage" header="Language" headerStyle="width: 15%" bodyStyle="width: 15%">
                                <template #body="{ data }">
                                    <div>{{ data.cg13 ? data.cg13 : "N/A" }}</div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="imessagecampaigndetail" role="tabpanel" aria-labelledby="imessage-campaign-tab">
        </div>
        <div class="tab-pane fade" id="smscampaigndetail" role="tabpanel" aria-labelledby="sms-campaign-tab">
        </div>
        <div class="tab-pane fade" id="emailcampaigndetail" role="tabpanel" aria-labelledby="email-campaign-tab">
        </div>
        <div class="tab-pane fade" id="audiocampaigndetail" role="tabpanel" aria-labelledby="audio-campaign-tab">
        </div>
        <div class="tab-pane fade" id="notificationcampaigndetail" role="tabpanel"
            aria-labelledby="notification-campaign-tab">

        </div>
    </div>
    <!-- Add Template modal start here -->
    <div class="modal-mask" v-if="$store.state.newtempaltemodalstatus">
        <div class="modal-dialog modal-xl modal-dialog-centered
            custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Campaign Template</h5>
                    <button type="button" class="btn-close" @click="newtemplateModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-12 custom-start-menu-col">
                            <ul class="nav nav-tabs custom-vertical-modal-tab flex-column" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link " id="intelligent-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#intelligentcampaigntarget" type="button" role="tab"
                                        aria-controls="intelligent-campaign" aria-selected="false"
                                        @click="selectedtab((e = 1))">
                                        Intelligent Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="imessage-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#imessagecampaigntarget" type="button" role="tab"
                                        aria-controls="imessage-campaign" aria-selected="false"
                                        @click="selectedtab((e = 3))">
                                        RCS / iMessage Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="sms-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#smscampaigntarget" type="button" role="tab"
                                        aria-controls="sms-campaign" aria-selected="false"
                                        @click="selectedtab((e = 4))">
                                        SMS Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="email-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#emailcampaigntarget" type="button" role="tab"
                                        aria-controls="email-campaign" aria-selected="false"
                                        @click="selectedtab((e = 5))">
                                        Email Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="audio-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#audiocampaigntarget" type="button" role="tab"
                                        aria-controls="audio-campaign" aria-selected="false"
                                        @click="selectedtab((e = 6))">
                                        Audio Call Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="notification-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#notificationcampaigntarget" type="button" role="tab"
                                        aria-controls="notification-campaign" aria-selected="false"
                                        @click="selectedtab((e = 7))">
                                        Push Notification Campaign
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-9 col-md-9 col-12 custom-end-body-col">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade" id="intelligentcampaigntarget" role="tabpanel"
                                    aria-labelledby="intelligent-campaign-tab">
                                    <div v-if="selected_tab == 1">
                                        <IntelligentCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane show active" id="whatsappcampaigntarget" role="tabpanel"
                                    aria-labelledby="whatsapp-campaign-tab">
                                    <div v-if="selected_tab == 2">
                                        <WhatsAppCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="imessagecampaigntarget" role="tabpanel"
                                    aria-labelledby="imessage-campaign-tab">
                                    <div v-if="selected_tab == 3">
                                        <iMessageCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="smscampaigntarget" role="tabpanel"
                                    aria-labelledby="sms-campaign-tab">
                                    <div v-if="selected_tab == 4">
                                        <SMSCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="emailcampaigntarget" role="tabpanel"
                                    aria-labelledby="email-campaign-tab">
                                    <div v-if="selected_tab == 5">
                                        <EmailCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="audiocampaigntarget" role="tabpanel"
                                    aria-labelledby="audio-campaign-tab">
                                    <div v-if="selected_tab == 6">
                                        <AudioCallCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="notificationcampaigntarget" role="tabpanel"
                                    aria-labelledby="notification-campaign-tab">
                                    <div v-if="selected_tab == 7">
                                        <PushNotificationCampaignTab />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Template modal end here -->
    <!-- Add Whatapp Response template modal Start Here -->
    <div class="modal-mask" v-if="addresponsetemplateDialog">
        <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Response Template</h5>
                    <button type="button" class="btn-close" @click="addResponseTemplateModalClose()"></button>
                </div>
                <div class="modal-body modal-body-scroll-outer">
                    <div class="campaign-tab-outer-scroll" :style="{ height: '409px' }">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="custom-form-group">
                                    <label class="form-label">Response Template Name <span
                                            class="text-danger">*</span></label>
                                    <input type="text" v-model="templatename" class="form-control"
                                        placeholder="Enter Template Name" maxlength="512" autocomplete="off"
                                        v-on:keyup="checkTemplateName()" />
                                    <div class="custom-error" v-if="v$.templatename.$error">
                                        {{ v$.templatename.$errors[0].$message }}
                                    </div>
                                    <div class="custom-error" v-if="duplicateTemplateMsg">
                                        {{ duplicateTemplateMsg }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="custom-form-group">
                                    <label class="form-label">Alias Name <span class="text-danger">*</span></label>
                                    <input type="text" v-model="aliasname" class="form-control"
                                        placeholder="Enter Alias Name" maxlength="250" autocomplete="off" />
                                    <div class="custom-error" v-if="v$.aliasname.$error">
                                        {{ v$.aliasname.$errors[0].$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="custom-form-group">
                                    <label class="form-label">Choose Category<span class="text-danger">*</span></label>
                                    <Multiselect v-model="categorytype" :options="categorytypelist" :searchable="true"
                                        label="label" placeholder="Select Category"
                                        class="multiselect-custom text-capitalize" :canClear="true"
                                        :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                                    <div class="custom-error" v-if="v$.categorytype.$error">
                                        {{ v$.categorytype.$errors[0].$message }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="custom-form-group">
                                    <label class="form-label">Choose Language<span class="text-danger">*</span></label>
                                    <Multiselect v-model="languagevalue" :options="languagelist" :searchable="true"
                                        label="label" placeholder="Select Language"
                                        class="multiselect-custom text-capitalize" :canClear="true"
                                        :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                                    <div class="custom-error" v-if="v$.languagevalue.$error">
                                        {{ v$.languagevalue.$errors[0].$message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="languagevalue != ''">
                            <div class="col-lg-8 col-md-8 col-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="card-template">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Vendor Template ID<span
                                                                class="text-danger">*</span></label>
                                                        <input type="text" v-model="vendortemplateid"
                                                            class="form-control" placeholder="Enter Vendor Template ID"
                                                            @keypress="onlyNumber"  autocomplete="off" />
                                                        <div class="custom-error" v-if="v$.vendortemplateid.$error">
                                                            {{ v$.vendortemplateid.$errors[0].$message }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-template mt-3">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Header<span
                                                                class="text-danger">*</span></label>
                                                        <div class="custom-group-radio-box-btn">
                                                            <input type="radio" class="btn-check" @click="clearheader()"
                                                                v-model="headertype" name="none-option" id="headernone"
                                                                autocomplete="off" value="0" />
                                                            <label class="btn custom-radio-box-btn text-capitalize"
                                                                for="headernone">None</label>
                                                            <input type="radio" class="btn-check" @click="clearheader()"
                                                                v-model="headertype" name="text-option" id="headermedia"
                                                                autocomplete="off" value="1" />
                                                            <label class="btn custom-radio-box-btn text-capitalize"
                                                                for="headermedia">Media</label>
                                                        </div>
                                                        <div class="custom-error" v-if="v$.headertype.$error">
                                                            {{ v$.headertype.$errors[0].$message }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12" v-if="headertype == 1">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Select Media Type<span
                                                                class="text-danger">*</span></label>
                                                        <Multiselect v-model="mediatype" @change="mediatypechanges()"
                                                            :options="mediatypelist" :searchable="true" label="label"
                                                            placeholder="Select Media"
                                                            class="multiselect-custom text-capitalize" :canClear="true"
                                                            :closeOnSelect="true" noOptionsText="No Result Found"
                                                            :object="true" />
                                                        <div class="custom-error" v-if="v$.mediatype.$error">
                                                            {{ v$.mediatype.$errors[0].$message }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12"
                                                    v-if="headertype == 1 && mediatype.value == 1">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Upload Image <span
                                                                class="text-danger">*</span></label>
                                                        <FileUpload mode="basic" accept="image/*" :auto="true"
                                                            ref="whtheaderimage" class="custom-upload-btn-ultima"
                                                            v-model="whtheaderimage"
                                                            v-on:change="handleWhtImageUploader()" />
                                                        <div class="custom-error" v-if="v$.whtheaderimage.$error">
                                                            {{ v$.whtheaderimage.$errors[0].$message }}
                                                        </div>
                                                        <div class="custom-error" v-if="errorwhtheaderimage">{{
                errorwhtheaderimage }}</div>
                                                        <div class="text-primary mt-1"
                                                            v-if="exitwhatheaderpreviewimgurl != null">{{
                exitwhatheaderpreviewimgurl.name }}</div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-lg-4 col-md-4 col-12" v-if="exitwhatheaderpreviewimgurl != null">
                                                            <img :src="exitwhatheaderpreviewimgurl.objectURL" height="72" class="
                                                        rounded
                                                        bg-light
                                                        me-auto
                                                        d-block
                                                        " alt="" />
                                                        </div> -->
                                                <div class="col-lg-6 col-md-6 col-12"
                                                    v-if="headertype == 1 && mediatype.value == 2">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Upload Video<span
                                                                class="text-danger">*</span></label>
                                                        <FileUpload mode="basic" :auto="true" ref="whtheadervideo"
                                                            class="custom-upload-btn-ultima" v-model="whtheadervideo"
                                                            v-on:change="handleWhtVideoUploader()" />
                                                        <div class="custom-error" v-if="v$.whtheadervideo.$error">
                                                            {{ v$.whtheadervideo.$errors[0].$message }}
                                                        </div>
                                                        <div class="custom-error" v-if="errorwhtheadervideo">{{
                errorwhtheadervideo }}</div>
                                                        <div class="text-primary mt-1"
                                                            v-if="exitwhatheaderpreviewvideourl != null">{{
                exitwhatheaderpreviewvideourl.name }}</div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-6 col-md-6 col-12"
                                                    v-if="headertype == 1 && mediatype.value == 3">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Upload Document<span
                                                                class="text-danger">*</span></label>
                                                        <FileUpload mode="basic" :auto="true" ref="whtheaderdoc"
                                                            class="custom-upload-btn-ultima" v-model="whtheaderdoc"
                                                            v-on:change="handleWhtDocUploader()" />
                                                        <div class="custom-error" v-if="v$.whtheaderdoc.$error">
                                                            {{ v$.whtheaderdoc.$errors[0].$message }}
                                                        </div>
                                                        <div class="custom-error" v-if="errorwhtheaderdoc">{{
                errorwhtheaderdoc }}</div>
                                                        <div class="text-primary mt-1"
                                                            v-if="exitwhatheaderpreviewdovurl != null">{{
                exitwhatheaderpreviewdovurl.name }}</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-template mt-3">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <div class="custom-form-group">
                                                        <label for="formmessageInput" class="form-label">Message <span
                                                                class="text-danger">*</span></label>
                                                        <textarea type="text" id="formmessageInput"
                                                            v-model="whatsappmessage"
                                                            class="form-control custom-text-area-scroll"
                                                            placeholder="Enter Message" v-on:keyup="htmlconvertmsg()"
                                                            autocomplete="off" rows="3" maxlength="1024"
                                                            :style="{ resize: 'vertical' }"></textarea>
                                                        <div class="custom-error" v-if="v$.whatsappmessage.$error">
                                                            {{ v$.whatsappmessage.$errors[0].$message }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-template mt-3">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Footer</label>
                                                        <input type="text" v-model="footername" class="form-control"
                                                            placeholder="Enter Footer" maxlength="60"
                                                            autocomplete="off" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                <div class="custom-form-group text-center">
                                                    <button type="button" class="btn modal-bulk-next-btn"
                                                        @click="whatsappResponseSubmitBtn()" :disabled="whtbtnloader">
                                                        <span v-if="!whtbtnloader">Submit</span>
                                                        <div class="spinner-border text-light custom-spinner-loader-btn"
                                                            role="status" v-if="whtbtnloader">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="intelligent-campagin-modal-header mb-2 text-center">Message Preview
                                        </div>
                                    </div>
                                </div>
                                <div class="preview-card-template">
                                    <div v-if="headertype == 1 && mediatype.value != ''">
                                        <img v-if="whtheaderimage != null" :src="exitwhatheaderpreviewimgurl.objectURL"
                                            :style="{ width: '100%' }" alt="" />
                                        <img v-if="mediatype.value == 1 && whtheaderimage == null"
                                            src="/assets/images/preview-img.jpg" alt="" :style="{ width: '100%' }" />
                                        <video id="video-preview" controls :style="{ width: '245px' }"
                                            v-show="this.whtheadervideo != null" />
                                        <img v-if="mediatype.value == 2 && this.whtheadervideo == null"
                                            src="/assets/images/preview-clip.jpg" alt="" :style="{ width: '100%' }" />
                                        <img v-if="mediatype.value == 3" src="/assets/images/preview-doc.jpg" alt=""
                                            :style="{ width: '100%' }" />
                                    </div>
                                    <div class="preview-body-msg" v-if="whatsappmessage != ''">
                                        <p v-html="this.whatsappmessageinput"></p>
                                    </div>
                                    <div class="preview-footer-msg" v-if="footername">
                                        {{ this.footername }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Whatapp Response template modal End Here -->
    <!-- filter start here -->
    <div class="modal-mask" v-if="campaigndetailviewstatus">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-fullscreen ms-auto custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">View Details</h5>
                    <button type="button" class="btn-close" @click="campaginTemplateDetailModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-12">
                            <div class="company-box-outer">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="customer-label-group">
                                            <label class="form-label">Template Alias Name</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ campaigndetails.cg2 ? campaigndetails.cg2 : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="customer-label-group">
                                            <label class="form-label">Template Name</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ campaigndetails.cg3 ? campaigndetails.cg3 : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="customer-label-group">
                                            <label class="form-label">Vendor Template Id</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ campaigndetails.cg4 ? campaigndetails.cg4 : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="customer-label-group">
                                            <label class="form-label">Language</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ campaigndetails.cg13 ? campaigndetails.cg13 : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12" v-if="campaigndetails.cg14">
                                        <div class="customer-label-group">
                                            <label class="form-label">Variables</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ campaigndetails.cg14 ? campaigndetails.cg14 : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12" v-if="campaigndetails.cg20">
                                        <div class="customer-label-group">
                                            <label class="form-label">Button Type</label>
                                            <div class="from-label-value text-capitalize">
                                                <span v-if="campaigndetails.cg20 == 1">Quick Reply</span>
                                                <span v-if="campaigndetails.cg20 == 2">Call To Action</span>
                                                <span v-else>N/A</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="campaigndetails.cg20 == 1">
                                    <div class="col-lg-12 col-md-12 col-12" v-if="campaigndetails.cg29">
                                        <div class="customer-label-group">
                                            <label class="form-label">Action Type</label>
                                            <div class="from-label-value text-capitalize">
                                                <span v-if="campaigndetails.cg29 == 1">Call Next Template</span>
                                                <span v-if="campaigndetails.cg29 == 2">Activate Chatbot</span>
                                                <span v-else>N/A</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12" v-if="campaigndetails.cg7">
                                        <div class="customer-label-group">
                                            <label class="form-label">Response</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ campaigndetails.cg7 ? campaigndetails.cg7 : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider class="mb-3" v-if="campaigndetails.cg30" />
                                    <div class="col-lg-12 col-md-12 col-12" v-if="campaigndetails.cg30">
                                        <div class="customer-label-group">
                                            <label class="form-label">Action Type</label>
                                            <div class="from-label-value text-capitalize">
                                                <span v-if="campaigndetails.cg30 == 1">Call Next Template</span>
                                                <span v-if="campaigndetails.cg30 == 2">Activate Chatbot</span>
                                                <span v-else>N/A</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12" v-if="campaigndetails.cg9">
                                        <div class="customer-label-group">
                                            <label class="form-label">Response</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ campaigndetails.cg9 ? campaigndetails.cg9 : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider class="mb-3" v-if="campaigndetails.cg31" />
                                    <div class="col-lg-12 col-md-12 col-12" v-if="campaigndetails.cg31">
                                        <div class="customer-label-group">
                                            <label class="form-label">Action Type</label>
                                            <div class="from-label-value text-capitalize">
                                                <span v-if="campaigndetails.cg31 == 1">Call Next Template</span>
                                                <span v-if="campaigndetails.cg31 == 2">Activate Chatbot</span>
                                                <span v-else>N/A</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12" v-if="campaigndetails.cg11">
                                        <div class="customer-label-group">
                                            <label class="form-label">Response</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ campaigndetails.cg11 ? campaigndetails.cg11 : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="campaigndetails.cg20 == 2">
                                    <div class="col-lg-12 col-md-12 col-12" v-if="campaigndetails.cg25">
                                        <div class="customer-label-group">
                                            <label class="form-label">Call Button Response</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ campaigndetails.cg25 ? campaigndetails.cg25 : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12" v-if="campaigndetails.cg27">
                                        <div class="customer-label-group">
                                            <label class="form-label">URL Button Response</label>
                                            <div class="from-label-value">
                                                {{ campaigndetails.cg27 ? campaigndetails.cg27 : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="intelligent-campagin-modal-header mb-2 text-center">Message
                                        Preview
                                    </div>
                                </div>
                            </div>
                            <div class="preview-card-template">
                                <div v-if="campaigndetails.cg15 == 1" class="mb-2">
                                    <div v-if="campaigndetails.cg16 == 1">
                                        <img class="img-fluid " :src="campaigndetails.cg18" width="100%" />
                                    </div>
                                </div>
                                <div v-if="campaigndetails.cg5"><span v-html="replacebrake(campaigndetails.cg5)"></span>
                                </div>
                                <div
                                    v-if="this.campaigndetails.cg6 || this.campaigndetails.cg8 || this.campaigndetails.cg10 || this.campaigndetails.cg24 || this.campaigndetails.cg26">
                                    <button v-if="campaigndetails.cg6" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i
                                            class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
                campaigndetails.cg6 }}</button>
                                    <button v-if="campaigndetails.cg8" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i
                                            class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
                campaigndetails.cg8 }}</button>
                                    <button v-if="campaigndetails.cg10" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i
                                            class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
                                        campaigndetails.cg10 }}</button>
                                    <button v-if="campaigndetails.cg24" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i
                                            class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
                                        campaigndetails.cg24 }}</button>
                                    <button v-if="campaigndetails.cg26" type="button"
                                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i
                                            class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
                                        campaigndetails.cg26 }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- filter end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, requiredIf, minLength, helpers } from "@vuelidate/validators";

export default {
    data() {
        return {
            v$: useValidate(),
            whatsAppCampaignList: [],
            whatsAppRecords: 0,
            loading: false,
            newtempaltemodalstatus: false,
            selected_tab: 1,
            selected_resp_tab: 1,
            whatsAppResponseTemplateList: [],
            responseRecords: 0,
            addresponsetemplateDialog: false,

            whtbtnloader: false,
            templatename: '',
            aliasname: '',
            categorytype: '',
            languagevalue: '',
            vendortemplateid: '',
            headertype: '',
            mediatype: '',
            whtheaderimage: null,
            errorwhtheaderimage: '',
            exitwhatheaderpreviewimgurl: null,

            whtheadervideo: null,
            errorwhtheadervideo: '',
            exitwhatheaderpreviewvideourl: null,

            whtheaderdoc: null,
            errorwhtheaderdoc: '',
            exitwhatheaderpreviewdovurl: null,

            whatsappmessage: '',
            footername: '',
            categorytypelist: [
                // { label: "Authentication", value: 1, },
                { label: "Marketing", value: 2 },
                { label: "Utility", value: 3 },
            ],
            languagelist: [],
            mediatypelist: [
                { label: "Image", value: 1 },
                { label: "Video", value: 2 },
                { label: "Document", value: 3 },
            ],
            errStatus: false,
            errmsg: '',
            whatsappmessageinput: '',
            duplicateTemplateMsg: '',
            campaigndetailviewstatus: false,
            campaigndetails: '',
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            templatename: {
                required: helpers.withMessage("Please enter template name", required),
            },
            aliasname: {
                required: helpers.withMessage("Please enter alias name", required),
            },
            categorytype: {
                required: helpers.withMessage("Please select category type", required),
            },
            languagevalue: {
                required: helpers.withMessage("Please select language", required),
            },
            vendortemplateid: {
                required: helpers.withMessage("Please enter vendor template id", required),
                minLength: helpers.withMessage(
                    "Template id should be min 10 length",
                    minLength(10)
                ),
            },
            headertype: {
                required: helpers.withMessage("Please select header", required),
            },
            mediatype: {
                requiredIf: helpers.withMessage(
                    "Please select media type",
                    requiredIf(
                        this.headertype == 1
                    )
                ),
            },
            whtheaderimage: {
                requiredIf: helpers.withMessage(
                    "Please upload image",
                    requiredIf(
                        this.headertype == 1 && this.mediatype.value == 1
                    )
                ),
            },
            whtheadervideo: {
                requiredIf: helpers.withMessage(
                    "Please upload video",
                    requiredIf(
                        this.headertype == 1 && this.mediatype.value == 2
                    )
                ),
            },
            whtheaderdoc: {
                requiredIf: helpers.withMessage(
                    "Please upload document",
                    requiredIf(
                        this.headertype == 1 && this.mediatype.value == 3
                    )
                ),
            },
            whatsappmessage: {
                required: helpers.withMessage("Please enter message", required),
            },
        };
    },
    mounted() {
        this.getwhatsappcampaigntemplates();
    },

    methods: {
        selectedtab(e) {
            this.selected_tab = e;
        },
        selectedResponseTab(e) {
            this.selected_resp_tab = e;
            if (this.selected_resp_tab == 1) {
                this.getwhatsappcampaigntemplates();
            } else if (this.selected_resp_tab == 2) {
                this.getwhatsappresponsetemplate();
            }
        },
        newtemplateModalOpen() {
            this.$store.dispatch("addCampaignStatus");
            // this.newtempaltemodalstatus = true;
            this.selected_tab = 2;
            this.getwhatsappcampaigntemplateList();
            this.getcallcampaigntemplateList();
            this.getsmscampaigntemplates();
        },
        newtemplateModalClose() {
            this.$store.dispatch("addCampaignStatus");
            // this.newtempaltemodalstatus = false;
        },
        getwhatsappcampaigntemplates() {
            this.loading = true;
            this.ApiService.getwhatsappcampaigntemplates({ cg28: 1 }).then((data) => {
                if (data.success === true) {
                    this.whatsAppCampaignList = data.data;
                    this.whatsAppRecords = data.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.whatsAppCampaignList = [];
                    this.whatsAppRecords = 0;
                }
            });
        },
        changePageCamp(event) {
            this.page_no = event.page;
            this.getwhatsappcampaigntemplates({ page_no: this.page_no });
        },
        getwhatsappresponsetemplate() {
            this.loading = true;
            this.ApiService.getwhatsappcampaigntemplates({ cg28: 2 }).then((data) => {
                if (data.success === true) {
                    this.whatsAppResponseTemplateList = data.data;
                    this.responseRecords = data.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.whatsAppResponseTemplateList = [];
                    this.responseRecords = 0;
                }
            });
        },
        changePageResp(event) {
            this.page_no = event.page;
            this.getwhatsappcampaigntemplates({ page_no: this.page_no });
        },
        redirectIntelligentCampaignApi() {
            this.$router.push("/campaigntemplate/intelligent");
        }, redirectiMessageCampaignApi() {
            this.$router.push("/campaigntemplate/rcsimessage");
        }, redirectSMSCampaignApi() {
            this.$router.push("/campaigntemplate/sms");
        }, redirectEmailCampaignApi() {
            this.$router.push("/campaigntemplate/email");
        }, redirectAudioCallCampaignApi() {
            this.$router.push("/campaigntemplate/audiocall");
        }, redirectNotificationCampaignApi() {
            this.$router.push("/campaigntemplate/pushnotification");
        },

        getwhatsappcampaigntemplateList() {
            this.ApiService.getwhatsappcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("whatsapp_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("whatsapp_templates", []);
                }
            });
        },
        getcallcampaigntemplateList() {
            this.ApiService.getcallcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("call_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("call_templates", []);
                }
            });
        },
        getsmscampaigntemplates() {
            this.ApiService.getsmscampaigntemplates().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("sms_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("sms_templates", []);
                }
            });
        },
        addResponseTemplateModalOpen() {
            this.addresponsetemplateDialog = true;
            this.getlanguages();
        },
        addResponseTemplateModalClose() {
            this.addresponsetemplateDialog = false;
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        getlanguages(e) {
            this.ApiService.getlanguages(e).then((data) => {
                if (data.status == 200) {
                    this.languagelist = data.data;
                } else {
                    this.languagelist = [];
                }
            });
        },
        whatsappResponseSubmitBtn() {
            this.v$.$validate();
            var formData = new FormData();
            formData.append("cg28", 2);
            formData.append("cg3", this.templatename);
            formData.append("cg2", this.aliasname);
            if (this.categorytype) {
                formData.append('cg21', this.categorytype.value);
                // formData.append('ak11', this.categorytype.label);
            }
            if (this.languagevalue) {
                formData.append('cg12', this.languagevalue.value);
                formData.append('cg13', this.languagevalue.label);
            }
            formData.append("cg15", this.headertype);
            formData.append("cg4", this.vendortemplateid);
            if (this.headertype == 1) {
                formData.append('cg16', this.mediatype.value);
                if (this.mediatype.value == 1) {
                    if (this.whtheaderimage) {
                        formData.append("cg17", this.whtheaderimage);
                    }
                } else if (this.mediatype.value == 2) {
                    if (this.whtheadervideo) {
                        formData.append("cg17", this.whtheadervideo);
                    }
                } else if (this.mediatype.value == 3) {
                    if (this.whtheaderdoc) {
                        formData.append("cg17", this.whtheaderdoc);
                    }
                }
            }
            formData.append("cg5", this.whatsappmessage);
            if (this.footername) {
                formData.append("cg23", this.footername);
            }
            if (!this.v$.$error && !this.errorwhtheaderimage && !this.errorwhtheadervideo && !this.errorwhtheaderdoc && !this.duplicateTemplateMsg) {
                this.whtbtnloader = true;
                this.ApiService.addWhatsappCampaignTemplate(formData).then((data) => {
                    if (data.status == 200) {
                        this.whtbtnloader = false;
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.selected_resp_tab == 2;
                        this.addresponsetemplateDialog = false;
                        this.getwhatsappresponsetemplate();
                    } else {
                        this.whtbtnloader = false;
                        this.addresponsetemplateDialog = true;
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                    }
                });

            } else {
                this.$error;
            }
        },
        handleWhtImageUploader() {
            var file = this.$refs.whtheaderimage.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file) {
                this.errorwhtheaderimage = 'Please upload image';
            } else if (!file.size > 2048) {
                this.errorwhtheaderimage = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errorwhtheaderimage = 'Invalid file type';
            } else {
                this.errorwhtheaderimage = '';
                this.whtheaderimage = this.$refs.whtheaderimage.files[0];
                this.exitwhatheaderpreviewimgurl = this.whtheaderimage;
            }
        },
        handleWhtVideoUploader() {
            var file = this.$refs.whtheadervideo.files[0];
            let allowedExtensions = /(\.mp4|\.mpeg)$/i;
            if (!file) {
                this.errorwhtheadervideo = 'Please upload video';
            } else if (!file.size > 5120) {
                this.errorwhtheadervideo = 'File size cannot exceed more than 5MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errorwhtheadervideo = 'Invalid file type';
            } else {
                this.errorwhtheadervideo = '';
                this.whtheadervideo = this.$refs.whtheadervideo.files[0];
                this.exitwhatheaderpreviewvideourl = this.whtheadervideo;
                this.previewVideo();
            }
        },
        previewVideo() {
            let video = document.getElementById('video-preview');
            let reader = new FileReader();

            reader.readAsDataURL(this.whtheadervideo);
            reader.addEventListener('load', function () {
                video.src = reader.result;
            });
        },
        handleWhtDocUploader() {
            var file = this.$refs.whtheaderdoc.files[0];
            let allowedExtensions = /(\.doc|\.docx|\.pdf|\.xml)$/i;
            if (!file) {
                this.errorwhtheaderdoc = 'Please upload file';
            } else if (!file.size > 2048) {
                this.errorwhtheaderdoc = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errorwhtheaderdoc = 'Invalid file type';
            } else {
                this.errorwhtheaderdoc = '';
                this.whtheaderdoc = this.$refs.whtheaderdoc.files[0];
                this.exitwhatheaderpreviewdovurl = this.whtheaderdoc;
            }
        },
        clearheader() {
            this.mediatype = '';
            this.whtheaderimage = null;
            this.errorwhtheaderimage = '';
            this.exitwhatheaderpreviewimgurl = null;
            this.whtheadervideo = null;
            this.errorwhtheadervideo = '';
            this.exitwhatheaderpreviewvideourl = null;
            this.whtheaderdoc = null;
            this.errorwhtheaderdoc = '';
            this.exitwhatheaderpreviewdovurl = null;
        },
        mediatypechanges() {
            this.mediatype = '';
            this.whtheaderimage = null;
            this.errorwhtheaderimage = '';
            this.exitwhatheaderpreviewimgurl = null;
            this.whtheadervideo = null;
            this.errorwhtheadervideo = '';
            this.exitwhatheaderpreviewvideourl = null;
            this.whtheaderdoc = null;
            this.errorwhtheaderdoc = '';
            this.exitwhatheaderpreviewdovurl = null;
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("formMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        validUrl: function (url) {
            var re = /^(?!((ftp|http(s?)):\/\/))?[^ "]+(\.[a-zA-Z]{2,})+$/;
            return re.test(url);
        },
        htmlconvertmsg() {
            var messagevalue = this.whatsappmessage;
            this.whatsappmessageinput = messagevalue.replace(/\n/g, '<br/>');
        },

        checkTemplateName() {
            if ((this.templatename).length > 3 && localStorage.getItem('whatsapp_templates') !== null) {
                var templates = JSON.parse(localStorage.getItem('whatsapp_templates'));
                for (var i = 0, len = templates.length; i < len; i++) {
                    if ((templates[i]['cg3']).toLowerCase() === (this.templatename).toLowerCase()) {
                        this.duplicateTemplateMsg = 'Template name already exits.';
                        break;
                    } else {
                        this.duplicateTemplateMsg = '';
                    }
                }
            }
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        replacebrake(html) {
            const doc = html;
            return doc.replace(/(\r)*\n/g, '<br>')
        },
        campaginTemplateDetailModalOpen(data) {
            this.campaigndetailviewstatus = true;
            this.campaigndetails = data;
        },
        campaginTemplateDetailModalClose() {
            this.campaigndetailviewstatus = false;
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
    },
};
</script>
<style scoped>
.custom-start-menu-col {
    width: 21%;
}

.custom-end-body-col {
    width: 79%;
    padding-left: 0;
}

.custom-vertical-modal-tab {
    width: 213px;
    min-width: 213px;
    height: 460px;
}

.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.intelligent-campagin-modal-header {
    font-family: 'AcuminPro-SemiBold';
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0B294B;
    line-height: 18px;
    margin-top: 10px;
}

.preview-card-template {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 14px 15px;
    height: auto;
    min-height: 197px;
}

.preview-body-msg {
    font-family: "AcuminPro-Regular";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 17px;
    margin-top: 15px;
}

.preview-footer-msg {
    margin-top: 15px;
}
.company-box-outer {
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}
.customer-label-group {
  margin-bottom: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}
</style>