<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-4 col-12">Campaign List</div>
    <div class="
        col-lg-8 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <button type="button" @click="newcampignModalOpen()" class="header-btn-outer btn btn-primary">
        New Campaign
      </button>
    </div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
    <DataTable :value="campaignList" :scrollable="true" scrollHeight="flex" :paginator="true" :rows="30" :lazy="true"
      :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading" dataKey="co1">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="name" header="Campaign Name" headerStyle="width: 40%" bodyStyle="width: 40%">
        <template #body="{ data }">
          <div>
            <div>
              {{ data.co2 ? data.co2 : "N/A" }}
            </div>
          </div>
        </template>
      </Column>
      <Column field="type" header="Campaign Type" headerStyle="width: 13%" bodyStyle="width: 13%">
        <template #body="{ data }">
          <div>
            <span v-if="data.co3 == 1">Inteligent</span>
            <span v-if="data.co3 == 2">WhatsApp</span>
            <span v-if="data.co3 == 3">RCS</span>
            <span v-if="data.co3 == 4">iMessage</span>
            <span v-if="data.co3 == 5">SMS</span>
            <span v-if="data.co3 == 6">Email</span>
            <span v-if="data.co3 == 7">Calls</span>
            <span v-if="data.co3 == 8">Push Notifications</span>
            <span v-if="data.co3 == ''">N/A</span>
          </div>
        </template>
      </Column>
      <Column field="count" header="Count" class="justify-content-center" headerStyle="width: 12%"
        bodyStyle="width: 12%">
        <template #body="{ data }">
          <div>
            {{ data.co7 ? data.co7 : "N/A" }}
          </div>
        </template>
      </Column>
      <Column field="date" header="Scheduled Date & Time" headerStyle="width: 20%" bodyStyle="width: 20%">
        <template #body="{ data }">
          <div>
            <div>{{ format_timestamp(data.co5) }}</div>
            <div>
              <span class="user-badge status-active" v-if="data.co11 == 4">Completed</span>
              <span class="user-badge status-per-suspend" v-if="data.co11 == 2">Cancelled</span>
              <span class="user-badge status-temp-suspend" v-if="data.co11 == 1">Scheduled</span>
              <span class="user-badge status-temp-suspend" v-if="data.co11 == 3">Inserted</span>
              <span v-else></span>
            </div>
          </div>
        </template>
      </Column>
      <Column field="category" header="Action" headerStyle="width: 15%" bodyStyle="width: 15%">
        <template #body="{ data }">
          <div class="w-100">
            <button type="button" class="btn custom-view-detail-btn mb-2 w-100" @click="campaignLogsRedirectPage(data)">
              View Logs
            </button>
            <button type="button" class="btn custom-view-detail-btn w-100" @click="campaignReportRedirectPage(data)"
              v-if="data.co3 == 7">
              View Reports
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
  <!-- Add New Campign modal start here -->
  <div class="modal-mask" v-if="newcampaignmodalstatus">
    <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered
            custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header whatsapp-header">
          <h5 class="modal-title" id="staticBackdropLabel">New Campaign</h5>
          <button type="button" class="btn-close" @click="newcampignModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="modal-body-fixed-height">
            <div v-if="!showmodalloader">
              <div class="row" v-if="campaignfilterstatus">
                <div class="col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select Campaign For<span class="text-danger">*</span></label>
                    <div class="custom-group-radio-box-btn">
                      <input type="radio" class="btn-check" v-model="ak29" name="camapaign-option" id="voter"
                        autocomplete="off" value="1" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="voter">Voter's</label>
                      <input type="radio" class="btn-check" v-model="ak29" name="camapaign-option" id="karyakartas"
                        autocomplete="off" value="2" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="karyakartas">Karyakarta's</label>
                      <input type="radio" class="btn-check" v-model="ak29" name="camapaign-option" id="users"
                        autocomplete="off" value="3" />
                      <label class="btn text-capitalize" for="users">User's</label>
                    </div>
                  </div>
                </div>
                <Divider type="dashed" class="mt-0 mb-3" />
                <div class="col-12">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <div class="form-check form-check-inline custom-inline-checkbox">
                          <input class="form-check-input" @change="clearfilterinput(campaignallvoters)"
                            v-model="campaignallvoters" type="checkbox" id="allvoters" value="1">
                          <label class="form-check-label" for="allvoters">Select All <span
                              v-if="ak29 == 1">Voter's</span><span v-if="ak29 == 2">Karyakarta's</span><span
                              v-if="ak29 == 3">User's</span></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="from-label-value text-primary">-- OR --</label>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-12" v-if="campaigncountstatus">
                      <div class="custom-form-group">
                        <div class="custom-img-error">No records found.</div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label"><span v-if="ak29 == 1">Voter</span><span
                            v-if="ak29 == 2">Karyakarta</span><span v-if="ak29 == 3">User</span> Name</label>
                        <input type="text" v-model="ak5" class="form-control text-capitalize" id="formfirstnameInput"
                          placeholder="Enter Name" autocomplete="off" @keypress="isLetterWithSpace($event)" />
                      </div>
                    </div>
                    <!-- <div class="input-group custom-input-group">
                          <div class="col-lg-4 col-md-4 col-12">
                            <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true"
                              label="label" placeholder="Select" disabled
                              class="multiselect-custom custom-title-multiselect" :canClear="false"
                              :closeOnSelect="true" :object="true" noOptionsText="No Result" :canDeselect="false" />
                          </div>
                          <div class="col-lg-8 col-md-8 col-12">
                            <input type="text" v-model="ak24" class="form-control" placeholder="Enter Mobile No."
                              maxlength="10" id="formMobileNoInput" @paste="onPasteMobile" @keypress="onlyNumberMobile"
                              autocomplete="off" />
                          </div>
                        </div> -->
                    <div class="col-lg-4 col-md-4 col-12" v-if="ak29 == 1">
                      <div class="custom-form-group" style="margin-top: 30px;;">
                        <div class="form-check form-check-inline custom-inline-checkbox">
                          <input class="form-check-input" v-model="ak99" type="checkbox" id="allvoters" value="1">
                          <label class="form-check-label" for="allvoters">Incomplete Voter's</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Mobile No.</label>
                        <textarea type="text" id="formmessageInput" v-model="ak24" @keypress="CommaSeparatedMobile"
                          class="form-control custom-text-area-scroll" placeholder="Enter Comma Separated Mobile No."
                          autocomplete="off" rows="1" :style="{ resize: 'vertical' }"></textarea>
                        <div><span class="text-muted">e.g. 9999999999,8888888888,...</span></div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Lok Sabha Name</label>
                        <Multiselect v-model="ak62" :options="loksabhaList" :searchable="true"
                          @change="getvidhansabhaName($event)" @keypress="isLetterWithSpace($event)" label="label"
                          placeholder="Select" class="multiselect-custom text-capitalize" :canClear="false"
                          :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Vidhan Sabha Name</label>
                        <Multiselect v-show="!vidhanloaderflag" v-model="ak60" :options="vidhansabhaList"
                          :searchable="true" label="label" placeholder="Select" @keypress="isLetterWithSpace($event)"
                          class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true"
                          :object="true" noOptionsText="No Result" />
                        <Multiselect v-show="vidhanloaderflag" loading :searchable="false" label="label"
                          placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">State</label>
                            <Multiselect v-model="ak47" :options="filterstateList" :searchable="true"
                                label="label" placeholder="Select" class="multiselect-custom"
                                :canClear="true" :closeOnSelect="true" :object="true"
                                noOptionsText="No Result" @keypress="isLetterWithSpace($event)"
                                @select="getCityByStateId(ak47)" />
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Select City</label>
                        <Multiselect v-show="!cityloaderflag" :disabled="cityloaderflag" mode="tags" v-model="ak45" :options="filtercityList" :searchable="true"
                          label="label" placeholder="Select" class="multiselect-custom" @keypress="isLetterWithSpace($event)"
                          :canClear="true" :closeOnSelect="true" noOptionsText="No Result" :object="false"
                          @select="getAreaByCityId(ak45)" required />
                        <Multiselect v-show="cityloaderflag" loading :searchable="false" label="label" placeholder="Select"
                          class="multiselect-custom" noOptionsText="No Result" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Select Area</label>
                        <Multiselect v-show="!arealoaderflag" :disabled="arealoaderflag" mode="tags" v-model="ak44" :options="filterareaList" :searchable="true"
                          label="label" placeholder="Select" class="multiselect-custom text-capitalize" @keypress="isLetterWithSpace($event)"
                          :canClear="true" :closeOnSelect="true" noOptionsText="No Result" :object="false" required />
                        <Multiselect v-show="arealoaderflag" loading :searchable="false" label="label" placeholder="Select"
                          class="multiselect-custom" noOptionsText="No Result" />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Blood Group</label>
                        <Multiselect v-model="ak20" :options="bloodgroupList" mode="tags" :createTag="false"
                          :searchable="true" label="label" placeholder="Select"
                          class="multiselect-custom text-capitalize" :canClear="true" :closeOnSelect="true"
                          :object="false" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Gender</label>
                        <Multiselect v-model="ak22" :options="genderList" mode="tags" :createTag="false" label="label"
                          class="multiselect-custom text-capitalize" placeholder="Select" :closeOnSelect="true"
                          :searchable="false" :object="false" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Pincode</label>
                        <!-- <input type="text" v-model="pincodevalue" id="addpincodeInput" class="form-control"
                          placeholder="Enter Pincode" minlength="6" maxlength="6" @paste="onPasteMobile"
                          @keypress="onlypincodeAddFormat" autocomplete="off" />
                        <span class="custom-error" v-if="pincodeerr">
                          {{ pincodeerr }}
                        </span> -->
                        <Multiselect v-show="!pincodefilterloaderflag" v-model="pincodevalue"
                          :options="filterpincodeList" mode="tags" :createTag="false" :searchable="true" label="label"
                          placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                          :object="false" minlength="6" maxlength="6" />
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-3 col-md-3 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Age</label>
                        <input type="text" v-model="agerange" class="form-control" placeholder="Enter Age" minlength="1"
                          maxlength="2" @paste="onPasteMobile" @keypress="onlysingleagechange" autocomplete="off" />
                        <span class="custom-error" v-if="agerangeeerr">
                          {{ agerangeeerr }}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                      <div class="customer-label-group text-center" style="margin-top: 35px;">
                        <label class="from-label-value">-- OR --</label>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                      <div class="row custom-form-group">
                        <div class="col-lg-5 col-md-5 col-12">
                          <div class="custom-form-group mb-0">
                            <label class="form-label">Age Range</label>
                            <input type="text" v-model="agerangefrom" class="form-control" placeholder="From"
                              minlength="1" maxlength="2" @keypress="agerangechange" autocomplete="off" />
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                          <div class="custom-form-group text-center mb-0" style="margin-top: 35px;">-</div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-12">
                          <div class="custom-form-group mb-0" style="margin-top: 5px;">
                            <label class="form-label"></label>
                            <input type="text" v-model="agerangeto" :disabled="agerangefrom == ''" class="form-control"
                              placeholder="To" minlength="1" maxlength="2" @keypress="agerangechange"
                              autocomplete="off" />
                          </div>
                        </div>
                        <span class="custom-error" v-if="agerangefromeerr">
                          {{ agerangefromeerr }}
                        </span>
                        <span class="custom-error" v-if="agerangetoeerr">
                          {{ agerangetoeerr }}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label for="formBirthDateInput" class="form-label">Birth Date</label>
                        <Calendar v-model="ak18" :minDate="minDateBirth" :maxDate="maxDateBirth" :showIcon="true"
                          class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                          :manualInput="false" :monthNavigator="true" :yearRange="'1950:' + maxDateBirth.getFullYear()"
                          appendTo="body" :yearNavigator="true">
                        </Calendar>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-show="!hideotherfilter">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="form-label d-flex justify-content-between"><span>Profession</span><span
                            class="small ms-1 text-muted">(Max 3)</span></label>
                        <Multiselect v-model="profession" :options="voterprofessionList" mode="tags" :createTag="false"
                          label="label" class="multiselect-custom form-custom-select text-capitalize"
                          placeholder="Select" :closeOnSelect="true" :searchable="true" :object="false" :max="3" />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="form-label d-flex justify-content-between"><span>Part Number</span><span
                            class="small ms-1 text-muted">(Max 3)</span></label>
                        <Multiselect v-model="ak85" :options="reassignpartnoList" mode="tags" :createTag="false"
                          label="label" class="multiselect-custom form-custom-select text-capitalize"
                          placeholder="Select" :closeOnSelect="true" :searchable="true" :object="true" :max="3" />
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="form-label d-flex justify-content-between"><span>Select Tags</span><span
                            class="small ms-1 text-muted">(Max 5)</span></label>
                        <Multiselect v-model="ak121" appendTo="body" :options="taggroupList" mode="tags"
                          :createTag="false" label="label" class="multiselect-custom form-custom-select text-capitalize"
                          placeholder="Select" :closeOnSelect="true" :searchable="false" :object="false" :max="5" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="createtempaltestatus">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="voter-already-user-status text-center">Total {{ this.filterListCount }} result found.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Campaign Name <span class="text-danger">*</span></label>
                      <input type="text" v-model="campaignname" class="form-control" placeholder="Enter Campaign Name"
                        maxlength="250" autocomplete="off" />
                      <div class="custom-error" v-if="v$.campaignname.$error">
                        {{ v$.campaignname.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-8 col-md-8 col-12">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Select Campaign Type <span class="text-danger">*</span></label>
                          <Multiselect v-model="campaigntype" @change="getcampaigntemplates($event)"
                            :options="campaigntypeList" :searchable="false" label="label"
                            placeholder="Select Campaign Type" class="multiselect-custom" :canClear="true"
                            :closeOnSelect="true" />
                          <div class="custom-error" v-if="v$.campaigntype.$error">
                            {{ v$.campaigntype.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12" v-if="this.campaigntype == 5">
                        <div class="custom-form-group">
                          <label class="form-label">Select Sender Id <span class="text-danger">*</span></label>
                          <Multiselect v-model="senderid" @change="getcampaignsenderid($event)" :options="senderidList"
                            :searchable="true" label="label" placeholder="Select Sender Id" class="multiselect-custom"
                            :canClear="false" :closeOnSelect="true" />
                          <div class="custom-error" v-if="v$.senderid.$error">
                            {{ v$.senderid.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Select Template <span class="text-danger">*</span></label>
                          <Multiselect v-show="!templateloaderflag"
                            :disabled="(this.campaigntype == '' || this.campaigntype == 5) && this.senderid == ''"
                            v-model="templatename" @select="previewTemplate(templatename)"
                            :options="getcampaigntemplateList" :searchable="false" label="label"
                            placeholder="Select Template" class="multiselect-custom" :canClear="true"
                            :closeOnSelect="true" />
                          <Multiselect v-show="templateloaderflag" loading :searchable="false" label="label"
                            placeholder="Select Template" class="multiselect-custom" noOptionsText="No Result" />
                          <div class="custom-error" v-if="v$.templatename.$error">
                            {{ v$.templatename.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="this.filterListCount < 1000">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="custom-form-group">
                          <div class="form-check form-check-inline custom-inline-checkbox">
                            <input class="form-check-input"
                              v-model="schedulenowcheck" type="checkbox" id="schedulenow" value="true">
                            <label class="form-check-label" for="schedulenow">Schedule Now?</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12" v-if="this.schedulenowcheck == false">
                        <div class="custom-form-group">
                          <label for="formBirthDateInput" class="form-label">Schedule Date & Time <span
                              class="text-danger">*</span></label>
                          <div class="input-group">
                            <div class="col-lg-6 col-md-6 col-6">
                              <Calendar v-model="scheduledate" :showIcon="true" class="custom-ultima-calendar"
                                placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :minDate="todaysDate"
                                :manualInput="false" :monthNavigator="true"
                                :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true"
                                @date-select="handleInterviewDate()" />

                            </div>
                            <div class="col-lg-6 col-md-6 col-6">
                              <Calendar id="time12" placeholder="HH:MM" v-model="scheduletime" :timeOnly="true"
                                :manualInput="false" hourFormat="12" class="custom-ultima-calendar custom-time-outer"
                                appendTo="body" :disabled="!scheduledate" :stepMinute="15" />

                            </div>
                          </div>
                          <div class="custom-error" v-if="v$.scheduledate.$error">
                            {{ v$.scheduledate.$errors[0].$message }}
                          </div>
                          <div class="custom-error" v-if="scheduledate != null && v$.scheduletime.$error">
                            {{ v$.scheduletime.$errors[0].$message }}
                          </div>
                          <div class="custom-error" v-if="errormsg">{{ errormsg }}</div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12" v-if="this.campaigntype == 7">
                        <div class="custom-form-group">
                          <label class="form-label">Call Service<span class="text-danger">*</span></label>
                          <div class="custom-group-radio-box-btn">
                            <input type="radio" class="btn-check" v-model="callservicetype" name="normal-option"
                              id="normaltype" autocomplete="off" value="1" />
                            <label class="btn custom-radio-box-btn text-capitalize" for="normaltype">Normal Call
                              Service</label>
                            <input type="radio" class="btn-check" v-model="callservicetype" name="blast-option"
                              id="blasttype" autocomplete="off" value="2" />
                            <label class="btn custom-radio-box-btn text-capitalize" for="blasttype">Call Blast
                              Service</label>
                          </div>
                          <div class="custom-error" v-if="v$.callservicetype.$error">
                            {{ v$.callservicetype.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="this.campaigntype == 5 && this.previewflag == 1">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="form-box-outer">
                          <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="custom-form-group mb-2">
                                <input type="text" v-model="tagnamesmsone" class="form-control"
                                  placeholder="Enter Tag Name" maxlength="50" autocomplete="off" />
                                <div class="custom-error" v-if="v$.tagnamesmsone.$error">
                                    {{ v$.tagnamesmsone.$errors[0].$message }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="custom-form-group mb-2">
                                <input type="text" v-model="tagvaluesmsone" class="form-control"
                                  placeholder="Enter Tag Value" maxlength="50" autocomplete="off" />
                                  <div class="custom-error" v-if="v$.tagvaluesmsone.$error">
                                    {{ v$.tagvaluesmsone.$errors[0].$message }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12" v-if="secondrowbtnstatus">
                              <button type="button" @click="addMoreSecondRow()" class="btn custom-view-detail-btn"><i
                                  class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More</button>
                            </div>
                          </div>
                          <Divider class="mb-3" v-if="secondrowshowstatus" />
                          <!-- Response Two  -->
                          <div class="row" v-if="secondrowshowstatus">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="custom-form-group mb-2">
                                <input type="text" v-model="tagnamesmstwo" class="form-control"
                                  placeholder="Enter Tag Name" maxlength="250" autocomplete="off" />
                                  <div class="custom-error" v-if="v$.tagnamesmstwo.$error">
                                    {{ v$.tagnamesmstwo.$errors[0].$message }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="custom-form-group mb-2">
                                <input type="text" v-model="tagvaluesmstwo" class="form-control"
                                  placeholder="Enter Tag Value" maxlength="50" autocomplete="off" />
                                  <div class="custom-error" v-if="v$.tagvaluesmstwo.$error">
                                    {{ v$.tagvaluesmstwo.$errors[0].$message }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12" v-if="thirdrowbtnstatus">
                              <button type="button" @click="addMoreThirdRow()" class="btn custom-view-detail-btn"><i
                                  class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More</button>
                            </div>
                          </div>
                          <Divider class="mb-3" v-if="thirdrowshowstatus" />
                          <!-- Response Three  -->
                          <div class="row" v-if="thirdrowshowstatus">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="custom-form-group mb-2">
                                <input type="text" v-model="tagnamesmsthree" class="form-control"
                                  placeholder="Enter Tag Name" maxlength="250" autocomplete="off" />
                                  <div class="custom-error" v-if="v$.tagnamesmsthree.$error">
                                    {{ v$.tagnamesmsthree.$errors[0].$message }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="custom-form-group mb-2">
                                <input type="text" v-model="tagvaluesmsthree" class="form-control"
                                  placeholder="Enter Tag Value" maxlength="50" autocomplete="off" />
                                  <div class="custom-error" v-if="v$.tagvaluesmsthree.$error">
                                    {{ v$.tagvaluesmsthree.$errors[0].$message }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12" v-if="fourthrowbtnstatus">
                              <button type="button" @click="addMoreFourthRow()" class="btn custom-view-detail-btn"><i
                                  class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More</button>
                            </div>
                          </div>
                          <Divider class="mb-3" v-if="fourthrowshowstatus" />
                          <div class="row" v-if="fourthrowshowstatus">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="custom-form-group mb-2">
                                <input type="text" v-model="tagnamesmsfour" class="form-control"
                                  placeholder="Enter Tag Name" maxlength="250" autocomplete="off" />
                                  <div class="custom-error" v-if="v$.tagnamesmsfour.$error">
                                    {{ v$.tagnamesmsfour.$errors[0].$message }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="custom-form-group mb-2">
                                <input type="text" v-model="tagvaluesmsfour" class="form-control"
                                  placeholder="Enter Tag Value" maxlength="50" autocomplete="off" />
                                  <div class="custom-error" v-if="v$.tagvaluesmsfour.$error">
                                    {{ v$.tagvaluesmsfour.$errors[0].$message }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12" v-if="fifthrowbtnstatus">
                              <button type="button" @click="addMoreFifthRow()" class="btn custom-view-detail-btn"><i
                                  class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More</button>
                            </div>
                          </div>
                          <Divider class="mb-3" v-if="fifthrowshowstatus" />
                          <div class="row" v-if="fifthrowshowstatus">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="custom-form-group mb-2">
                                <input type="text" v-model="tagnamesmsfive" class="form-control"
                                  placeholder="Enter Tag Name" maxlength="250" autocomplete="off" />
                                  <div class="custom-error" v-if="v$.tagnamesmsfive.$error">
                                    {{ v$.tagnamesmsfive.$errors[0].$message }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="custom-form-group mb-2">
                                <input type="text" v-model="tagvaluesmsfive" class="form-control"
                                  placeholder="Enter Tag Value" maxlength="50" autocomplete="off" />
                                  <div class="custom-error" v-if="v$.tagvaluesmsfive.$error">
                                    {{ v$.tagvaluesmsfive.$errors[0].$message }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12">
                    <div class="row" v-if="pushnotificationfiltercount">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="intelligent-campagin-modal-header mb-2 text-center">
                          <span v-if="this.campaigntype == 8">App Installs found</span>
                          <span v-if="this.campaigntype == 6">Email IDs found</span>
                        </div>
                        <div class="voter-already-user-status text-center">{{ this.pushnotificationfiltercount }}</div>
                      </div>
                    </div>
                    <div class="row" v-if="showmsgpreview">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="intelligent-campagin-modal-header mb-2 text-center">Message Preview</div>
                          </div>
                        </div>
                        <div class="preview-card-template">
                          <!-- WhatsApp -->
                          <div v-if="this.campaigntype == 2">
                            <div v-if="campaigndetails.cg15 == 1" class="mb-2">
                              <div v-if="campaigndetails.cg16 == 1">
                                <img class="img-fluid " :src="campaigndetails.cg18" width="100%" />
                              </div>
                            </div>
                            <div v-if="campaigndetails.cg5"><span v-html="replacebrake(campaigndetails.cg5)"></span></div>
                            <div
                              v-if="this.campaigndetails.cg6 || this.campaigndetails.cg8 || this.campaigndetails.cg10 || this.campaigndetails.cg24 || this.campaigndetails.cg26">
                              <button v-if="campaigndetails.cg6" type="button"
                                class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i
                                  class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
            campaigndetails.cg6 }}</button>
                              <button v-if="campaigndetails.cg8" type="button"
                                class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i
                                  class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
            campaigndetails.cg8 }}</button>
                              <button v-if="campaigndetails.cg10" type="button"
                                class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i
                                  class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
            campaigndetails.cg10 }}</button>
                              <button v-if="campaigndetails.cg24" type="button"
                                class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i
                                  class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
            campaigndetails.cg24 }}</button>
                              <button v-if="campaigndetails.cg26" type="button"
                                class="btn modal-bulk-next-btn w-100 my-1 text-capitalize" disabled><i
                                  class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
            campaigndetails.cg26 }}</button>
                            </div>
                          </div>
                          <!-- Calls -->
                          <div v-if="this.campaigntype == 7">
                            <div v-if="campaigndetails.cl3 != null && campaigndetails.cl3 != ''"><audio controls
                                controlsList="download" style="height: 35px !important;">
                                <source
                                  :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/callcampaign/' + this.campaigndetails.cl3"
                                  type="audio/mpeg" />
                              </audio>
                            </div>
                            <button v-if="campaigndetails.cl11 == 1" type="button"
                              class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span
                                class="me-2 fw-bold">{{
            campaigndetails.cl9
          }}</span>- {{
              campaigndetails.cl10 }}</button>
                            <button v-if="campaigndetails.cl14 == 1" type="button"
                              class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span
                                class="me-2 fw-bold">{{
            campaigndetails.cl12
          }}</span>- {{
              campaigndetails.cl13 }}</button>
                            <button v-if="campaigndetails.cl17 == 1" type="button"
                              class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span
                                class="me-2 fw-bold">{{
            campaigndetails.cl15
          }}</span>- {{
              campaigndetails.cl16 }}</button>
                            <button v-if="campaigndetails.cl20 == 1" type="button"
                              class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span
                                class="me-2 fw-bold">{{
            campaigndetails.cl18
          }}</span>- {{
              campaigndetails.cl19 }}</button>
                            <button v-if="campaigndetails.cl23 == 1" type="button"
                              class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span
                                class="me-2 fw-bold">{{
            campaigndetails.cl21
          }}</span>- {{
              campaigndetails.cl22 }}</button>
                            <button v-if="campaigndetails.cl26 == 1" type="button"
                              class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span
                                class="me-2 fw-bold">{{
            campaigndetails.cl24
          }}</span>- {{
              campaigndetails.cl25 }}</button>
                            <button v-if="campaigndetails.cl29 == 1" type="button"
                              class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span
                                class="me-2 fw-bold">{{
            campaigndetails.cl27
          }}</span>- {{
              campaigndetails.cl28 }}</button>
                            <button v-if="campaigndetails.cl32 == 1" type="button"
                              class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span
                                class="me-2 fw-bold">{{
            campaigndetails.cl30
          }}</span>- {{
              campaigndetails.cl31 }}</button>
                            <button v-if="campaigndetails.cl35 == 1" type="button"
                              class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span
                                class="me-2 fw-bold">{{
            campaigndetails.cl33
          }}</span>- {{
              campaigndetails.cl34 }}</button>
                          </div>
                          <!-- Push Notification -->
                          <div v-if="this.campaigntype == 8">
                            <div v-if="campaigndetails.cc12" class="mb-2">
                              <img class="img-fluid " :src="campaigndetails.cc12" width="100%" />
                            </div>
                            <div v-if="campaigndetails.cc17" class="fw-bold"> {{ campaigndetails.cc17 }}</div>
                            <div><span v-html="replacebrake(campaigndetails.cc5)"></span></div>
                          </div>
                          <!-- SMS -->
                          <div v-if="this.campaigntype == 5">
                            <div><span v-html="replacebrake(campaigndetails.cj7)"></span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="balancealertstatus">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="balance-alert-status-outer text-center">
                      <div v-if="this.balancealert1">{{ this.balancealert1 }}</div>
                      <div v-if="this.balancealert2" class="mt-2">{{ this.balancealert2 }}</div>
                      <div v-if="this.balancealert3" class="mt-2">{{ this.balancealert3 }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showmodalloader">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0" v-if="campaignfilterstatus">
            <div class="d-flex justify-content-between">
              <button class="btn filter-apply-btn"
                :disabled="(ak18 == '' || ak18 == null) && (ak62 == '' || ak62 == null) && (ak60 == '' || ak60 == null) && (ak20 == '' || ak20 == null) && (this.ak22 == '' || this.ak22 == null) && (ak44 == null || ak44 == '') && (ak45 == null || ak45 == '') && (ak47 == null || ak47 == '') && (ak85 == null || ak85 == '') && (ak121 == null || ak121 == '') && (ak24 == null || ak24 == '') && (profession == null || profession == '') && ak5 == '' && ak99 == '' && pincodevalue == '' && agerange == '' && agerangefrom == '' && agerangeto == '' && campaignallvoters == ''"
                @click="
        btnFilterSearch(
          ak29,
          ak5,
          ak99,
          ak24,
          ak62,
          ak60,
          ak44,
          ak45,
          ak47,
          ak20,
          ak22,
          pincodevalue,
          agerange,
          agerangefrom,
          agerangeto,
          ak18,
          profession,
          ak85,
          ak121
        )
        ">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" @click="resetVoterFilter">
                Reset All
              </button>
            </div>
          </div>
          <div class="col-12 m-0 text-end" v-if="createtempaltestatus">
            <button type="button" class="btn filter-apply-btn" @click="confirmModalOpen()" :disabled="confirmloader"
              :style="{ width: '70px' }">
              <span v-if="!confirmloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="confirmloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Add New Campign modal end here -->
  <!-- confirmation modal start here -->
  <div class="modal-mask" v-if="confirm_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" :disabled="createloader" class="btn confirm-modal-no-btn"
              @click="confirmModalClose()">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="CreateNewCampaignBtnbtn()"
              :disabled="createloader">
              <span v-if="!createloader">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="createloader"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation modal end here -->
</template>
<script>
import { required, requiredIf, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../service/ApiService";
import router from "@/router";
import moment from 'moment';
export default {
  data() {
    return {
      v$: useValidate(),
      campaignList: [],
      totalRecords: 0,
      loading: false,
      newcampaignmodalstatus: false,
      createtempaltestatus: false,
      campaignfilterstatus: false,
      ak5: '',
      ak99: '',
      ak24: [],
      ak62: [],
      ak60: [],
      ak20: [],
      ak18: null,
      ak22: [],
      agerange: '',
      agerangefrom: '',
      agerangeto: '',
      pincodevalue: [],
      pincodeerr: '',
      ak44: [],
      ak45: [],
      ak47: '',
      profession: [],
      ak85: [],
      ak121: [],
      
      voterprofessionList: [],
      reassignpartnoList: [],
      taggroupList: [],
      bloodgroupList: [],
      filterpincodeList: [],
      genderList: [
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
        { label: "Others", value: 3 },
      ],


      agerangeeerr: '',
      agerangefromeerr: '',
      agerangetoeerr: '',
      errStatus: false,
      errmsg: "",

      showmodalloader: false,
      filterList: [],
      campaigncountstatus: false,
      createloader: false,
      filterListCount: '',

      campaignname: '',
      campaigntype: '',
      senderid: '',
      templatename: '',
      callservicetype: '',
      scheduledate: null,
      schedulenowcheck: false,
      scheduletime: null,
      campaigntypeList: [
        { label: "Intelligent", value: 1 },
        { label: "RCS", value: 3 },
        { label: "iMessage", value: 4 },
        { label: "SMS", value: 5 },
        { label: "Email", value: 6 },
        { label: "Calls", value: 7 },
        { label: "Push Notification", value: 8 },
      ],
      // { label: "WhatsApp", value: 2 },
      getcampaigntemplateList: [],
      templateloaderflag: false,
      todaysDate: new Date(moment().add(4, 'hours')._d),
      balancealert1: '',
      balancealert2: '',
      balancealert3: '',
      campaignallvoters: '',
      hideotherfilter: false,
      errormsg: '',
      balancealertstatus: false,
      countrycode: { value: 101, label: "+91" },
      countryCodeList: [],
      maxDateBirth: moment().subtract(1, "years")._d,
      minDateBirth: moment().subtract(120, "years")._d,
      loksabhaList: [],
      vidhansabhaList: [],
      vidhanloaderflag: false,
      ak29: 1,
      senderidList: [],
      confirmloader: false,
      confirm_popup_status: false,
      campaigndetails: '',
      voterstorageimgpath: '',
      showmsgpreview: false,

      tagnamesmsone: '',
      tagvaluesmsone: '',

      tagnamesmstwo: '',
      tagvaluesmstwo: '',

      tagnamesmsthree: '',
      tagvaluesmsthree: '',

      tagnamesmsfour: '',
      tagvaluesmsfour: '',

      tagnamesmsfive: '',
      tagvaluesmsfive: '',


      secondrowshowstatus: false,
      secondrowbtnstatus: true,

      thirdrowshowstatus: false,
      thirdrowbtnstatus: false,

      fourthrowshowstatus: false,
      fourthrowbtnstatus: false,

      fifthrowshowstatus: false,
      fifthrowbtnstatus: false,
      previewflag: 0,
      currentdatetime: new Date(),

      filterstateList: [],
      filterareaList: [],
      filtercityList: [],
      cityloaderflag: false,
      arealoaderflag: false,
      pushnotificationfiltercount: '',
    };
  },
  watch: {
    // pincodevalue(pincodevalue) {
    //   this.errmsg = "";
    //   this.validatePinCodeNumber(pincodevalue);
    //   if (pincodevalue.length == 0) {
    //     this.pincodeerr = "";
    //     this.errStatus = false;
    //   }
    // },
    agerange(agerange) {
      this.errmsg = "";
      this.validateAgeRangeNumber(agerange);
      if (agerange.length == 0) {
        this.agerangeeerr = "";
        this.errStatus = false;
      }
    },
    agerangefrom(agerangefrom) {
      this.errmsg = "";
      this.validateAgeRangeFromNumber(agerangefrom);
      if (agerangefrom.length == 0) {
        this.agerangefromeerr = "";
        this.errStatus = false;
      }
    },
    agerangeto(agerangeto) {
      this.errmsg = "";
      this.validateAgeRangeToNumber(agerangeto);
      if (agerangeto.length == 0) {
        this.agerangetoeerr = "";
        this.errStatus = false;
      }
    },
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {
      campaignname: {
        required: helpers.withMessage("Please enter campaign name", required),
      },
      campaigntype: {
        required: helpers.withMessage("Please select campaign type", required),
      },
      senderid: {
        requiredIf: helpers.withMessage(
          "Please select sender id",
          requiredIf(
            this.campaigntype == 5
          )
        ),
      },
      callservicetype: {
        requiredIf: helpers.withMessage(
          "Please select call service",
          requiredIf(
            this.campaigntype == 7
          )
        ),
      },
      templatename: {
        required: helpers.withMessage("Please select template name", required),
      },
      scheduledate: {
          requiredIf: helpers.withMessage(
              "Please select schedule date",
              requiredIf(
                  this.schedulenowcheck == false
              )
          ),
      },
      scheduletime: {
          requiredIf: helpers.withMessage(
              "Please select schedule time",
              requiredIf(
                  this.schedulenowcheck == false
              )
          ),
      },
      tagnamesmsone: {
          requiredIf: helpers.withMessage(
              "Please enter tag name",
              requiredIf(
                  this.campaigntype == 5 && this.previewflag == 1
              )
          ),
      },
      tagvaluesmsone: {
          requiredIf: helpers.withMessage(
              "Please select tag value",
              requiredIf(
                  this.campaigntype == 5 && this.previewflag == 1
              )
          ),
      },
      // response two
      tagnamesmstwo: {
          requiredIf: helpers.withMessage(
              "Please enter tag name",
              requiredIf(
                  this.tagvaluesmstwo != ''
              )
          ),
      },
      tagvaluesmstwo: {
          requiredIf: helpers.withMessage(
              "Please select tag value",
              requiredIf(
                  this.tagnamesmstwo != ''
              )
          ),
      },
      // response three
      tagnamesmsthree: {
          requiredIf: helpers.withMessage(
              "Please enter tag name",
              requiredIf(
                  this.tagvaluesmsthree != ''
              )
          ),
      },
      tagvaluesmsthree: {
          requiredIf: helpers.withMessage(
              "Please select tag value",
              requiredIf(
                  this.tagnamesmsthree != ''
              )
          ),
      },
      // response four
      tagnamesmsfour: {
          requiredIf: helpers.withMessage(
              "Please enter tag name",
              requiredIf(
                  this.tagvaluesmsfour != ''
              )
          ),
      },
      tagvaluesmsfour: {
          requiredIf: helpers.withMessage(
              "Please select tag value",
              requiredIf(
                  this.tagnamesmsfour != ''
              )
          ),
      },
      // response five
      tagnamesmsfive: {
          requiredIf: helpers.withMessage(
              "Please enter tag name",
              requiredIf(
                  this.tagvaluesmsfive != ''
              )
          ),
      },
      tagvaluesmsfive: {
          requiredIf: helpers.withMessage(
              "Please select tag value",
              requiredIf(
                  this.tagnamesmsfive != ''
              )
          ),
      },
    };
  },
  mounted() {
    this.loading = true;
    this.getcampaign();
    this.gettags();
    this.getboothnumberlist();
    this.getProfessions();
    this.getbloodgroups();
    this.getstates();
    this.loadCityByStateId();
    // this.getareaList();
    this.getloksabha();
    this.getSenderIdList();
    this.getpincodeList();
    if (localStorage.user) {
      this.client_info = JSON.parse(localStorage.client_info);
      this.voterstorageimgpath = this.client_info.maa24;
    }
  },
  methods: {
    getstates() {
        this.ApiService.getstates().then((data) => {
            if (data.success == true) {
                this.filterstateList = data.data;
            } else {
              this.filterstateList = null;
            }
        });
    },
    loadCityByStateId() {
      this.cityloaderflag = true;
        this.ak44 = null;
        this.ApiService.getcities({ state_id: 11 }).then((data) => {
            if (data.success == true) {
                this.filtercityList = data.data;
                this.cityloaderflag = false;
            } else {
              this.cityloaderflag = false;
              this.filtercityList = data.data;
            }
        });
    },
    getCityByStateId(e) {
      this.cityloaderflag = true;
        this.ak45 = null;
        this.ak44 = null;
        this.ApiService.getcities({ state_id: e.value }).then((data) => {
            if (data.success == true) {
                this.filtercityList = data.data;
                this.cityloaderflag = false;
            } else {
              this.cityloaderflag = false;
              this.filtercityList = data.data;
            }
        });
    },
    getAreaByCityId(e) {
      this.arealoaderflag = true;
      this.ak44 = null;
      this.ApiService.getareas({ city_id: e }).then((data) => {
        if (data.success == true) {
          this.filterareaList = data.data;
          this.arealoaderflag = false;
        } else {
          this.arealoaderflag = false;
          this.filterareaList = null;
        }
      });
    },
    addMoreSecondRow() {
      this.secondrowshowstatus = true;
      this.secondrowbtnstatus = false;
      this.thirdrowbtnstatus = true;
    },
    addMoreThirdRow() {
      this.thirdrowshowstatus = true;
      this.thirdrowbtnstatus = false;
      this.fourthrowbtnstatus = true;
    },
    addMoreFourthRow() {
        this.fourthrowshowstatus = true;
        this.fourthrowbtnstatus = false;
        this.fifthrowbtnstatus = true;
    },

    addMoreFifthRow() {
        this.fifthrowshowstatus = true;
        this.fifthrowbtnstatus = false;
    },


    newcampignModalOpen() {
      this.newcampaignmodalstatus = true;
      this.campaignfilterstatus = true;
      this.createtempaltestatus = false;
      this.profession = null;
      this.ak29 = 1;
      this.ak5 = '',
        this.ak99 = '',
        this.ak24 = null,
        this.ak18 = null;
      this.ak62 = null;
      this.ak60 = null;
      this.ak20 = null;
      this.ak22 = null;
      this.agerange = "";
      this.agerangefrom = '';
      this.agerangeto = '';
      this.pincodevalue = null;
      this.ak44 = null;
      this.ak45 = null;
      this.agerangeeerr = '';
      this.pincodeerr = '';
      this.ak85 = null;
      this.ak121 = null;
      this.campaignname = '';
      this.campaigntype = '';
      this.senderid = '';
      this.templatename = '';
      this.callservicetype = '';
      this.scheduledate = null;
      this.schedulenowcheck = false;
      this.scheduletime = null;
      this.createloader = false;
      this.balancealert1 = '';
      this.balancealert2 = '';
      this.balancealert3 = '';
      this.campaignallvoters = '';
      this.hideotherfilter = false;
      this.balancealertstatus = false;
      this.showmsgpreview = false;
      this.tagnamesmsone = '';
      this.tagvaluesmsone = '';
      this.tagnamesmstwo = '';
      this.tagvaluesmstwo = '';
      this.tagnamesmsthree = '';
      this.tagvaluesmsthree = '';
      this.tagnamesmsfour = '';
      this.tagvaluesmsfour = '';
      this.tagnamesmsfive = '';
      this.tagvaluesmsfive = '';
      this.secondrowshowstatus = false;
      this.secondrowbtnstatus = true;
      this.thirdrowshowstatus = false;
      this.thirdrowbtnstatus = false;
      this.fourthrowshowstatus = false;
      this.fourthrowbtnstatus = false;
      this.fifthrowshowstatus = false;
      this.fifthrowbtnstatus = false;
      this.previewflag = 0;
      this.pushnotificationfiltercount = '';
    },
    newcampignModalClose() {
      this.newcampaignmodalstatus = false;
      this.campaignfilterstatus = false;
      this.createtempaltestatus = false;
      this.showmsgpreview = false;
      this.previewflag = 0;
      this.resetVoterFilter();
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },
    getcampaign(e) {
      this.ApiService.getcampaign(e).then((data) => {
        if (data.success === true) {
          this.campaignList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.campaignList = null;
          this.totalRecords = 0;
        }
      });
    },
    getSenderIdList(e) {
      this.ApiService.getSenderIdList(e).then((data) => {
        if (data.status == 200) {
          this.senderidList = data.data;
        } else {
          this.senderidList = null;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getcampaign({ 
        page_no: this.page_no,
        ak29: this.ak29,
        ak5: this.ak5,
        ak99: this.ak99,
        mobile_numbers: this.ak24,
        profession: this.profession,
        ak62: (this.ak62) ? this.ak62.value : '',
        ak60: (this.ak60) ? this.ak60.value : '',
        ak20: this.ak20,
        ak18: this.ak18,
        ak22: this.ak22,
        ak19: this.agerange,
        ak19from: this.agerangefrom,
        ak19to: this.agerangeto,
        ak49: this.ak49,
        ak44: this.ak44,
        ak45: this.ak45,
        ak47: (this.ak47) ? this.ak47.value : '',
        ak85: this.ak85,
        ak121: this.ak121
      });
    },
    btnFilterSearch(
      ak29,
      ak5,
      ak99,
      ak24,
      ak62,
      ak60,
      ak44,
      ak45,
      ak47,
      ak20,
      ak22,
      pincodevalue,
      agerange,
      agerangefrom,
      agerangeto,
      ak18,
      profession,
      ak85,
      ak121
    ) {
      if (!this.agerangeeerr && !this.pincodeerr && !this.agerangefromeerr && !this.agerangetoeerr) {
        this.ak29 = ak29;
        this.ak5 = ak5;
        this.ak99 = ak99;
        this.ak24 = ak24;
        this.profession = profession;
        this.ak62 = ak62;
        this.ak60 = ak60;
        this.ak20 = ak20;
        if (ak18 != null) {
          this.ak18 = moment(ak18).format("YYYY-MM-DD");
        }
        this.ak22 = ak22;
        this.agerange = agerange;
        this.agerangefrom = agerangefrom;
        this.agerangeto = agerangeto;
        this.pincodevalue = pincodevalue;
        this.ak44 = ak44;
        this.ak45 = ak45;
        this.ak47 = ak47;
        this.ak85 = ak85;
        this.ak121 = ak121;
        this.getCampaignVoterList({
          ak29: this.ak29,
          ak5: this.ak5,
          ak99: this.ak99,
          mobile_numbers: this.ak24,
          profession: this.profession,
          ak62: (this.ak62) ? this.ak62.value : '',
          ak60: (this.ak60) ? this.ak60.value : '',
          ak20: this.ak20,
          ak18: this.ak18,
          ak22: this.ak22,
          ak19: this.agerange,
          ak19from: this.agerangefrom,
          ak19to: this.agerangeto,
          ak49: this.ak49,
          ak44: this.ak44,
          ak45: this.ak45,
          ak47: (this.ak47) ? this.ak47.value : '',
          ak85: this.ak85,
          ak121: this.ak121
        });
      } else {
        this.showmodalloader = false;
        this.campaignfilterstatus = true;
        this.createtempaltestatus = false;
        this.campaigncountstatus = false;
      }
    },
    resetVoterFilter() {
      this.ak29 = '';
      this.ak5 = '';
      this.ak99 = '';
      this.ak24 = '';
      this.profession = null;
      this.ak62 = null;
      this.ak60 = null;
      this.ak20 = null;
      this.ak18 = null;
      this.ak22 = null;
      this.agerange = "";
      this.agerangefrom = '';
      this.agerangeto = '';
      this.pincodevalue = null;
      this.ak44 = null;
      this.ak45 = null;
      this.ak47 = null;
      this.agerangeeerr = '';
      this.pincodeerr = '';
      this.ak85 = null;
      this.ak121 = null;
      this.campaigncountstatus = false;
      // this.getCampaignVoterList({
      //   page_no: this.page_no,
      // });
    },
    getCampaignVoterList(e) {
      this.showmodalloader = true;
      this.ApiService.getCampaignVoterList(e).then((data) => {
        if (data.success == true) {
          this.filterList = data.data;
          this.filterListCount = data.count;
          if (this.filterListCount > 0) {
            this.showmodalloader = false;
            this.campaignfilterstatus = false;
            this.createtempaltestatus = true;
            this.campaigncountstatus = false;
          } else {
            this.showmodalloader = false;
            this.campaignfilterstatus = true;
            this.createtempaltestatus = false;
            this.campaigncountstatus = true;
          }
        } else {
          this.filterList = null;
          this.showmodalloader = false;
          this.campaignfilterstatus = false;
          this.createtempaltestatus = false;
        }
      });
    },
    handleInterviewDate() {
      // var current_minute = moment(ev).format('mm');
      // var new_minute = 15;
      // if (current_minute >= 1 && current_minute <= 14) {
      //   new_minute = 15;
      // } else if (current_minute >= 15 && current_minute <= 29) {
      //   new_minute = 30;
      // } else if (current_minute >= 30 && current_minute <= 44) {
      //   new_minute = 45;
      // } else if (current_minute >= 45 && current_minute <= 59) {
      //   new_minute = 0;
      // }
      var hours = new Date(moment().add(4, 'hours')._d);
      this.scheduletime = new Date(moment(hours).minute(new Date().getMinutes() + 5)._d);
      //if (moment(this.scheduledate).format('YYYY-MM-DD') == moment(this.todaysDate).format('YYYY-MM-DD') 
      //&& moment.utc(this.scheduletime).format('HH:mm') <= moment.utc(this.todaysDate).format('HH:mm')) {
    },
    confirmModalOpen() {
      this.errormsg = '';
      if(this.schedulenowcheck == false) {
        if (moment(this.scheduledate).format('YYYY-MM-DD') == moment(this.todaysDate).format('YYYY-MM-DD') &&
          moment.utc(this.scheduletime).format('HH:mm') <= moment.utc(this.todaysDate).format('HH:mm')) {
          this.errormsg = 'Please select a time, four hours from now.';
        } else {
          this.v$.$validate();
          let fields = {};
          fields["co2"] = this.campaignname;
          fields["co3"] = this.campaigntype;
          fields["co4"] = this.templatename;
          if(this.schedulenowcheck == false) {
            fields["co5"] = moment(this.scheduledate).format("YYYY-MM-DD") + ' ' + moment(this.scheduletime).format('HH:mm:ss');
          }
          fields["co6"] = this.filterList;
          if(this.campaigntype == 8 || this.campaigntype == 6) {
            fields["co7"] = this.pushnotificationfiltercount;
          } else {
            fields["co7"] = this.filterListCount;
          }
          if (this.campaigntype == 7) {
            fields["co27"] = this.callservicetype;
          }
          this.v$.$validate();
          if (!this.v$.$error) {
            this.confirm_popup_status = true;
          } else {
            this.confirm_popup_status = false;
          }
        }
      } else {
        this.v$.$validate();
        let fields = {};
        fields["co2"] = this.campaignname;
        fields["co3"] = this.campaigntype;
        fields["co4"] = this.templatename;
        if(this.schedulenowcheck == false) {
          fields["co5"] = moment(this.scheduledate).format("YYYY-MM-DD") + ' ' + moment(this.scheduletime).format('HH:mm:ss');
        }
        fields["co6"] = this.filterList;
        if(this.campaigntype == 8 || this.campaigntype == 6) {
            fields["co7"] = this.pushnotificationfiltercount;
        } else {
          fields["co7"] = this.filterListCount;
        }
        if (this.campaigntype == 7) {
          fields["co27"] = this.callservicetype;
        }
        if(this.schedulenowcheck == false) {
          fields["scheduleCampaign"] = 0;
        } else if (this.schedulenowcheck == true) {
          fields["scheduleCampaign"] = 1;
        }
        this.v$.$validate();
        if (!this.v$.$error) {
          this.confirm_popup_status = true;
        } else {
          this.confirm_popup_status = false;
        }
      }
    },

    confirmModalClose() {
      this.confirm_popup_status = false;
    },
    CreateNewCampaignBtnbtn() {
        this.errormsg = '';
        this.v$.$validate();
        let tagArray = []
        if(this.tagvaluesmsone){
          tagArray.push({label:this.tagnamesmsone, value: this.tagvaluesmsone});
        }
        if(this.tagvaluesmstwo){
          tagArray.push({label:this.tagnamesmstwo, value: this.tagvaluesmstwo});
        }
        if(this.tagvaluesmsthree){
          tagArray.push({label:this.tagnamesmsthree, value: this.tagvaluesmsthree});
        }
        if(this.tagvaluesmsfour){
          tagArray.push({label:this.tagnamesmsfour, value: this.tagvaluesmsfour});
        }
        if(this.tagvaluesmsfive){
          tagArray.push({label:this.tagnamesmsfive, value: this.tagvaluesmsfive});
        }
        let fields = {};
        fields["co2"] = this.campaignname;
        fields["co3"] = this.campaigntype;
        fields["co4"] = this.templatename;
        if(this.schedulenowcheck == false) {
          fields["co5"] = moment(this.scheduledate).format("YYYY-MM-DD") + ' ' + moment(this.scheduletime).format('HH:mm:ss');
        } else if(this.schedulenowcheck == true) {
          fields["co5"] = moment(this.currentdatetime).format("YYYY-MM-DD HH:mm:ss");
        }
        fields["co6"] = this.filterList;
        if(this.campaigntype == 8 || this.campaigntype == 6) {
            fields["co7"] = this.pushnotificationfiltercount;
        } else {
          fields["co7"] = this.filterListCount;
        }
        if(this.schedulenowcheck == false) {
          fields["scheduleCampaign"] = 0;
        } else if (this.schedulenowcheck == true) {
          fields["scheduleCampaign"] = 1;
        }
        
        if (this.campaigntype == 7) {
          fields["co27"] = parseInt(this.callservicetype);
        }
        if (this.campaigntype == 5) {
          fields["tagarray"] = tagArray;
        }
        this.v$.$validate();
        if (!this.v$.$error) {
          this.createloader = true;
          this.ApiService.addScheduleCampaign(fields).then((items) => {
            if (items.success == true) {
              this.createloader = false;
              var successMsg = items.message;
              this.$toast.open({
                message: successMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              this.confirm_popup_status = false;
              this.newcampaignmodalstatus = false;
              this.showmodalloader = false;
              this.campaignfilterstatus = false;
              this.createtempaltestatus = false;
              this.getcampaign();
              setTimeout(() => {
                this.v$.$reset();
              }, 0);
              this.balancealert1 = '';
              this.balancealert2 = '';
              this.balancealert3 = '';
              this.balancealertstatus = false;
            } else {
              this.balancealert1 = items.balance_message1;
              this.balancealert2 = items.balance_message2;
              this.balancealert3 = items.balance_message3;
              this.createloader = false;
              this.balancealertstatus = true;
              this.confirm_popup_status = true;
            }
          });
      }
    },
    campaignLogsRedirectPage(data) {
      if (data.co3 == 2) {
        let routeData = router.resolve({
          name: "VoterWhatsAppCampaignLogs",
          params: { campaignId: data.co1 },
        });
        window.open(routeData.href, "_blank");
      } else if (data.co3 == 5) {
        let routeData = router.resolve({
          name: "VoterSmsCampaignLogs",
          params: { campaignId: data.co1 },
        });
        window.open(routeData.href, "_blank");
      } else if (data.co3 == 7) {
        let routeData = router.resolve({
          name: "VoterCallCampaignLogs",
          params: { campaignId: data.co1 },
        });
        window.open(routeData.href, "_blank");
      } else if (data.co3 == 8) {
        let routeData = router.resolve({
          name: "VoterPushCampaignLogs",
          params: { campaignId: data.co1 },
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = router.resolve({
          name: "VoterCampaignLogs",
          params: { campaignId: data.co1 },
        });
        window.open(routeData.href, "_blank");
      }
    },
    campaignReportRedirectPage(data) {
      if (data.co3 == 7) {
        let routeData = router.resolve({
          name: "VoterCallCampaignReports",
          params: { campaignId: data.co1 },
        });
        window.open(routeData.href, "_blank");
      } else if (data.co3 == 5) {
        let routeData = router.resolve({
          name: "VoterSmsCampaignReports",
          params: { campaignId: data.co1 },
        });
        window.open(routeData.href, "_blank");
      }
      else {
        let routeData = router.resolve({
          name: "VoterCampaignReports",
          params: { campaignId: data.co1 },
        });
        window.open(routeData.href, "_blank");
      }
    },
    
  
    getpincodeList() {
      this.pincodefilterloaderflag = true;
      this.pincodevalue = [];
      this.ApiService.getPincoceByAreaIds({ area_ids: null }).then((items) => {
        if (items.success === true) {
          this.filterpincodeList = items.data;
          this.pincodefilterloaderflag = false;
        } else {
          this.filterpincodeList = null;
          this.pincodefilterloaderflag = false;
        }
      });
    },
    gettags(e) {
      this.ApiService.gettags(e).then((data) => {
        if (data.status == 200) {
          this.taggroupList = data.data;
        } else {
          this.taggroupList = null;
        }
      });
    },
    getboothnumberlist(e) {
      this.ApiService.getboothnumberlist(e).then((data) => {
        if (data.status == 200) {
          this.reassignpartnoList = data.data;
        }
      });
    },
    getProfessions(e) {
      this.ApiService.getProfessions(e).then((data) => {
        if (data.status == 200) {
          this.voterprofessionList = data.data;
        } else {
          this.voterprofessionList = null;
        }
      });
    },
    getbloodgroups(e) {
      this.ApiService.getbloodgroups(e).then((data) => {
        if (data.status == 200) {
          this.bloodgroupList = data.data;
        } else {
          this.bloodgroupList = null;
        }
      });
    },

    validatePinCodeNumber(e) {
      this.errStatus = false;
      if (!this.validPinCode(e)) {
        this.pincodeerr = "Please enter valid pincode";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.pincodeerr = "";
        return true;
      }
    },
    validPinCode: function (e) {
      var re = /^((?!(0))[0-9]{6,6})$/;
      return re.test(e);
    },
    validateAgeRangeNumber(e) {
      this.errStatus = false;
      if (!this.validAgeRange(e)) {
        this.agerangeeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerange == 0) {
        this.agerangeeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangeeerr = "";
        return true;
      }
    },
    validAgeRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateAgeRangeFromNumber(e) {
      this.errStatus = false;
      if (!this.validAgeFromRange(e)) {
        this.agerangefromeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (this.agerangefrom == 0) {
        this.agerangefromeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangefromeerr = "";
        return true;
      }
    },
    validAgeFromRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    validateAgeRangeToNumber(e) {
      this.errStatus = false;
      if (!this.validAgeToRange(e)) {
        this.agerangetoeerr = "Please enter minimum 1 digit";
        this.errStatus = true;
      }
      if (parseInt(this.agerangeto) <= parseInt(this.agerangefrom)) {
        this.agerangetoeerr = "Please enter valid age range";
        this.errStatus = true;
      }
      if (this.agerangeto == 0) {
        this.agerangetoeerr = "Please enter valid age";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.agerangetoeerr = "";
        return true;
      }

    },
    validAgeToRange: function (e) {
      var re = /([0-9]){1,2}$/;
      return re.test(e);
    },
    onlysingleagechange($event) {
      this.agerangefrom = '';
      this.agerangeto = '';
      this.agerangefromeerr = '';
      this.agerangetoeerr = '';
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    agerangechange($event) {
      this.agerange = '';
      this.agerangeeerr = '';
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    getcampaigntemplates(e) {
      this.templateloaderflag = true;
      this.templatename = "";
      this.callservicetype = '';
      this.showmsgpreview = false;
      if(e == 8 || e == 6) {
        this.filterCampaignVoterList({
          ak29: this.ak29,
          ak5: this.ak5,
          ak99: this.ak99,
          mobile_numbers: this.ak24,
          profession: this.profession,
          ak62: (this.ak62) ? this.ak62.value : '',
          ak60: (this.ak60) ? this.ak60.value : '',
          ak20: this.ak20,
          ak18: this.ak18,
          ak22: this.ak22,
          ak19: this.agerange,
          ak19from: this.agerangefrom,
          ak19to: this.agerangeto,
          ak49: this.ak49,
          ak44: this.ak44,
          ak45: this.ak45,
          ak47: (this.ak47) ? this.ak47.value : '',
          ak85: this.ak85,
          ak121: this.ak121,
          campaign_type: e,
        });
      }
      this.ApiService.getcampaigntemplatesList({ campaign_type: e }).then((data) => {
        if (data.status == 200) {
          this.getcampaigntemplateList = data.data;
          this.templateloaderflag = false;
        } else {
          this.getcampaigntemplateList = [];
          this.templateloaderflag = false;
        }
      });
    },
    filterCampaignVoterList(e) {
      this.ApiService.filterCampaignVoterList(e).then((data) => {
        if (data.status == 200) {
          this.pushnotificationfiltercount = data.count;
          this.filterList = data.data;
        } else {
          this.pushnotificationfiltercount = '';
        }
      });
    },
    getcampaignsenderid(e) {
      this.templateloaderflag = true;
      this.templatename = "";
      this.ApiService.getcampaigntemplatesList({ campaign_type: this.campaigntype, sender_id: e }).then((data) => {
        if (data.status == 200) {
          this.getcampaigntemplateList = data.data;
          this.templateloaderflag = false;
        } else {
          this.getcampaigntemplateList = [];
          this.templateloaderflag = false;
        }
      });
    },
    previewTemplate(e) {
      this.ApiService.getTemplateBody({ campaign_type: this.campaigntype, template_id: e, sender_id: this.senderid }).then((data) => {
        if (data.success === true) {
          this.campaigndetails = data.data;
          this.showmsgpreview = true;
          this.previewflag = data.tag;
        } else {
          this.campaigndetails = '';
          this.previewflag = 0;
        }
      });
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment.utc(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return '-';
      }
    },
    clearfilterinput(e) {
      this.ak5 = '';
      this.ak99 = '';
      this.ak24 = '';
      this.profession = null;
      this.ak62 = null;
      this.ak60 = null;
      this.ak20 = null;
      this.ak18 = null;
      this.ak22 = null;
      this.agerange = "";
      this.agerangefrom = '';
      this.agerangeto = '';
      this.pincodevalue = null;
      this.ak44 = null;
      this.ak45 = null;
      this.ak47 = null;
      this.ak85 = null;
      this.ak121 = null;
      this.agerangeeerr = '';
      this.pincodeerr = '';
      this.agerangefromeerr = '';
      this.agerangetoeerr = '';
      if (e == true) {
        this.hideotherfilter = true;
      } else if (e == false) {
        this.hideotherfilter = false;
      }
    },
    onlypincodeAddFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var addpincodeInput = document.getElementById("addpincodeInput");
      if ((addpincodeInput.value == '' || addpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    CommaSeparatedMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 44) {
        $event.preventDefault();
      }
    },
    getloksabha(e) {
      this.ak60 = null;
      this.ApiService.getloksabha(e).then((data) => {
        if (data.status == 200) {
          this.loksabhaList = data.data;
        }
      });
    },
    getvidhansabhaName(e) {
      this.vidhanloaderflag = true;
      this.ak60 = null;
      this.ApiService.getvidhansabha({ lokhsabha_id: e.value }).then((items) => {
        if (items.success === true) {
          this.vidhansabhaList = items.data;
          this.vidhanloaderflag = false;
        } else {
          this.vidhanloaderflag = false;
          this.vidhansabhaList = [];
        }
      });
    },
    replacebrake(html) {
      if (html) {
        const doc = html;
        return doc.replace(/(\r)*\n/g, '<br>');
      }
    },
  },
};
</script>
<style scoped>
.modal-body-fixed-height {
  min-height: 435px;
}

.voter-already-user-status {
  padding: 8px 16px;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  margin-bottom: 15px;
  color: #63957d;
  font-weight: 700;
  font-family: "AcuminPro-SemiBold";
}

.balance-alert-status-outer {
  padding: 8px 16px;
  background: #ffcdd2 0% 0% no-repeat padding-box;
  border: 1px solid #d5b1b1;
  margin-bottom: 15px;
  color: #c63737;
  font-weight: 700;
  font-family: "AcuminPro-SemiBold";
}

.user-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.user-badge.status-active {
  background: #c8e6c9;
  color: #256029;
}

.user-badge.status-per-suspend {
  background: #ffcdd2;
  color: #c63737;
}

.user-badge.status-temp-suspend {
  background: #ffd8b2;
  color: #805b36;
}

.preview-card-template {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #c1d4f3;
  border-radius: 4px;
  padding: 14px 15px 14px 15px;
  height: auto;
  min-height: 197px;
}

.intelligent-campagin-modal-header {
  font-family: 'AcuminPro-SemiBold';
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.52px;
  color: #0B294B;
  line-height: 18px;
  margin-top: 0px;
}

.preview-body-msg {
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 17px;
  margin-top: 15px;
}

.preview-footer-msg {
  margin-top: 15px;
}
.custom-view-detail-btn {
  color: #5266e5;
  border-color: #5266e5;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #5266e5;
  border-radius: 4px;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  line-height: 26px;
}
.custom-view-detail-btn:hover {
  color: #5266e5;
}
.custom-view-detail-btn:focus-visible {
  outline: 0;
}
</style>